import { Box } from '@mui/material';

import ScyllaCloudImage from 'assets/icons/monster-cloud.svg?react';
import LoaderAnimation from 'components/layouts/app-layout/LoaderAnimation';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  loadingText: {
    fontSize: theme.utils.pxToRem(20),
    lineHeight: theme.utils.pxToRem(32),
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  scyllaImage: {
    height: 440,
    width: 440,
    position: 'absolute',
    opacity: '10%',
    bottom: -100,
    right: -80,
  },
}));

function LoadingPage({ classes = {} }) {
  return (
    <Box className={classes.container} data-loading="true">
      <LoaderAnimation className={classes.animation} />
      <h1 className={classes.loadingText}>Loading...</h1>
      <ScyllaCloudImage className={classes.scyllaImage} />
    </Box>
  );
}

export default withJss(LoadingPage);
