import { BROADCAST_TYPE } from '@siren-frontend/shared';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link, useLocation, useParams } from 'react-router-dom';

import Skeleton from 'components/common/Skeleton';
import Logo from 'components/common/logo';
import Breadcrumbs from 'components/layouts/app-layout/header/Breadcrumbs';
import SetUpConnectionBanner from 'components/layouts/app-layout/header/SetUpConnectionBanner';
import UserInfoDropdown from 'components/layouts/app-layout/header/UserInfoDropdown';
import WhatsNewButton from 'components/layouts/app-layout/header/WhatsNewButton';
import withStyles from 'components/withStylesAdapter';
import { PERMISSIONS } from 'definitions/user-profile';
import { useAuthentication } from 'hooks/authentication';
import { useClusterInfo } from 'hooks/cluster';
import { usePermission } from 'hooks/utils';
import { useSupportRedirect } from 'utils/hooks';

const withJss = withStyles(theme => ({
  root: {
    backgroundColor: theme.colors.darkSanMarino,
    color: theme.colors.white,
    height: theme.headerHeight,
    display: 'grid',
    gridTemplateColumns: 'max-content max-content 1fr',
    gap: theme.spacing(2),
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    minWidth: 'max-content',
    padding: `0 ${theme.spacing(4)}`,
    [theme.breakpoints.down('md')]: {
      padding: `0 ${theme.spacing(2)}`,
    },
  },
  leftContent: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gridGap: theme.spacing(1),
  },
  menu: {
    marginLeft: '24px',
    width: '18px',
    fill: theme.colors.white,
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      height: '40px',
      width: 'auto',
    },
  },
  userLink: {
    color: theme.colors.white,
  },
  dropdown: {
    '& > li': {
      paddingRight: 40,
    },
  },
  centerContent: {
    display: 'flex',
    justifySelf: 'center',
    alignItems: 'center',
  },
  rightContent: {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    justifySelf: 'end',
  },
  skeleton: {
    backgroundColor: theme.colors.sanMarino,
    marginRight: theme.spacing(6.5),
  },
}));

function Header({ outerClass, classes }) {
  useSupportRedirect(); // preload support url
  const location = useLocation();
  const canManageVPCPeering = usePermission(PERMISSIONS.canManageVPCPeering);

  const { clusterId } = useParams();
  const { isLoading, cluster } = useClusterInfo(clusterId);

  const path = location?.pathname;
  const isConnectionsPage =
    path && (path.includes('connections') || path.includes('vpc-peering'));

  const withConnections =
    canManageVPCPeering &&
    !isLoading &&
    cluster?.broadcastType === BROADCAST_TYPE.PRIVATE;

  const showSetUpConnectionBanner =
    !isConnectionsPage &&
    clusterId &&
    withConnections &&
    (cluster?.vpcPeeringList ? cluster?.vpcPeeringList.length === 0 : false) &&
    cluster?.connections?.length === 0;

  const { isLoading: isProfileLoading } = useAuthentication();

  return (
    <header className={classnames(classes.root, outerClass)}>
      <div className={classes.leftContent}>
        <Link to="/" className={classes.logoWrapper} data-testid="ScyllaLogo">
          <Logo variant="mascot" color="default" />
        </Link>
        <Breadcrumbs />
      </div>
      <div className={classes.centerContent}>
        {showSetUpConnectionBanner && (
          <SetUpConnectionBanner clusterId={clusterId} />
        )}
      </div>
      <div className={classes.rightContent}>
        <WhatsNewButton />
        <Skeleton isLoading={isProfileLoading} className={classes.skeleton}>
          <UserInfoDropdown />
        </Skeleton>
      </div>
    </header>
  );
}

Header.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  outerClass: PropTypes.string,
};

export default withJss(Header);
