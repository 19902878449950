import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import { RestartCloudAccountWizard } from 'components/common/modals';
import AppRoutes from 'components/pages';
import { useShowModal } from 'components/providers/modals';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';
import { useOwnCloudAccount } from 'hooks/account';
import { useCreateCloudAccount } from 'hooks/byoa';

const withJss = withStyles(theme => ({
  caption: {
    margin: '10px 0 0',
  },
  alert: {
    margin: '20px 0',
  },
  text: {
    lineHeight: theme.utils.pxToRem(20),
    marginBottom: theme.spacing(2),
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  link: {
    ...theme.extensions.hyperLink,
  },
  cancelBtn: {
    minWidth: 'initial',
  },
}));

const notActiveStates = ['ACTION_NEEDED', 'FAILED'];

function usePreloadPages() {
  useEffect(function preloadPages() {
    AppRoutes.CloudAccountSetup.preload();
    AppRoutes.CloudAccountSetupHeader.preload();
  }, []);
}

function CrossCloudRequest({ classes, hideModal }) {
  const showModal = useShowModal();
  const navigate = useNavigate();
  usePreloadPages();

  const {
    data: cloudAccount,
    isLoading: isOwnAccountLoading,
    error: ownAccountError,
  } = useOwnCloudAccount();

  const {
    request: createCloudAccount,
    isLoading: isCreateAccountLoading,
    error: createError,
  } = useCreateCloudAccount(() =>
    navigate('/managed-resources/cloud-account-setup')
  );

  const onAction = () => {
    if (!cloudAccount) {
      createCloudAccount();
    } else if (notActiveStates.includes(cloudAccount.state)) {
      navigate('/managed-resources/byoa/edit');
    } else {
      navigate('/managed-resources/cloud-account-setup');
    }

    hideModal();
  };

  const content = cloudAccount ? (
    <Fragment>
      Click on &quot;Resume&quot; to pick up where you left off. Click
      &quot;Restart&quot; if you prefer to restart the wizard from the
      beginning.
    </Fragment>
  ) : (
    <Fragment>
      Click &quot;Setup Cloud Account&quot; to start the wizard that will guide
      you through this process.
    </Fragment>
  );

  const isLoading = isCreateAccountLoading || isOwnAccountLoading;
  const error = createError || ownAccountError;

  const isInactive = notActiveStates.includes(
    cloudAccount && cloudAccount.state
  );

  const buttonText = cloudAccount ? 'RESUME' : 'SETUP CLOUD ACCOUNT';

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader onClose={hideModal} testid="byoaHeader">
        Use Your AWS Account
      </MainModalHeader>

      <MainModalContent>
        <AlertWithTraceId
          error={error}
          className={classes.alert}
          id="requestCrossCloudCredentials"
        />
        <Typography component="div" variant="caption" paragraph>
          {isInactive ? (
            <Typography className={classes.text}>
              Your AWS account setup needs some attention. Please check the
              Cloud Account page for details.
            </Typography>
          ) : (
            <Fragment>
              <Typography className={classes.text}>
                Using your AWS account for Scylla Cloud works by allocating
                cloud resources (such as EC2 instances) for your Scylla Cloud
                clusters using your AWS account. To support this option, we need
                you to grant the Scylla Cloud team access by creating two IAM
                Policies and an IAM Role. {content}
              </Typography>
              <Typography className={classes.text}>
                Using your AWS account for Scylla Cloud is not supported with
                Scylla Cloud Free Trial.
              </Typography>
              <Typography className={classes.text}>
                The access to your AWS account will only be used to create and
                manage Scylla clusters by your explicit orders made using the
                Scylla Cloud service.
                <br />
                For more information see&nbsp;
                <Typography
                  className={classes.link}
                  variant="caption"
                  color="primary"
                  component="a"
                  id="docsLink"
                  href={documentationLinks.cloudDocs.cloudSetup.byoa}
                  target="_blank"
                >
                  Deploy ScyllaDB Cloud on your AWS Account
                </Typography>
              </Typography>
            </Fragment>
          )}
        </Typography>
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="cloudAccountCreateBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={onAction}
            isLoading={isLoading}
          >
            {isInactive ? 'CHECK CLOUD ACCOUNT' : buttonText}
          </MainModalActionButton>
          {cloudAccount && !isInactive && (
            <MainModalActionButton
              id="cloudAccountRestartBtn"
              type={MainModalActionButton.Types.MAJOR}
              onClick={() => showModal(RestartCloudAccountWizard)()}
            >
              RESTART
            </MainModalActionButton>
          )}
          <MainModalActionButton
            id="requestCrossCloudCredentialsCancelBtn"
            type={MainModalActionButton.Types.MINOR}
            className={classes.cancelBtn}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

CrossCloudRequest.propTypes = {
  classes: PropTypes.shape({}),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(CrossCloudRequest);
