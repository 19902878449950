import { useState } from 'react';

import MainModal, {
  MainModalActions,
  MainModalContent,
  MainModalHeader,
} from 'components/common/main-modal';
import ResizeClusterAlert from 'components/common/modals/ResizeCluster/ResizeClusterAlert';
import ResizeClusterModalActions from 'components/common/modals/ResizeCluster/ResizeClusterModalActions';
import ResizeClusterModalContent from 'components/common/modals/ResizeCluster/ResizeClusterModalContent';
import ResizeInProgressModalContent from 'components/common/modals/ResizeCluster/ResizeInProgressModalContent';
import Skeleton from 'components/common/skeleton';
import withStyles from 'components/withStylesAdapter';
import { useClusterInfo, useResizeCluster } from 'hooks/cluster';
import { useResizeRequests } from 'hooks/clusterRequest';
import { extractManualResizeRequest } from 'services/self-service-resize';

const withJss = withStyles(theme => ({
  subtitle: {
    ...theme.extensions.modalTitle,
    fontSize: theme.utils.pxToRem(16),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
  },
  dcWrapper: {
    marginBottom: theme.spacing(2),
  },
  infoBox: {
    ...theme.extensions.infoBox,
    marginTop: theme.spacing(0.5),
    padding: `${theme.spacing(2)} 22px`,
  },
  headerTitle: {
    fontSize: theme.utils.pxToRem(20),
  },
}));

function ResizeClusterModal({ hideModal, params: { clusterId }, classes }) {
  const {
    cluster,
    isLoading: isClusterLoading,
    error: clusterError,
  } = useClusterInfo(clusterId);

  const {
    data: resizeInProgressRequests,
    isLoading: isResizeInProgressLoading,
    error: resizeRequestsInProgressError,
  } = useResizeRequests(clusterId);

  const {
    request,
    isLoading: isResizeRequestLoading,
    data: resizeResponse,
    error: resizeRequestError,
  } = useResizeCluster(clusterId);

  const isResizeInProgress = resizeInProgressRequests?.length > 0;
  const { dataCenters = [], nodes = [], isLocked } = cluster ?? {};
  const [newNodesPerDc, setNewNodesPerDc] = useState({});
  const [newInstancePerDc, setNewInstancePerDc] = useState({});

  const resizeSuccessful = !resizeRequestError && resizeResponse;
  const isResizeActionDisabled =
    Object.keys(newNodesPerDc).length === 0 &&
    Object.keys(newInstancePerDc).length === 0;

  const resizeTicketId = extractManualResizeRequest(
    resizeInProgressRequests
  )?.ticketExternalId;

  const nodesPerDc = nodes.reduce(
    (nodesPerDc, node) => ({
      ...nodesPerDc,
      [node.dcId]: nodesPerDc[node.dcId] + 1 || 1,
    }),
    {}
  );

  const instanceIdsPerDc = nodes.reduce(
    (instancesPerDc, node) => ({
      ...instancesPerDc,
      [node.dcId]: node?.instance?.id,
    }),
    {}
  );

  function updateNewNodesCount(newNodesCount, dcId) {
    if (nodesPerDc[dcId] === newNodesCount) {
      const { dcId: dcToRemove, ...otherDcs } = newNodesCount;
      setNewNodesPerDc(otherDcs);
    } else {
      setNewNodesPerDc({ ...newNodesPerDc, [dcId]: newNodesCount });
    }
  }

  function updateNewInstanceType(newInstanceId, dcId) {
    if (instanceIdsPerDc[dcId] === newInstanceId) {
      const { dcId: dcToRemove, ...otherDcs } = newInstanceId;
      setNewInstancePerDc(otherDcs);
    } else {
      setNewInstancePerDc({ ...newNodesPerDc, [dcId]: newInstanceId });
    }
  }

  function requestClusterResize() {
    const payload = dataCenters.map(({ id: dcId } = {}) => {
      const currentNodesCount = nodes?.filter(
        node => node.dcId === dcId
      )?.length;
      const currentInstanceType = nodes?.find(
        node => node.dcId === dcId
      )?.instanceId;

      return {
        dcId: parseInt(dcId, 10),
        wantedSize: newNodesPerDc[dcId] ?? currentNodesCount,
        instanceTypeId: newInstancePerDc[dcId] ?? currentInstanceType,
      };
    });

    request(payload);
  }

  const resizeType = JSON.parse(
    resizeInProgressRequests?.[0]?.requestBody ?? '{}'
  )?.processor;

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        classes={{
          headerTitle: classes.headerTitle,
        }}
      >
        Resize Cluster
      </MainModalHeader>
      <Skeleton isLoading={isClusterLoading || isResizeInProgressLoading}>
        <MainModalContent>
          <ResizeClusterAlert
            clusterError={clusterError}
            resizeRequestError={resizeRequestError}
            resizeRequestsInProgressError={resizeRequestsInProgressError}
            resizeResponse={resizeResponse}
          />

          {isResizeInProgress || isLocked ? (
            <ResizeInProgressModalContent
              isLocked={isLocked}
              isResizeInProgress={isResizeInProgress}
              resizeType={resizeType}
              resizeTicketId={resizeTicketId}
              data-loading-indicator
            />
          ) : (
            <ResizeClusterModalContent
              dataCenters={dataCenters}
              nodesPerDc={nodesPerDc}
              newNodesPerDc={newNodesPerDc}
              newInstancePerDc={newInstancePerDc}
              updateNewNodesCount={updateNewNodesCount}
              updateNewInstanceType={updateNewInstanceType}
              disableInputs={Boolean(resizeResponse)}
              data-loading-indicator
            />
          )}
        </MainModalContent>
      </Skeleton>
      <MainModalActions centered={isResizeInProgress}>
        <ResizeClusterModalActions
          resizeCluster={requestClusterResize}
          isActionDisabled={isResizeActionDisabled}
          resizeSuccessful={resizeSuccessful}
          isResizeInProgress={isResizeInProgress}
          isResizeRequestLoading={isResizeRequestLoading}
          hideModal={hideModal}
        />
      </MainModalActions>
    </MainModal>
  );
}

export default withJss(ResizeClusterModal);
