/**
 * @template {Record<string, null>} T
 * @param {T} src
 * @returns {{[K in keyof T]: K}}
 */
export function keyMirror(src) {
  return Object.keys(src).reduce((p, k) => {
    p[k] = k;
    return p;
  }, Object.create(null));
}
