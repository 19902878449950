import MuiSkeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(() => ({
  fullSize: {
    transform: 'none',
    maxWidth: 'none',
  },
}));

function Skeleton({ isLoading, children, fullSize, classes, ...props }) {
  return isLoading ? (
    <MuiSkeleton
      data-loading="true"
      data-testid="skeleton"
      classes={{
        root: fullSize && classes.fullSize,
      }}
      {...props}
    >
      {children ? children : null}
    </MuiSkeleton>
  ) : children ? (
    children
  ) : (
    <MuiSkeleton
      data-loading="true"
      data-testid="skeleton"
      classes={{
        root: fullSize && classes.fullSize,
      }}
      {...props}
    />
  );
}

Skeleton.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  fullSize: PropTypes.bool,
  classes: PropTypes.shape({}),
};

export default withJss(Skeleton);
