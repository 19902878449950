import { useAuth } from '@frontegg/react';
import { STALE_DATA_PREFIX } from '@siren-frontend/shared';
import { ERROR_CODES } from '@siren-frontend/shared/constants/errors';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { useCallback, useEffect, useRef, useState } from 'react';
import useSWR, { mutate, useSWRConfig } from 'swr';

import {
  SNACKBAR_VARIANTS,
  useSnackbar,
} from 'components/providers/notifications';
import { useAuthHeader } from 'hooks/authentication';
import { infoKey } from 'services/resources/me';
import { parseGeneralServerError } from 'services/server-errors';
import { logRequestErrors } from 'utils/error-handlers';

const MAX_RETRIES = process.env.maxFetchRetries;

const refetchErrorCodes = [ERROR_CODES.unauthorizedErrorCode];

/**
 * Handle mutation request (POST/DELETE/PUT).
 *
 * @param {function():Promise} requester - Async function that will execute the request when called
 * @param {function} onSuccess - Callback function to be called with response value
 * @param {function} onFailure - Callback function to be called with response error
 * @param {function} fetchAtInit - Whether to self-trigger the mutation request at init time (only for requesters without params)
 * Can be used to display and error or trigger a snackbar. There is no default handling
 * @return {object} { request, data, isLoading, error }
 * @property {function} request -  a function to be called to execute a request
 * @property {object} data - data returned from the resolved request
 * @property {boolean} isLoading - is the request currently executing
 * @property {string} error - error occurred on request or returned by server. In case it is a known error code, it will be parsed
 */
export function useMutationRequest({
  requester,
  onSuccess,
  onFailure,
  fetchAtInit = false,
}) {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [initiallyFetched, setInitiallyFetched] = useState(false);
  const { mutate } = useSWRConfig();

  const { user } = useAuth();
  const token = user?.accessToken;

  const request = useCallback(
    async function requestFn(...args) {
      setError();
      setIsLoading(true);
      try {
        /*
          The token is passed as the last argument now.
          So we have to pay attention to requester calls inside the component
          to not pass a syntetic event from onClick as an argument
        */
        const result = await requester(...args, token);

        setData(result);
        onSuccess && onSuccess(result);
      } catch (error) {
        const parsedError = parseGeneralServerError(error);
        setError(parsedError);
        setIsLoading(false);

        // Permission might have changed out of the client, refetch
        if (refetchErrorCodes.includes(parsedError?.code)) {
          mutate(infoKey);
        }

        onFailure && onFailure(parsedError);
      }

      setIsLoading(false);
    },
    [requester, onSuccess, onFailure, mutate, token]
  );

  useEffect(
    function igniteRequestIfRequired() {
      if (fetchAtInit && !data && !initiallyFetched) {
        (async () => {
          setInitiallyFetched(true);
          await request();
        })();
      }
    },
    [
      fetchAtInit,
      data,
      initiallyFetched,
      requester,
      request,
      setInitiallyFetched,
    ]
  );

  return { request, data, isLoading, error };
}

const traceHeaderKey = 'Trace-Id';

export async function axiosFetcher(url, options = {}) {
  const { authHeader, headers, fullResponse, tokenExpiry, cache } = options;
  const requestHeaders = {
    ...headers,
    [traceHeaderKey]: nanoid(process.env.uIdLength),
    ...(authHeader ? authHeader : []),
  };

  if (authHeader && new Date().getTime() > tokenExpiry * 1000) {
    mutate(`${STALE_DATA_PREFIX}${url}`, true);
    return cache.get(url)?.data;
  }

  try {
    const res = await axios.get(url, {
      headers: requestHeaders,
    });
    // We have a bigger issue here - errors will be returned with 200 status code and error inside the response body
    if (res?.data?.error) {
      throw new Error(res?.data?.error);
    }

    return fullResponse ? res : res.data;
  } catch (err) {
    logRequestErrors(err, url);
    const parsed = parseGeneralServerError(err.message);
    const error = new Error(parsed.msg || parsed);
    error.traceId = parsed?.traceId || requestHeaders[traceHeaderKey];
    error.code = parsed?.code ?? err.message;

    throw error;
  }
}

export function axiosWithTokenFetcher(authHeader, tokenExpiry, cache) {
  return function bindToken(url, options = {}) {
    return axiosFetcher(url, { ...options, authHeader, tokenExpiry, cache });
  };
}

/**
 * Handle GET requests using SWR and retries
 *
 * @param {string} key - key used by swr
 * @param {object} options - Additional options to be passed to SWR hook call
 * @param {boolean} withToken - should add dbassTokenHeaderKey to request header, default false
 * @param {boolean} notifyOnError - should snackbar be displayed on error / retry, default false
 * First 3 failures will be retried and show a warning auto dismissed snackbar
 * Any further failure will not be retried by default and will show a manually dismissed snackbar with trace id
 * @return {object} { request, data, isLoading, error }
 * @property {object} data - data returned from the resolved request
 * @property {string} error - error occurred on request or returned by server
 * Note that this usually handled with snackbar notifications
 * In case it is a known error code, it will be parsed
 * @property {boolean} isLoading - is there is no error or data for the request
 * @property {boolean} isValidating - if there's a request or revalidation loading
 */
export function useQueryRequest({
  key,
  options = {},
  withToken = false,
  notifyOnError = true,
  customFetcher,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const retries = useRef(0);
  let appliedOptions = options;

  const shouldNotRetry = retries.current > MAX_RETRIES;

  if (notifyOnError && !options?.onError) {
    appliedOptions = {
      onError: (err, key) => {
        // TODO: when we have a logger we should log the information
        if (retries.current > 0 && retries.current < MAX_RETRIES) {
          enqueueSnackbar(
            "An error occurred while fetching information. Don't worry, we are retrying...",
            { variant: SNACKBAR_VARIANTS.WARNING, key: 'network-fetch-warning' }
          );
        } else if (retries.current === Number(MAX_RETRIES)) {
          enqueueSnackbar(
            <div>
              <p>
                An error occurred while fetching information, please check your
                network connection
              </p>
              <p>
                {err.message}. trace-id: {err.traceId}
              </p>
            </div>,
            {
              variant: SNACKBAR_VARIANTS.ERROR,
              persist: true,
              key: `fetch-${key}-error`,
            } // manually dismiss errors
          );
        }
        retries.current += 1;
      },
      onSuccess: () => {
        retries.current = 0;
      },
      errorRetryCount: MAX_RETRIES,
      ...options,
    };
  }

  const authHeader = useAuthHeader();
  const auth = useAuth();
  const { cache } = useSWRConfig();

  const swrResponse = useSWR(
    shouldNotRetry ? '' : key,
    customFetcher ??
      (withToken
        ? axiosWithTokenFetcher(authHeader, auth.user?.exp, cache)
        : axiosFetcher),
    appliedOptions
  );

  return {
    get data() {
      if (Array.isArray(swrResponse?.data)) {
        return swrResponse?.data?.map(d => d.data);
      }
      return swrResponse?.data?.data;
    },
    get error() {
      return swrResponse?.error;
    },
    // Can be used if we explicitly want to inform when data is refetch after the initial fetched
    get isValidating() {
      return swrResponse?.isValidating;
    },
    // Note that after error we will still present loading state. We handle errors globally as notification. Consuming components will continue displaying loading.
    get isLoading() {
      if (!key) {
        return false;
      }

      if (retries.current > 0 && retries.current < MAX_RETRIES) {
        return true;
      }

      return swrResponse?.isLoading;
    },
    get mutate() {
      return swrResponse.mutate;
    },
  };
}

export function useMultipleQueryRequest({
  keys,
  options,
  withToken = true,
  notifyOnError = true,
}) {
  const authHeader = useAuthHeader();

  function arrayFetcher(urlList) {
    const fetcher = withToken
      ? axiosWithTokenFetcher(authHeader)
      : axiosFetcher;
    const singleFetch = url => fetcher(url, options);
    return Promise.all(urlList.map(singleFetch));
  }

  return useQueryRequest({
    key: keys,
    options,
    notifyOnError,
    customFetcher: arrayFetcher,
  });
}

export function useFetchList(key, name, options, fallbackData) {
  const { data, error, isLoading } = useQueryRequest({ key, options });
  return {
    [name]: data?.[name] ?? fallbackData,
    isLoading,
    error,
  };
}

export function useFetchItem(listUrl, listName, id, name, options) {
  const { data, error, isLoading } = useQueryRequest({
    key: listUrl && id ? listUrl : null,
    options,
  });

  return {
    [name]: data?.[listName]?.find(v => v.id === id),
    isLoading,
    error,
  };
}
