import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import classNames from 'classnames';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import AccountSelectionDropdown from 'components/layouts/app-layout/header/AccountSelectionDropdown';
import ClusterSelectionDropdown from 'components/layouts/app-layout/header/ClusterSelectionDropdown';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  item: {
    color: 'white',
    fontSize: theme.utils.pxToRem(16),
    fontWeight: 400,
  },
  separator: {
    margin: theme.spacing(0.5),
    color: 'white',
    svg: {
      color: 'white',
    },
  },
}));

const breadcrumbNameMap = [
  {
    routes: [
      { path: '/', exact: true },
      { path: '/clusters/list', exact: true },
      { path: '/clusters/maintenance', exact: true },
    ],
    breadcrumbs: [],
  },
  {
    routes: [{ path: '/clusters/new/*' }],
    breadcrumbs: [
      {
        name: 'New Cluster',
      },
    ],
  },
  {
    routes: [{ path: '/clusters/:clusterId/*' }],
    breadcrumbs: [
      {
        name: 'My Clusters',
        Component: ClusterSelectionDropdown,
      },
    ],
  },
  {
    routes: [{ path: '/billing/*' }],
    breadcrumbs: [
      {
        name: 'Billing',
      },
    ],
  },
  {
    routes: [{ path: '/managed-resources/*' }],
    breadcrumbs: [
      {
        name: 'Managed Resources',
        link: '/managed-resources',
      },
    ],
  },
  {
    routes: [{ path: '/maintenance-windows/*' }],
    breadcrumbs: [
      {
        name: 'Maintenance Windows',
        link: '/maintenance-windows',
      },
    ],
  },
  {
    routes: [{ path: '/notifications' }],
    breadcrumbs: [
      {
        name: 'Notification Preferences',
      },
    ],
  },
  {
    routes: [{ path: '/usage' }],
    breadcrumbs: [
      {
        name: 'Usage',
      },
    ],
  },
];

function Breadcrumbs({ classes }) {
  const location = useLocation();
  const navigate = useNavigate();

  const matchedRoute = breadcrumbNameMap.filter(({ routes }) => {
    const match = matchRoutes(routes, location.pathname);
    return match;
  })[0];

  return (
    <>
      <MuiBreadcrumbs
        separator={'/'}
        aria-label="breadcrumb"
        classes={{
          separator: classes.separator,
        }}
      >
        <AccountSelectionDropdown />
        <Button
          color="inheritWhite"
          onClick={() => {
            navigate('/');
          }}
          className={classes.item}
        >
          All Clusters
        </Button>
        {matchedRoute?.breadcrumbs.map(({ name, link, disabled, Component }) =>
          Component ? (
            <Component key={name} />
          ) : (
            <Button
              color="inheritWhite"
              key={name}
              onClick={() => {
                if (link) {
                  navigate(link);
                }
              }}
              className={classNames(classes.item, {
                [classes.disabledItem]: disabled,
              })}
              sx={{
                '&.Mui-disabled': {
                  color: 'white',
                },
              }}
              disabled={disabled}
            >
              {name}
            </Button>
          )
        )}
      </MuiBreadcrumbs>
    </>
  );
}

export default withJss(Breadcrumbs);
