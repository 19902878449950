/**
 * Theme colors
 * https://www.figma.com/design/DZis4cW7Ko4yhBPit9fBzX/Scylla-UI-Variables
 * */

// Primary
const primary = '#2196F3';
const primaryDark = '#0288D1';
const primaryLight = '#42A5F5';
const primaryLighter = '#B1DEFD';
const primaryBg = '#D6F0FF';

// Brand Primary
const brandPrimary = '#57D1E5';
const brandPrimaryDark = '#27B6DB';
const brandPrimaryLight = '#5ADDF2';
const brandPrimaryLighter = '#BAF3FC';
const brandPrimaryBg = '#D9FAFF';

// Secondary
const secondary = '#4458A3';
const secondaryDark = '#33427A';
const secondaryLight = '#6E86FA';
const secondaryLighter = '#C2CEFE';
const secondaryBg = '#DEE5FF';

// Success
const success = '#4CAF50';
const successDark = '#36784C';
const successLight = '#77D464';
const successLighter = '#CCF2CD';
const successBg = '#E8FCF0';

// Info
const info = '#326DE6';
const infoDark = '#3161C4';
const infoLight = '#4DB5FF';
const infoLighter = '#C0F3ED';
const infoBg = '#E6F2FC';

// Warning
const warning = '#F79000';
const warningDark = '#E46B1A';
const warningLight = '#FFBB33';
const warningLighter = '#FDE8BB';
const warningBg = '#FCF6E8';

// Error
const error = '#CE291F';
const errorDark = '#942C2C';
const errorLight = '#FF6857';
const errorLighter = '#FDCDC4';
const errorBg = '#FCEEE8';

// Grays
const white = '#FFFFFF';
const gray50 = '#F7FAFC';
const gray100 = '#EDF5F7';
const gray200 = '#D8E5EB';
const gray300 = '#C3D5DE';
const gray400 = '#AEC0CF';
const gray500 = '#95A7BA';
const gray600 = '#7B8BA2';
const gray700 = '#616D87';
const gray800 = '#4B546F';
const gray900 = '#383D57';
const gray950 = '#2F324B';
const black = '#23263B';

// Components
const textDisabled = '#95A7BA';
const textMuted = '#616D87';
const textColor = '#4B546F';
const textEmphasis = '#383D57';
const tooltipText = '#D8E5EB';
const tooltipBg = '#23263BD9'; // #23263B 85%
const alertText = gray50;
const alertTextDark = gray800;
const disabledBg = gray200;
const bgColor = '#F6F8FF';
const cardBg = white;
const cardBgDark = gray50;
const wellBg = '#F0F2F8';
const codeBg = gray50;
const borderColor = '#EEEEEE';
const borderDark = '#C6D5E1';
const linkColor = primaryDark;
const navbarTextColor = gray100;

// Color Groups
const iconColors = [
  textColor,
  primary,
  secondary,
  success,
  info,
  warning,
  error,
];

export default {
  primary,
  primaryDark,
  primaryLight,
  primaryLighter,
  primaryBg,
  brandPrimary,
  brandPrimaryDark,
  brandPrimaryLight,
  brandPrimaryLighter,
  brandPrimaryBg,
  secondary,
  secondaryDark,
  secondaryLight,
  secondaryLighter,
  secondaryBg,
  success,
  successDark,
  successLight,
  successLighter,
  successBg,
  info,
  infoDark,
  infoLight,
  infoLighter,
  infoBg,
  warning,
  warningDark,
  warningLight,
  warningLighter,
  warningBg,
  error,
  errorDark,
  errorLight,
  errorLighter,
  errorBg,
  white,
  gray50,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  gray700,
  gray800,
  gray900,
  gray950,
  black,
  textDisabled,
  textMuted,
  textColor,
  textEmphasis,
  tooltipText,
  tooltipBg,
  alertText,
  alertTextDark,
  disabledBg,
  bgColor,
  cardBg,
  cardBgDark,
  wellBg,
  codeBg,
  borderColor,
  borderDark,
  linkColor,
  navbarTextColor,
  iconColors,
};
