import classnames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  plane: {
    position: 'relative',
    top: -200,
    left: -220,
    transformStyle: 'preserve-3d',
    transform: 'translateX(200px)  rotateX(50deg) rotateZ(45deg) rotateY(0deg)',
  },
  square: {
    width: '50px',
    height: '50px',
    padding: '40px',
    position: 'absolute',
    transformStyle: 'preserve-3d',
    willChange: 'transform',
    animation: `${theme.keyframes.loader} ${theme.keyframes.variables.loader.duration}s infinite linear`,
    animationTimingFunction: 'ease-in-out',

    '&:nth-child(2)': {
      animationDelay: `-${theme.keyframes.variables.loader.duration / 4}s`,
    },
    '&:nth-child(3)': {
      animationDelay: `-${theme.keyframes.variables.loader.duration / 2}s`,
    },
    '&:nth-child(4)': {
      animationDelay: `-${
        (3 * theme.keyframes.variables.loader.duration) / 4
      }s`,
    },
  },
  side: {
    position: 'relative',
    zIndex: 'inherit',
    border: `1px solid ${theme.custom.colors.borderDark}`,
    width: `${theme.keyframes.variables.loader.cubeSide}px`,
    height: `${theme.keyframes.variables.loader.cubeSide}px`,

    '&.top': {
      backgroundColor: theme.custom.colors.gray100,
      transform: 'translateY(0px) translateZ(0px)',
    },
    '&.left': {
      backgroundColor: theme.custom.colors.gray300,
      transform: `translateY(-${
        theme.keyframes.variables.loader.cubeSide / 2 + 0.5
      }px) translateZ(-${
        theme.keyframes.variables.loader.cubeSide / 2
      }px) rotateX(90deg)`,
    },
    '&.right': {
      backgroundColor: theme.custom.colors.white,
      transform: `translateX(${
        theme.keyframes.variables.loader.cubeSide / 2 - 0.5
      }px) translateY(-100px) translateZ(-${
        theme.keyframes.variables.loader.cubeSide / 2
      }px) rotateY(90deg)`,
    },
  },
}));

function LoaderAnimation({ classes = {} }) {
  return (
    <div className={classes.plane}>
      <div className={classes.square}>
        <div className={classnames(classes.side, 'top')} />
        <div className={classnames(classes.side, 'left')} />
        <div className={classnames(classes.side, 'right')} />
      </div>
      <div className={classes.square}>
        <div className={classnames(classes.side, 'top')} />
        <div className={classnames(classes.side, 'left')} />
        <div className={classnames(classes.side, 'right')} />
      </div>
      <div className={classes.square}>
        <div className={classnames(classes.side, 'top')} />
        <div className={classnames(classes.side, 'left')} />
        <div className={classnames(classes.side, 'right')} />
      </div>
      <div className={classes.square}>
        <div className={classnames(classes.side, 'top')} />
        <div className={classnames(classes.side, 'left')} />
        <div className={classnames(classes.side, 'right')} />
      </div>
    </div>
  );
}

LoaderAnimation.propTypes = {
  classes: PropTypes.shape({}),
};

export default withJss(LoaderAnimation);
