import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';
import { pxToRem } from 'definitions/utils';
import { useExtractClusterMetrics } from 'hooks/cluster';
import { SUCCESS } from 'services/translations/notifications';

const withJss = withStyles(theme => ({
  caption: {
    margin: '10px 0 0',
  },
  alert: {
    margin: '20px 0',
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  iconContainer: {
    backgroundColor: theme.colors.aeroBlue,
    border: `${theme.spacing(1)} solid ${theme.colors.honeyDewLight}`,
    borderRadius: '50%',
    width: theme.spacing(6.5),
    height: theme.spacing(6.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    margin: `${theme.spacing(2.5)} 0 ${theme.spacing(1)} 0`,
    fontSize: theme.utils.pxToRem(18),
    fontWeight: '500',
    color: theme.colors.black,
  },
  checkIcon: {
    color: theme.colors.spanishGreen,
  },
  textLarger: {
    fontSize: pxToRem(16),
  },
}));

function ClusterMetricsModal({ hideModal, classes, params: { clusterId } }) {
  const { isLoading, request, error, data } =
    useExtractClusterMetrics(clusterId);
  const isSuccess = Boolean(data);

  return (
    <MainModal onClose={hideModal} maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<CheckCircleOutlineIcon />}
      >
        Activate Cluster Metrics
      </MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId
          isSuccess={isSuccess}
          successTitle={SUCCESS.CLUSTER.EXTRACT}
          error={error}
          className={classes.alert}
          id="extractClusterMetrics"
        />
        {!isSuccess && (
          <div>
            <Typography paragraph className={classes.textLarger}>
              Would you like to enable Cluster Metrics? For more information and
              assistance, check out{' '}
              <Typography
                color="primary"
                component="a"
                href={documentationLinks.cloudDocs.cloudSetup.promProxy}
                target="_blank"
                className={classes.textLarger}
              >
                documentation
              </Typography>
              .
            </Typography>
          </div>
        )}
      </MainModalContent>

      <MainModalActions>
        {isSuccess ? (
          <MainModalActionButton
            id="goBackBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={hideModal}
            fullWidth
          >
            CLOSE
          </MainModalActionButton>
        ) : (
          <div className={classes.buttonsContainer}>
            <MainModalActionButton
              id="extractClusterMetricsBtn"
              type={MainModalActionButton.Types.MAJOR}
              /*
                To prevent syntetic event from being passed to the request function,
                we have to use an arrow function here, otherwise the event will be passed as an argument
                and token will not be passed to the requester
              */
              onClick={() => request()}
              isLoading={isLoading}
            >
              ACTIVATE
            </MainModalActionButton>
            <MainModalActionButton
              id="extractClusterMetricsCancelBtn"
              type={MainModalActionButton.Types.MINOR}
              onClick={hideModal}
            >
              CANCEL
            </MainModalActionButton>
          </div>
        )}
      </MainModalActions>
    </MainModal>
  );
}

ClusterMetricsModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterId: PropTypes.string,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(ClusterMetricsModal);
