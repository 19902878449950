import { REQUEST_FAILED, INVALID_DBAAS_TOKEN } from '@siren-frontend/shared';

export const errorParser = (body, { isFailure }) => {
  if (isFailure) {
    throw new Error(REQUEST_FAILED);
  }

  if (body && body.error) {
    if (body.error === 'TOKEN_REQUIRED') {
      throw new Error(INVALID_DBAAS_TOKEN);
    }
    throw new Error(body.error);
  }

  return body;
};

const cloudAccountStatusParser = checkStatus => ({
  error: checkStatus.error,
  boundaryPolicy: checkStatus.boundaryPolicy,
  cloudPolicy: checkStatus.cloudPolicy,
});

const cloudAccountPropertyParser = properties => ({
  awsAccountId: properties.awsAccountId,
  boundaryPolicyArn: properties.boundaryPolicyArn,
  cloudPolicyArn: properties.cloudPolicyArn,
  cloudRoleArn: properties.cloudRoleArn,
  cloudRoleExternalId: properties.cloudRoleExternalId,
});

export const cloudAccountParser = cloudAccount => ({
  id: cloudAccount.id,
  cloudProviderId: cloudAccount.cloudProviderId,
  owner: cloudAccount.owner,
  state: cloudAccount.state,
  ...(cloudAccount.checkStatus && {
    checkStatus: cloudAccountStatusParser(cloudAccount.checkStatus),
  }),
  ...(cloudAccount.properties && {
    properties: cloudAccountPropertyParser(cloudAccount.properties),
  }),
});
