import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { TextField, Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import MainModal, {
  MainModalActionButton,
  MainModalActions,
  MainModalContent,
  MainModalHeader,
} from 'components/common/main-modal';
import ClusterLockedContent, {
  LOCKED_CLUSTER_ACTIONS,
} from 'components/common/modals/ClusterLocked/content';
import { useMuiForm } from 'components/pages/new-cluster/hooks/form';
import withStyles from 'components/withStylesAdapter';
import { useAllClusterList } from 'hooks/cluster';
import { useRenameCluster } from 'hooks/clusterRequest';
import { SUCCESS } from 'services/translations/notifications';
import { clusterValidators } from 'services/validators';

const withJss = withStyles(theme => ({
  lockWrapper: {
    padding: `0 ${theme.spacing(2)}`,
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  input: {
    ...theme.extensions.borderedInput,
  },
  list: {
    listStyleType: 'disc',
    marginLeft: '1rem',
    p: {
      marginBottom: 0,
    },
  },
  rulesTitle: {
    marginBottom: theme.spacing(1),
  },
}));

function RenameClusterModal({
  hideModal,
  classes,
  params: { clusterId, isLocked },
}) {
  const theme = useTheme();
  const form = useMuiForm({ mode: 'onChange' }, {});
  const { muiRegister } = form;
  const {
    request,
    error: renameError,
    data: renameResponse,
  } = useRenameCluster(clusterId, hideModal);
  const { clusters, isLoading: isClustersListLoading } = useAllClusterList(
    false,
    false
  );
  const submitModeChange = data => {
    request(data.inputValue);
  };
  const isSuccess = Boolean(renameResponse);

  const isDisabled =
    isClustersListLoading || !form?.formState?.isValid || isLocked;

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <form>
        <MainModalHeader
          onClose={hideModal}
          HelperIcon={<DriveFileRenameOutlineIcon fontSize="medium" />}
          helperIconColor={theme.colors.mineShaft}
        >
          Rename cluster
        </MainModalHeader>

        {isLocked ? (
          <div className={classes.lockWrapper}>
            <ClusterLockedContent
              action={LOCKED_CLUSTER_ACTIONS.RENAME_CLUSTER}
            />
          </div>
        ) : (
          <>
            <MainModalContent>
              <Typography paragraph>
                Enter a new name for this cluster
              </Typography>
              <AlertWithTraceId
                isSuccess={isSuccess}
                successTitle={SUCCESS.CLUSTER.RENAME}
                error={renameError}
                className={classes.alert}
                id="renameClusteralert"
              />
              <TextField
                id="clusterNameInput"
                variant="standard"
                autoFocus={true}
                placeholder="Cluster name"
                slotProps={{
                  input: {
                    disableUnderline: true,
                    classes: {
                      input: classes.input,
                    },
                  },
                }}
                sx={{ mb: 2 }}
                fullWidth
                {...muiRegister({
                  name: 'inputValue',
                  ...clusterValidators.clusterName(clusters),
                })}
              />
              <Typography className={classes.rulesTitle} paragraph>
                Please ensure your cluster name:
              </Typography>

              <ul className={classes.list}>
                <li>
                  <Typography paragraph>
                    Starts and ends with an alphanumeric character (a-z, A-Z,
                    0-9).
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    Contains only alphanumeric characters, spaces, underscores
                    (_), at symbols (@), hyphens (-), ampersands (&), or
                    hashtags (#).
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>
                    Is between 2 to 63 characters long.
                  </Typography>
                </li>
                <li>
                  <Typography paragraph>Is unique.</Typography>
                </li>
              </ul>
            </MainModalContent>
          </>
        )}

        <MainModalActions>
          <div className={classes.buttonsContainer}>
            <MainModalActionButton
              id="changeClusterNameBtn"
              data-testid="submit-cluster-rename"
              type={MainModalActionButton.Types.MAJOR}
              className={classes.downloadBtn}
              disabled={isDisabled}
              onClick={form.handleSubmit(submitModeChange)}
            >
              RENAME
            </MainModalActionButton>
            <MainModalActionButton
              id="cancelModalBtn"
              type={MainModalActionButton.Types.MINOR}
              onClick={hideModal}
            >
              CANCEL
            </MainModalActionButton>
          </div>
        </MainModalActions>
      </form>
    </MainModal>
  );
}

RenameClusterModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterId: PropTypes.string,
    isLocked: PropTypes.bool,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(RenameClusterModal);
