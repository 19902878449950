import { keyMirror } from '@siren-frontend/shared/utils';
/**
 * only flags declared here will be evaluated
 * @todo move to config?
 */
export const flags = keyMirror({
  account_ca: null,
});

export const stringified = Object.values(flags).join(',');
