/* server side validations general error */
import { SERVER_ERRORS } from '@siren-frontend/shared/constants/errors';

export function parseGeneralServerError(error = 'UNKNOWN_ERROR') {
  let message, code;

  if (error in SERVER_ERRORS) {
    message = SERVER_ERRORS[error]?.clientMessage || SERVER_ERRORS[error];
    code = error;
  } else if (error?.message && error.message in SERVER_ERRORS) {
    message =
      SERVER_ERRORS[error.message]?.clientMessage ||
      SERVER_ERRORS[error.message];
    code = error.message;
  } else if (error === 'UNKNOWN_ERROR') {
    message = 'Unexpected error. please try again.';
  } else {
    message = `Unexpected server error occurred (${
      error?.message ?? error
    }). Please contact support.`;
  }

  return {
    msg: message,
    traceId: error?.traceId,
    code,
  };
}

export function getErrorTraceId(errorWithTraceId) {
  return errorWithTraceId?.traceId;
}

export function getErrorMessage(errorWithTraceId) {
  return errorWithTraceId?.msg || 'Unexpected error. please try again.';
}
