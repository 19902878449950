import { CLUSTER_WIZARD_PARAMS } from '@siren-frontend/shared';
import { DEAL_TYPES, BROADCAST_TYPE } from '@siren-frontend/shared';
import { publicIpv4 } from 'public-ip';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { userAccount } from 'components/pages/new-cluster/hooks/fallbackData';
import { useAccount } from 'hooks/account';
import { useFetchItem, useFetchList, useQueryRequest } from 'hooks/fetch';
import { validateIpAddress } from 'services/validators';

const apiUrl = process.env.apiUrl;

const versionsUrl = `${apiUrl}/deployment/scylla-versions`;

export function useScyllaVersions(fallbackData) {
  return useFetchList(versionsUrl, 'scyllaVersions', {}, fallbackData);
}

const cloudProvidersUrl = `${apiUrl}/deployment/cloud-providers`;

export function useCloudProviders(fallbackData) {
  return useFetchList(cloudProvidersUrl, 'cloudProviders', {}, fallbackData);
}

const regionsUrl = cloudProviderId =>
  cloudProviderId
    ? `${apiUrl}/deployment/cloud-provider/${cloudProviderId}/regions`
    : null;

export function useRegions(cloudProviderId, includeDefault) {
  const key =
    cloudProviderId &&
    `${regionsUrl(cloudProviderId)}${includeDefault ? '?defaults=true' : ''}`;

  const { data, error, isLoading } = useQueryRequest({
    key,
  });

  return {
    isLoading,
    error,
    regions: data?.regions,
    defaultRegionId: data?.defaultRegionId,
  };
}

export function useRegion(cloudProviderId, id) {
  return useFetchItem(regionsUrl(cloudProviderId), 'regions', id, 'region', {});
}

export function useRegionInstances(cloudProviderId, regionId, fallbackRegions) {
  const key =
    cloudProviderId &&
    regionId &&
    `${apiUrl}/deployment/cloud-provider/${cloudProviderId}/region/${regionId}`;

  const { data, ...rest } = useQueryRequest({
    key,
  });

  return {
    instances: data?.instances || fallbackRegions || [],
    zones: data?.zones,
    ...rest,
  };
}

export function useRegionInstance(cloudProviderId, regionId, instanceId) {
  const { isLoading, isError, instances } = useRegionInstances(
    cloudProviderId,
    regionId
  );

  return {
    instance: instances?.find(i => i.id === instanceId),
    isLoading,
    isError,
  };
}

export function useInstance(cloudProviderId, instanceId) {
  const key =
    cloudProviderId &&
    instanceId &&
    `${apiUrl}/deployment/cloud-provider/${cloudProviderId}/instance/${instanceId}`;

  const { data, ...rest } = useQueryRequest({
    key,
  });

  return {
    instance: data || {},
    ...rest,
  };
}

export function useDefaultMonitorManager(cloudProviderId, regionId) {
  const key =
    cloudProviderId &&
    regionId &&
    `${apiUrl}/deployment/cloud-provider/${cloudProviderId}/region/${regionId}/monitor-manager-default`;

  return useQueryRequest({ key });
}

function usePublicIpRequest() {
  const [isLoading, setIsLoading] = useState(false);
  const [ipv4, setIpv4] = useState();
  const [error, setError] = useState();

  useEffect(function queryPublicIp() {
    setIsLoading(true);
    (async () => {
      try {
        const ipv4 = await publicIpv4();
        const validationError = validateIpAddress(ipv4);

        if (validationError) {
          setError(validationError);
          setIsLoading(false);
          return;
        }

        setIpv4(ipv4);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    })();
  }, []);

  return {
    ipv4,
    isLoading,
    error,
  };
}

function useIpFromNodeServer(queryNodeServer = false) {
  const key = queryNodeServer ? `${apiUrl}/deployment/client-ip` : null;
  const { data, error: queryError, isLoading } = useQueryRequest({ key });

  let error = queryError;
  let ipFromNodeServer = data?.clientIp;

  const validationError = !isLoading && validateIpAddress(data?.clientIp);
  if (validationError) {
    error = validationError;
    ipFromNodeServer = undefined;
  }

  return {
    ipFromNodeServer,
    isLoading,
    error,
  };
}

export function useClientIp() {
  const [queryNodeServer, setQueryNodeServer] = useState(false);

  const {
    ipv4,
    isLoading: isPublicIpLoading,
    error: publicIpError,
  } = usePublicIpRequest();

  const {
    ipFromNodeServer,
    isLoading: isNodeIpLoading,
    error: nodeIpError,
  } = useIpFromNodeServer(queryNodeServer);

  useEffect(
    function startQuery() {
      if (publicIpError) {
        setQueryNodeServer(true);
      }
    },
    [publicIpError]
  );

  return {
    clientIp: ipv4 || ipFromNodeServer,
    isLoading: isNodeIpLoading || isPublicIpLoading,
    error: publicIpError && nodeIpError,
  };
}

const defaults = {
  broadcastType: BROADCAST_TYPE.PUBLIC,
  cidrBlock: '172.31.0.0/24',
  cloudProviderId: 1,
  accountCredentialId: 1,
  instanceId: 62,
  regionId: 1,
  replicationFactor: 3,
  numberOfNodes: 3,
  userApiInterface: 'CQL',
  allowedIPs: [],
  encryptionAtRest: {},
};

export function getNewClusterDefaults() {
  return defaults;
}

export function useFreeTierHours() {
  const { data: account, isLoading: isAccountLoading } =
    useAccount(userAccount);

  const freeTierDeal = account?.deals?.find(
    d => d.type === DEAL_TYPES.FREE_TIER
  );
  const totalHours = freeTierDeal?.total;
  const hoursLeft = freeTierDeal?.total - (freeTierDeal?.used ?? 0);

  const isFreeTierUsedUp = !isAccountLoading && hoursLeft === 0;

  return {
    totalHours,
    hoursLeft,
    isFreeTierUsedUp,
  };
}

export function getUrlDeal() {
  const params = new URL(document.location).searchParams;
  return params.get(CLUSTER_WIZARD_PARAMS.DEAL);
}

export function useUrlDeal() {
  const [searchParams] = useSearchParams();
  return searchParams.get(CLUSTER_WIZARD_PARAMS.DEAL);
}
