import * as custom from 'definitions/mui/custom';
import * as utils from 'definitions/utils';

const baseAlertStyles = {
  filled: color => ({
    backgroundColor: color,
    color: custom.colors.white,
  }),
  outlined: (color, bgColor) => ({
    border: `1px solid ${color}`,
    background: bgColor,
    color: color,
    '& .MuiAlert-icon': {
      color: color,
    },
  }),
  standard: (color, bgColor) => ({
    backgroundColor: bgColor,
    color: color,
    '& .MuiAlert-icon': {
      color: color,
    },
  }),
};

const MuiAlertOverrides = {
  styleOverrides: {
    root: {
      marginBottom: utils.pxToRem(12),
    },
    message: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'unset',
      width: '100%',
    },
    filledPrimary: baseAlertStyles.filled(custom.colors.primary),
    outlinedPrimary: baseAlertStyles.outlined(
      custom.colors.primary,
      custom.colors.primaryBg
    ),
    standardPrimary: baseAlertStyles.standard(
      custom.colors.primary,
      custom.colors.primaryBg
    ),

    filledSecondary: baseAlertStyles.filled(custom.colors.secondary),
    outlinedSecondary: baseAlertStyles.outlined(
      custom.colors.secondary,
      custom.colors.secondaryBg
    ),
    standardSecondary: baseAlertStyles.standard(
      custom.colors.secondary,
      custom.colors.secondaryBg
    ),

    filledInfo: baseAlertStyles.filled(custom.colors.info),
    outlinedInfo: baseAlertStyles.outlined(
      custom.colors.info,
      custom.colors.infoBg
    ),
    standardInfo: baseAlertStyles.standard(
      custom.colors.info,
      custom.colors.infoBg
    ),

    filledWarning: baseAlertStyles.filled(custom.colors.warning),
    outlinedWarning: baseAlertStyles.outlined(
      custom.colors.warning,
      custom.colors.warningBg
    ),
    standardWarning: baseAlertStyles.standard(
      custom.colors.warning,
      custom.colors.warningBg
    ),

    filledError: baseAlertStyles.filled(custom.colors.error),
    outlinedError: baseAlertStyles.outlined(
      custom.colors.error,
      custom.colors.errorBg
    ),
    standardError: baseAlertStyles.standard(
      custom.colors.error,
      custom.colors.errorBg
    ),

    filledSuccess: baseAlertStyles.filled(custom.colors.success),
    outlinedSuccess: baseAlertStyles.outlined(
      custom.colors.success,
      custom.colors.successBg
    ),
    standardSuccess: baseAlertStyles.standard(
      custom.colors.success,
      custom.colors.successBg
    ),
  },
};

const MuiAlertTitleOverrides = {
  styleOverrides: {
    root: {
      color: 'unset',
      fontSize: utils.pxToRem(14),
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      marginTop: 0,
      a: {
        color: 'inherit',
        fontWeight: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
};

export { MuiAlertOverrides, MuiAlertTitleOverrides };
