import classnames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  root: {
    animation: `${theme.keyframes.rotate} 0.6s infinite linear`,
    borderBottom: '0.2em solid #e5e6e6',
    borderLeft: '0.2em solid #7b7f83',
    borderRight: '0.2em solid #e5e6e6',
    borderTop: '0.2em solid #e5e6e6',
    display: 'inline-block',
    fontSize: theme.utils.pxToRem(10),
    position: 'relative',
    textIndent: '-9999em',

    '&, &:after': {
      borderRadius: '50%',
      height: '1.15em',
      width: '1.15em',
    },

    '&.small': {
      width: '16px',
      height: '16px',
    },

    '&.large': {
      width: '20px',
      height: '20px',
    },
  },
}));

const Spinner = ({ size = 'small', className, classes }) => {
  const cssClassName = classnames(
    classes.root,
    {
      [classes.small]: size === 'small',
      [classes.large]: size === 'large',
    },
    className
  );

  return (
    <span className={cssClassName} data-testid="spinner" data-loading="true" />
  );
};

Spinner.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    small: PropTypes.string,
    large: PropTypes.string,
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']),
};

export { Spinner };

export default withJss(Spinner);
