import WarningIcon from '@mui/icons-material/Warning';
import { Typography, useTheme } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import AlertWithTraceId from 'components/common/alert-with-trace-id';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import Skeleton from 'components/common/skeleton';
import withStyles from 'components/withStylesAdapter';
import { useOwnCloudAccount } from 'hooks/account';
import { useCloudAccountClusters, useDeleteCloudAccount } from 'hooks/byoa';
import { usePrevious } from 'utils/hooks';

const withJss = withStyles(theme => ({
  caption: {
    margin: '10px 0 0',
  },
  alert: {
    margin: '20px 0',
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  deleteButton: {
    ...theme.extensions.customButton(),
  },
  icon: {
    fill: theme.colors.monza,
    width: theme.utils.pxToRem(20),
    marginRight: theme.utils.pxToRem(9),
  },
  modalHeader: {
    display: 'flex',
    justifyItems: 'center',
    borderBottomColor: 'transparent',
  },
  title: {
    fontSize: theme.utils.pxToRem(12),
    fontWeight: 700,
  },
  cancelBtn: {
    minWidth: 'initial',
  },
}));

function DeleteCloudAccount({ classes, hideModal, isSuccess }) {
  const theme = useTheme();
  const previousIsSuccess = usePrevious(isSuccess);
  const navigate = useNavigate();

  const {
    data: { id: cloudAccountId } = {},
    isLoading: isOwnAccountLoading,
    error: ownAccountError,
  } = useOwnCloudAccount();
  const {
    data: cloudAccountClusters,
    isLoading: isCloudAccountClustersLoading,
    error: cloudAccountClustersError,
  } = useCloudAccountClusters();
  const {
    request: deleteCloudAccount,
    isLoading: isDeleteLoading,
    error: deleteError,
  } = useDeleteCloudAccount();

  const shouldDelete =
    !cloudAccountClusters || cloudAccountClusters?.length === 0;
  const isLoading =
    isCloudAccountClustersLoading || isDeleteLoading || isOwnAccountLoading;
  const error = deleteError || cloudAccountClustersError || ownAccountError;

  useEffect(
    function closeOnSuccess() {
      if (isSuccess && !previousIsSuccess) {
        hideModal();
      }
    },
    [isSuccess, previousIsSuccess, hideModal]
  );

  function handleRedirect() {
    navigate('/clusters/list');
    hideModal();
  }

  async function handleDelete() {
    await deleteCloudAccount(cloudAccountId);

    navigate('/clusters/list');
    hideModal();
  }

  function getTextContent() {
    if (shouldDelete) {
      return "This will remove your Cloud Account information from Scylla Cloud. You won't be able to create new Scylla Cloud clusters within your AWS Account. You can, however, use clusters on a managed Scylla Cloud account.";
    }

    const cloudAccountClustersNames = cloudAccountClusters?.map(c => c.name);

    if (cloudAccountClusters?.length === 1) {
      return `There is an active cluster running at this Cloud Account. It’s name is “${cloudAccountClustersNames?.[0]}”. If you’d like to delete a Cloud Account, delete the cluster first.`;
    }

    return `There are active clusters running at this Cloud Account. Their names are ${cloudAccountClustersNames?.join(
      ', '
    )}. If you’d like to delete a Cloud Account, delete these clusters first.`;
  }

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader
        className={classes.modalHeader}
        onClose={hideModal}
        HelperIcon={<WarningIcon fontSize="medium" />}
        helperIconColor={theme.colors.monza}
      >
        DELETE CLOUD ACCOUNT
      </MainModalHeader>
      <MainModalContent>
        <AlertWithTraceId
          error={error}
          className={classes.alert}
          id="deleteCloudAccountAlert"
        />
        {isLoading ? (
          <>
            <Skeleton width="60%" height="14px" />
            <Skeleton width="40%" height="14px" />
          </>
        ) : (
          <Typography variant="caption" paragraph>
            {getTextContent()}
          </Typography>
        )}
      </MainModalContent>
      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="deleteCloudAccountBtn"
            disabled={isLoading}
            type={MainModalActionButton.Types.MAJOR}
            className={classNames({ [classes.deleteButton]: shouldDelete })}
            onClick={shouldDelete ? handleDelete : handleRedirect}
            isLoading={isLoading}
          >
            {shouldDelete ? 'DELETE' : 'GO TO CLUSTERS'}
          </MainModalActionButton>
          <MainModalActionButton
            id="deleteCloudAccountCancelBtn"
            type={MainModalActionButton.Types.MINOR}
            className={classes.cancelBtn}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

DeleteCloudAccount.propTypes = {
  classes: PropTypes.shape({}),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(DeleteCloudAccount);
