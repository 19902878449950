import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';

import ClusterLockedContent, {
  LOCKED_CLUSTER_ACTIONS,
  LOCKED_ACTIONS_TEXT,
} from './content';

const withJss = withStyles(theme => ({
  headerTitle: {
    justifyContent: 'start',
    fontWeight: 500,
    fontSize: theme.utils.pxToRem(20),
    color: theme.colors.mako,
  },
}));

function ClusterLockedModal({ hideModal, params: { action }, classes }) {
  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        classes={{
          headerTitle: classes.headerTitle,
        }}
      >
        {LOCKED_ACTIONS_TEXT[action].header}
      </MainModalHeader>

      <MainModalContent>
        <ClusterLockedContent action={action} />
      </MainModalContent>
      <MainModalActions centered>
        <Box>
          <MainModalActionButton
            id="closeModalBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={hideModal}
          >
            I UNDERSTAND
          </MainModalActionButton>
        </Box>
      </MainModalActions>
    </MainModal>
  );
}

ClusterLockedModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    action: PropTypes.oneOf(Object.values(LOCKED_CLUSTER_ACTIONS)).isRequired,
  }),
  classes: PropTypes.shape({}),
};

export default withJss(ClusterLockedModal);
