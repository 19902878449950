import {
  Box,
  LinearProgress as MuiLinearProgress,
  Typography,
} from '@mui/material';

import * as custom from 'definitions/mui/custom';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: custom.colors.textColor }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function LinearProgress({ withLabel, ...rest }) {
  return withLabel ? (
    <LinearProgressWithLabel data-loading="true" {...rest} />
  ) : (
    <MuiLinearProgress data-loading="true" {...rest} />
  );
}
