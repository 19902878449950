import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ClusterName from 'components/common/ClusterName';
import Skeleton from 'components/common/skeleton';
import withStyles from 'components/withStylesAdapter';
import { useAllClusterList } from 'hooks/cluster';
import { status as clusterStatus } from 'services/cluster';

const withJss = withStyles(theme => ({
  button: {
    color: 'white',
    fontSize: theme.utils.pxToRem(16),
    fontWeight: 400,
  },
  endIcon: {
    marginLeft: 0,
  },
  item: {
    fontSize: theme.utils.pxToRem(14),
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
    lineHeight: '1.25rem',
  },
  disabledItem: {
    fontSize: theme.utils.pxToRem(12),
    color: theme.colors.sonicSilver,
    paddingTop: '0.25rem',
    paddingBottom: '0.25rem',
    '&.Mui-disabled': {
      opacity: 'unset',
    },
  },
}));

function ClusterSelectionDropdown({ classes }) {
  const navigate = useNavigate();
  const { clusterId } = useParams();

  const { clusters, isLoading: allClustersLoading } = useAllClusterList({
    enriched: true,
    enablePolling: false,
  });
  const currentCluster = clusters?.find(c => String(c.id) == String(clusterId));
  const clustersToList = clusters?.filter(
    c => c.id !== currentCluster?.id && c.status === clusterStatus.active
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isLoading = allClustersLoading;

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Skeleton fullSize isLoading={isLoading}>
      <Button
        className={classes.button}
        endIcon={clusters?.length > 1 ? <ArrowDropDownIcon /> : null}
        data-testid="clusters-dropdown"
        onClick={handleClick}
        classes={{
          endIcon: classes.endIcon,
        }}
      >
        Cluster:&nbsp;
        <ClusterName name={currentCluster?.clusterName} />
      </Button>
      {clusters?.length > 1 ? (
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={classes.menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          MenuListProps={{
            'aria-labelledby': 'clusters-dropdown',
          }}
        >
          <MenuItem className={classes.disabledItem} disabled>
            Other Clusters
          </MenuItem>
          {clustersToList?.map(c => (
            <MenuItem
              key={c.id}
              className={classes.item}
              onClick={() => {
                handleClose();
                navigate(`/clusters/${c.id}`);
              }}
            >
              <ClusterName name={c?.clusterName} />
            </MenuItem>
          ))}
        </Menu>
      ) : null}
    </Skeleton>
  );
}

export default withJss(ClusterSelectionDropdown);
