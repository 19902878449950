import { alpha } from '@mui/material';
import MuiChip from '@mui/material/Chip';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Spinner from 'components/common/Spinner';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles((theme, { onClick, color }) => {
  const bgColor = theme.custom.colors[`${color}Bg`];
  const hoverBgColor = theme.custom.colors[`${color}Lighter`];
  const textColor = theme.custom.colors[`${color}Dark`];
  const closeIconColorUnhovered = alpha(
    color && color !== 'default'
      ? theme.custom.colors[`${color}Dark`]
      : theme.custom.colors.textColor,
    0.2
  );

  return {
    chip: {
      display: 'flex',
      width: 'fit-content',
      transition: 'background-color .3s, color .3s',
      '& > .MuiChip-icon:not(.MuiChip-deleteIcon)': {
        color: 'inherit',
      },
      '&:hover, &:active, &:focus': {
        cursor: onClick ? 'pointer' : 'text',
        '& > .MuiChip-icon:not(.MuiChip-deleteIcon)': {
          color: 'inherit',
        },
      },
      '& > .MuiChip-deleteIcon:hover': {
        color: 'inherit',
      },
    },
    default: {
      backgroundColor: theme.custom.colors.wellBg,
      color: theme.custom.colors.textColor,
      '& > .MuiChip-deleteIcon': {
        color: closeIconColorUnhovered,
      },
      '&:hover, &:active, &:focus': {
        backgroundColor: onClick
          ? theme.custom.colors.brandPrimaryBgHover
          : theme.custom.colors.wellBg,
        color: onClick
          ? theme.custom.colors.textEmphasis
          : theme.custom.colors.textColor,
      },
    },
    [`statusColor-${color || 'default'}`]: {
      backgroundColor: bgColor,
      color: textColor,
      '&:hover, &:active, &:focus': {
        backgroundColor: onClick ? hoverBgColor : bgColor,
        color: textColor,
      },
      '& > .MuiChip-deleteIcon': {
        color: closeIconColorUnhovered,
      },
    },
    spinner: {
      verticalAlign: 'middle',
    },
  };
});

function Chip(
  {
    label,
    color = 'default',
    onClick,
    onDelete,
    icon,
    isLoading = false,
    disabled = false,
    className,
    classes,
    ...chipProps
  },
  chipRef
) {
  return (
    <MuiChip
      ref={chipRef}
      className={classnames(
        classes.chip,
        {
          [classes[`statusColor-${color}`]]:
            color !== 'default' && color !== 'brand',
          [classes.default]: color === 'default',
        },
        className
      )}
      size="small"
      variant="filled"
      icon={icon}
      clickable={!!onClick}
      onClick={onClick}
      onDelete={onDelete}
      data-loading={isLoading}
      label={
        isLoading ? (
          <span>
            {label}
            &nbsp;
            <Spinner className={classes.spinner} />
          </span>
        ) : (
          label
        )
      }
      disabled={disabled}
      {...chipProps}
    />
  );
}

const ChipRef = forwardRef(Chip);

ChipRef.propTypes = {
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  label: PropTypes.string,
  icon: PropTypes.node,
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
  ]),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.shape({
    chip: PropTypes.string,
    default: PropTypes.string,
    brand: PropTypes.string,
    statusColor: PropTypes.string,
  }),
};

export default withJss(ChipRef);
