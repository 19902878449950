import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

import Chip from 'components/common/Chip';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(() => ({
  input: {
    '& .MuiInputBase-root': {
      gap: '0.1rem',
    },
  },
}));

const defaultAddDelimiters = [',', ';', ' '];

function isDelimiterKeyPressed(string, addDelimiters = defaultAddDelimiters) {
  return addDelimiters.some(delimiter => string.endsWith(delimiter));
}

/**
 * ChipsInput properties.
 *
 * @typedef {object} Props
 * @property {object} form - return type of `useForm()`
 * @property {string} label - input label
 * @property {string} [listName] - list name as it will appear in the form
 * @property {string} [inputName] - input name as it will appear in the form and used as id / data-testid
 * @property {object} [required] - whether the input is required or can be blank
 * @property {boolean} required.value - enable or disable required
 * @property {string} required.message - error message to show if required error
 * @property {string[]} [defaultValues] - default values to include
 * @property {string[]} [addDelimiters] - characters to use to determine when to add new item to the list
 * @property {object} [inputValidationRules] - input validation rules
 * @property {object} inputValidationRules.pattern
 * @property {string} inputValidationRules.pattern.value
 * @property {string} inputValidationRules.pattern.message
 * @property {object} [listValidationRules] - list validation rules, each method should return either true or the error message (Record<string, (list: T) => true | string>)
 */

function ChipsInput({
  form,
  label,
  listName = 'chipsList',
  inputName = 'chipsInput',
  required,
  inputValidationRules,
  listValidationRules,
  defaultValues,
  addDelimiters,
  classes,
}) {
  const [inputValue, setInputValue] = useState('');

  const hasRequiredError =
    !inputValue && form.formState.errors[listName]?.type === 'required';
  const hasInvalidInputError = form.formState.errors[inputName];
  const hasInvalidListError = Boolean(
    form.formState.errors[listName]?.type &&
      form.formState.errors[listName]?.message
  );

  const inputFormProps = form.muiRegister({
    name: inputName,
    useErrorText: true,
    ...(inputValidationRules || {}),
  });

  function handleValueChange(newValue, onChange) {
    if (newValue.length === 0) {
      onChange(newValue);
      return;
    }
    if (
      !inputValidationRules?.pattern?.value ||
      inputValidationRules.pattern.value.test(newValue.at(-1))
    ) {
      setInputValue('');
      onChange(newValue);
      return;
    }
    setInputValue(newValue.at(-1));
    form.trigger(inputName);
  }

  return (
    <Controller
      control={form.control}
      name={listName}
      rules={{
        required,
        validate: form.formState.isDirty && listValidationRules,
      }}
      defaultValue={defaultValues || []}
      render={({ field: { value = [], onChange, ...fieldProps } }) => (
        <Autocomplete
          multiple
          options={[]}
          disableClearable
          value={value}
          inputValue={inputValue}
          onInputChange={(e, newValue) => {
            if (
              isDelimiterKeyPressed(newValue, addDelimiters) &&
              newValue.trim().length
            ) {
              handleValueChange([...value, newValue.slice(0, -1)], onChange);
              return;
            }
            setInputValue(newValue);
          }}
          onChange={(e, newValue) => {
            handleValueChange(newValue, onChange);
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip label={option} {...getTagProps({ index })} key={index} />
            ))
          }
          {...fieldProps}
          renderInput={props => (
            <TextField
              variant="standard"
              className={classes.input}
              label={label}
              name={inputName}
              id={inputName}
              data-testid={inputName}
              {...inputFormProps}
              onChange={(...args) => {
                form.clearErrors(inputName);
                form.clearErrors(listName);
                inputFormProps.onChange(...args);
              }}
              onBlur={(...args) => {
                if (inputValue !== '') {
                  handleValueChange([...value, inputValue], onChange);
                }
                inputFormProps.onBlur(...args);
              }}
              error={Boolean(
                hasRequiredError || hasInvalidInputError || hasInvalidListError
              )}
              helperText={
                (hasRequiredError && required.message) ||
                (hasInvalidInputError &&
                  inputValidationRules.pattern.message) ||
                (hasInvalidListError &&
                  form.formState.errors[listName]?.message)
              }
              slotProps={{
                formHelperText: {
                  'data-testid': `helperText${inputName}`,
                },
              }}
              {...props}
            />
          )}
        />
      )}
    />
  );
}

ChipsInput.propTypes = {
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  listName: PropTypes.string,
  inputName: PropTypes.string,
  required: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    message: PropTypes.string,
  }).isRequired,
  inputValidationRules: PropTypes.shape({
    pattern: PropTypes.shape({
      value: PropTypes.instanceOf(RegExp).isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  }),
  listValidationRules: PropTypes.objectOf(PropTypes.func),
  defaultValues: PropTypes.arrayOf(PropTypes.string),
  addDelimiters: PropTypes.arrayOf(PropTypes.string),
  classes: PropTypes.object,
};

export default withJss(ChipsInput);
