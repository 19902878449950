import {
  compose,
  eq,
  every,
  flow,
  isEmpty,
  prop,
  some,
  split,
  toLower,
} from 'lodash/fp';
import subnetOverlap from 'subnet-overlap';

import ERRORS from 'services/translations/field-validations';
import * as TYPES from 'services/translations/types';

const NAME_REGEX = /^[^.:;<>/{}\\]*$/u; // Any unicode character except for some specific ones to not include links etc.
const PHONE_REGEX = /^(\(?\+?[0-9]{5}\)?)?[0-9_\-()]{0,30}$/;
const ZIP_CODE_REGEX = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i;
const IP_ADDRESS_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const IPV6_ADDRESS_REGEX =
  /(?:^|(?<=\s))(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))(?=\s|$)/;
const CIDR_REGEX =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/([0-9]|[1-2][0-9]?|[3][0-2])$/;
const CIDR_BLOCK_REG = /^([0-9]|[1-2][0-9]?|[3][0-2]?)$/;
const CIDR_BLOCK_IS_26_REGEX = /\/(26)$/;
const CIDR_BLOCK_OVER_26_REGEX = /\/(2[7-9]|3[0-2])$/;
const RFC_1918_CIDR_REGEX =
  /^(10(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/(([8-9])|([1-2][0-9])|(3[0-1]))$)|(192\.168(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/((1[6-9])|(2[0-9])|(3[0-1]))$)|(172\.(?:1[6-9]|2\d|3[0-1])(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/((1[2-9])|(2[0-9])|(3[0-1]))$)/;
const MULTI_RFC_1918_CIDR_REGEX =
  /(^|[,])(10(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\/(([8-9])|([1-2][0-9])|(3[0-1]))$)|(192\.168(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/((1[6-9])|(2[0-9])|(3[0-1]))$)|(172\.(?:1[6-9]|2\d|3[0-1])(?:\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){2}\/((1[2-9])|(2[0-9])|(3[0-1]))+$)/;

const AWS_TGW_ID_REGEX = /^tgw-[A-Za-z0-9]+$/;

const minCIDRrange = 32;

const CONNECTION_NAME_REGEX = /^[A-Za-z0-9_-]+$/;
const CLUSTER_NAME_REGEX = /^[a-zA-Z0-9][@_a-zA-Z0-9&# -]{0,61}[a-zA-Z0-9]$/;
const COMPANY_NAME_REGEX = /^$|^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/;
const CITY_REGEX = /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/;
const VPC_ID_REGEX = /^vpc-.+$/i;
const PASSWORD_REGEX = {
  NUMBER: /(?=.*?[0-9])/,
  LETTER: /(?=.*?[a-zA-Z])/,
  SPECIAL: /(?=.*?[#?!@$%^&*\-+=_<>|"'`(){}[\]:;,.\\/~\s])/,
};
const AWS_ACCOUNT_ID_REGEX = /^\d{12}$/;
// arn:aws|aws-cn|aws-us-gov:service:region:account-id:resource-id
const AWS_ARN_REGEX =
  /arn:(aws|aws-cn|aws-us-gov):[A-Za-z0-9\-\]+:[A-Za-z0-9]+:\d{12}:[A-Za-z0-9_*]+\/[A-Za-z0-9_*]+/;
//arn:aws:ram:us-east-2:043400831220:resource-share/5493ee26-93f3-4dda-b49f-f8a7da4f5a69
const AWS_RAM_ARN_REGEX =
  /^arn:(aws|aws-cn|aws-us-gov):ram:[A-Za-z0-9-]+:\d{12}:[A-Za-z0-9-]+\/[a-z0-9+=,.@_-]+$/;
// arn:aws:iam:[region]:account-id:policy/[additional]
const AWS_POLICY_ARN_REGEX =
  /^arn:aws:iam:[A-Za-z0-9]*:\d{12}:policy\/[A-Za-z0-9+=,.@_-]{1,128}$/;
// arn:aws:iam:[region]:account-id:role/[additional]
const AWS_ROLE_ARN_REGEX =
  /^arn:aws:iam:[A-Za-z0-9]*:\d{12}:role\/[A-Za-z0-9+=,.@_-]{1,64}$/;

// unique string of 6 to 30 lowercase letters, digits, or hyphens. It must start with a letter, and cannot have a trailing hyphen
const GCP_PROJECT_ID_REGEX = /^[a-z]{1}[a-z0-9-]{4,28}[a-z0-9]{1}$/;
// name must be 1-63 characters long, and comply with RFC1035. Specifically, the name must be 1-63 characters long and match the regular expression [a-z]([-a-z0-9]*[a-z0-9])? which means the first character must be a lowercase letter, and all following characters must be a dash, lowercase letter, or digit, except the last character, which cannot be a dash. For more information: https://cloud.google.com/compute/docs/reference/rest/v1/networks
const GCP_NETWORK_NAME_REGEX = /^[a-z]([a-z0-9-]{0,60}[a-z0-9])?$/;

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // https://emailregex.com/

const patterns = {
  CIDR_REGEX,
  NAME_REGEX,
  CONNECTION_NAME_REGEX,
  CLUSTER_NAME_REGEX,
  RFC_1918_CIDR_REGEX,
  MULTI_RFC_1918_CIDR_REGEX,
  VPC_ID_REGEX,
  GCP_PROJECT_ID_REGEX,
  GCP_NETWORK_NAME_REGEX,
  AWS_ACCOUNT_ID_REGEX,
  EMAIL_REGEX,
};

/* used for both logic and input forms validations */
export const passwordValidators = {
  minLength: password => password.length >= 8,
  number: password => PASSWORD_REGEX.NUMBER.test(password),
  letter: password => PASSWORD_REGEX.LETTER.test(password),
  special: password => PASSWORD_REGEX.SPECIAL.test(password),
};

/* Hook form oriented validators */
export const clusterValidators = {
  /**
   * @typedef {{clusterName: string}} Cluster
   * @param {Cluster[]} clusters
   * @returns {object}
   */
  clusterName: clusters => ({
    required: { value: true, message: 'This field is required' },
    maxLength: {
      value: 63,
      message: 'Cluster name should contain a maximum of 63 characters',
    },
    pattern: {
      value: patterns.CLUSTER_NAME_REGEX,
      message:
        'Cluster name should start and end with an alphanumeric character and can contain @ _ & # -',
    },
    validate: {
      /**
       * compare lowercased cluster names
       * @param {string} name
       * @returns {string|boolean}
       */
      unique: name =>
        some(compose(eq(toLower(name)), toLower, prop('clusterName')), clusters)
          ? 'Cluster name should be unique'
          : true,
    },
  }),
  cidrBlock: {
    required: {
      value: true,
      message: 'This field is required',
    },
    pattern: {
      value: patterns.CIDR_REGEX,
      message: 'Please enter a valid CIDR value',
    },
  },
  rfc1918CidrBlock: {
    required: {
      value: true,
      message: 'This field is required',
    },
    pattern: {
      value: patterns.RFC_1918_CIDR_REGEX,
      message: 'Please enter a valid CIDR value according to RFC 1918',
    },
  },
  tgwCidrList: {
    required: {
      value: true,
      message: 'This field is required',
    },
    validate: cidrList => cidrList.length <= 5,
    validateMessage: 'Maximum of 5 CIDR blocks are allowed',
  },
  tgwCidrInput: {
    pattern: {
      value: patterns.RFC_1918_CIDR_REGEX,
      message: 'Please enter a valid CIDR value according to RFC 1918',
    },
  },
};

export const vpcValidators = {
  awsAccountId: {
    required: {
      value: true,
      message: 'Please enter your AWS account ID',
    },
    pattern: {
      value: patterns.AWS_ACCOUNT_ID_REGEX,
      message: 'Please enter a valid twelve digit AWS account ID',
    },
  },
  gcpProjectId: {
    required: {
      value: true,
      message: 'Please enter your GCP project ID',
    },
    pattern: {
      value: patterns.GCP_PROJECT_ID_REGEX,
      message: 'Please enter a valid project ID',
    },
  },
  vpcId: {
    required: {
      value: true,
      message: 'Please enter a VPC ID',
    },
    pattern: {
      value: patterns.VPC_ID_REGEX,
      message: 'Please enter a valid VPC ID (start with `vpc-`)',
    },
  },
  gcpNetworkName: {
    required: {
      value: true,
      message: 'Please enter your GCP network name',
    },
    pattern: {
      value: patterns.GCP_NETWORK_NAME_REGEX,
      message: 'Please enter a valid network name',
    },
  },
  multiRfc1918CidrBlock: {
    required: {
      value: true,
      message: 'This field is required',
    },
    pattern: {
      value: patterns.MULTI_RFC_1918_CIDR_REGEX,
      message: 'Please enter a valid CIDR value according to RFC 1918',
    },
  },
};

export const connectionValidators = {
  connectionName: {
    required: { value: true, message: 'This field is required' },
    pattern: {
      value: patterns.CONNECTION_NAME_REGEX,
      message:
        'Transit Gateway name should contain only alphanumerical and special characters - _',
    },
  },
  dc: {
    required: { value: true, message: 'This field is required' },
  },
  tgwRamArn: {
    required: { value: false },
    pattern: {
      value: AWS_RAM_ARN_REGEX,
      message: 'Please enter a valid AWS RAM ARN',
    },
  },
  tgwId: {
    required: { value: true, message: 'This field is required' },
    pattern: {
      value: AWS_TGW_ID_REGEX,
      message:
        "ID must start with 'tgw-' and be followed only by alphanumeric characters",
    },
  },
};

export const paymentValidators = {
  companyName: {
    required: { value: true, message: ERRORS[TYPES.COMPANYNAME].BLANK },
    maxLength: {
      value: 30,
      message: ERRORS[TYPES.COMPANYNAME].LENGTH_LONG,
    },
    pattern: {
      value: COMPANY_NAME_REGEX,
      message: ERRORS[TYPES.COMPANYNAME].INVALID_FORMAT,
    },
  },
  address: {
    required: {
      value: true,
      message: ERRORS[TYPES.ADDRESS].BLANK,
    },
    minLength: {
      value: 1,
      message: ERRORS[TYPES.ADDRESS].INVALID_FORMAT,
    },
    maxLength: {
      value: 50,
      message: ERRORS[TYPES.ADDRESS].INVALID_FORMAT,
    },
  },
  city: {
    required: {
      value: true,
      message: ERRORS[TYPES.CITY].BLANK,
    },
    pattern: {
      value: CITY_REGEX,
      message: ERRORS[TYPES.CITY].INVALID_FORMAT,
    },
  },
  country: {
    required: {
      value: true,
      message: ERRORS[TYPES.COUNTRY].BLANK,
    },
  },
  state: {
    required: {
      value: true,
      message: ERRORS[TYPES.STATE].BLANK,
    },
  },
  zip: {
    required: {
      value: true,
      message: ERRORS[TYPES.POSTALCODE].BLANK,
    },
    pattern: {
      value: ZIP_CODE_REGEX,
      message: ERRORS[TYPES.POSTALCODE].INVALID_FORMAT,
    },
  },
  firstName: {
    required: {
      value: true,
      message: ERRORS[TYPES.FIRSTNAME].BLANK,
    },
    maxLength: {
      value: 30,
      message: ERRORS[TYPES.FIRSTNAME].LENGTH_LONG,
    },
    pattern: {
      value: NAME_REGEX,
      message: ERRORS[TYPES.FIRSTNAME].INVALID_FORMAT,
    },
  },
  lastName: {
    required: {
      value: true,
      message: ERRORS[TYPES.LASTNAME].BLANK,
    },
    maxLength: {
      value: 30,
      message: ERRORS[TYPES.LASTNAME].LENGTH_LONG,
    },
    pattern: {
      value: NAME_REGEX,
      message: ERRORS[TYPES.LASTNAME].INVALID_FORMAT,
    },
  },
};

export const byoaValidators = {
  awsRoleArn: {
    required: { value: true, message: ERRORS[TYPES.AWS_ROLE_ARN].BLANK },
    pattern: {
      value: AWS_ROLE_ARN_REGEX,
      message: ERRORS[TYPES.AWS_ROLE_ARN].INVALID_FORMAT,
    },
  },
  awsPolicyArn: {
    required: { value: true, message: ERRORS[TYPES.AWS_POLICY_ARN].BLANK },
    pattern: {
      value: AWS_POLICY_ARN_REGEX,
      message: ERRORS[TYPES.AWS_POLICY_ARN].INVALID_FORMAT,
    },
  },
};

export const emailValidator = {
  input: {
    pattern: {
      value: EMAIL_REGEX,
      message: 'Please enter a valid email address',
    },
  },
  list: {
    required: {
      value: true,
      message: 'This field is required',
    },
  },
};

/**
 * used for reset password logic
 * @param {String} password
 */
export function validatePassword(password) {
  if (!password || password.trim().length === 0) {
    return ERRORS.PASSWORD.BLANK;
  }

  if (password.length < 8) {
    return ERRORS.PASSWORD.LENGTH_SHORT;
  }
  if (password.length > 64) {
    return ERRORS.PASSWORD.LENGTH_LONG;
  }
  if (!passwordValidators.number(password)) {
    return ERRORS.PASSWORD.NO_NUMBER;
  }
  if (!passwordValidators.letter(password)) {
    return ERRORS.PASSWORD.NO_LETTER;
  }
  if (!passwordValidators.special(password)) {
    return ERRORS.PASSWORD.NO_SPECIAL_CHAR;
  }

  return undefined;
}

export function comparePasswords(password, originalPassword) {
  if (password !== originalPassword) {
    return ERRORS.PASSWORD.NOT_MATCHED;
  }

  return undefined;
}

/**
 * used for input validators inside a form
 */

export function validatePhoneNumber(phoneNumber) {
  return PHONE_REGEX.test(phoneNumber);
}

export function validateZipCode(zipCode) {
  return ZIP_CODE_REGEX.test(zipCode);
}

export function validateIsTrue(value) {
  return value === true;
}

export function validateName(name) {
  return NAME_REGEX.test(name);
}

export function validateClusterName(clusterName) {
  return CLUSTER_NAME_REGEX.test(clusterName);
}

export function validateCompanyName(companyName) {
  return COMPANY_NAME_REGEX.test(companyName);
}

export function validateAddress(address) {
  return address && address.length <= 50 && address.length >= 1;
}

export function validateCity(city) {
  return CITY_REGEX.test(city);
}

export function validateIp(ipAddress) {
  return (
    ipAddress === '' ||
    IP_ADDRESS_REGEX.test(ipAddress) ||
    CIDR_REGEX.test(ipAddress)
  );
}

export function validateCidrAddress(cidrAddress) {
  return cidrAddress === '' || CIDR_REGEX.test(cidrAddress);
}

export function validateMultiCidrAddress(cidrAddresses) {
  return (
    isEmpty(cidrAddresses) ||
    flow(
      split(','),
      every(cidrAddress => cidrAddress.match(CIDR_REGEX))
    )(cidrAddresses)
  );
}

export function validateCidrBlock(value, maxAllowedCIDRRange = 16) {
  return CIDR_BLOCK_REG.test(value) &&
    value >= maxAllowedCIDRRange &&
    value <= minCIDRrange
    ? null
    : `Accepted range of CIDR is ${maxAllowedCIDRRange}-${minCIDRrange}`;
}

export function validateCIDROverlap(previousCidrs, newCidr, cidrOrigin = 'DC') {
  let errorText = ERRORS.CIDR.OVERLAPPING_ADDRESS_DC;

  if (cidrOrigin === 'VPC') {
    errorText = ERRORS.CIDR.OVERLAPPING_ADDRESS_VPC;
  }

  return subnetOverlap(previousCidrs, newCidr) ? errorText : null;
}

/* Cluster validations */
export function validateIPsArray(ipAddresses) {
  if (Array.isArray(ipAddresses) && ipAddresses.length === 0) {
    return ERRORS.ALLOWED_IPS.INVALID_LENGTH;
  }

  return undefined;
}

export function validateIpAddress(ipAddress) {
  if (!ipAddress || ipAddress.trim().length === 0) {
    return ERRORS.IP.BLANK;
  }

  if (!IP_ADDRESS_REGEX.test(ipAddress)) {
    return ERRORS.IP.INVALID_FORMAT;
  }

  return undefined;
}

export function validateCidr(cidrBlock) {
  if (!cidrBlock || cidrBlock.trim().length === 0) {
    return ERRORS.IP.BLANK;
  }

  if (!CIDR_REGEX.test(cidrBlock)) {
    return ERRORS.IP.INVALID_FORMAT;
  }

  return undefined;
}

export function validateVPCId(vpcId) {
  return VPC_ID_REGEX.test(vpcId);
}

export function validateIPV6(ip) {
  return IPV6_ADDRESS_REGEX.test(ip);
}

export function fixCidr(cidrBlock) {
  if (IP_ADDRESS_REGEX.test(cidrBlock)) {
    return `${cidrBlock}/32`;
  }

  if (CIDR_REGEX.test(cidrBlock)) {
    const [ip, blockStr] = cidrBlock.split('/', 2);
    const block = parseInt(blockStr, 10);
    const ipParts = ip.split('.', 4).map(p => parseInt(p, 10));
    const fixedIpParts = ipParts.map((p, i) =>
      (i + 1) * 8 < block
        ? p
        : parseInt(
            p
              .toString(2)
              .padStart(8, '0')
              .slice(0, Math.max(0, block - i * 8))
              .padEnd(8, '0'),
            2
          )
    );

    return `${fixedIpParts.join('.')}/${block}`;
  }

  return null;
}

export function validateAWSArn(arn) {
  return AWS_ARN_REGEX.test(arn);
}

export function validateAWSPolicyArn(arn) {
  return AWS_POLICY_ARN_REGEX.test(arn);
}

export function validateAWSRoleArn(arn) {
  return AWS_ROLE_ARN_REGEX.test(arn);
}

export function validateGCPProjectId(arn) {
  return GCP_PROJECT_ID_REGEX.test(arn);
}

export function validateGCPNetworkName(name) {
  return GCP_NETWORK_NAME_REGEX.test(name);
}

export function validateCidrIs26(value) {
  return CIDR_BLOCK_IS_26_REGEX.test(value);
}

export function validateCidrOver26(value) {
  const isOver26 = CIDR_BLOCK_OVER_26_REGEX.test(value);
  return isOver26
    ? 'This network range is too small. Please provide a larger network range.'
    : null;
}

export function validateCommaSeparatedEntriesCount(value, maxCount, message) {
  const entries = value.split(',').map(item => item.trim());
  return entries.length > maxCount ? message : null;
}

export function validateMatchingAWSId(value, providedAwsAccountId) {
  return value.includes(providedAwsAccountId)
    ? null
    : ERRORS.AWS_POLICY_ARN.MATCH_AWS_ID;
}
