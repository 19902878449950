import { always, assoc } from 'lodash/fp';

/**
 * @typedef {ConstructorParameters<
 *  typeof import('@openfeature/web-sdk').InMemoryProvider
 * >[0]} FlagConfiguration
 */

/**
 * this config mimics the "new" flags that one day will be fetched from Flipt
 * @type {FlagConfiguration}
 */
export const config = {};

/**
 * convert plain old string flags to InMemoryProvider config format
 * @param {string[]} flags
 * @returns {FlagConfiguration}
 */
export const stringFlagsToConfig = flags =>
  flags.reduce(
    (p, c) =>
      assoc(
        c,
        {
          disabled: false,
          variants: {
            on: true,
            off: false,
          },
          defaultVariant: 'on',
          contextEvaluator: always('on'),
        },
        p
      ),
    {}
  );
