import PropTypes from 'prop-types';

import Alert from 'components/common/Alert';
import { getErrorMessage, getErrorTraceId } from 'services/server-errors';

function getAlertText(isSuccess, error) {
  if (isSuccess) {
    return null;
  }
  if (getErrorTraceId(error)) {
    return `trace-id: ${getErrorTraceId(error)}`;
  }
  return null;
}

function AlertWithTraceId({ id, isSuccess, error, successTitle, ...props }) {
  return (
    <Alert
      visible={Boolean(isSuccess || error)}
      id={id}
      title={isSuccess ? successTitle : getErrorMessage(error)}
      text={getAlertText(isSuccess, error)}
      status={error ? 'error' : 'success'}
      {...props}
    />
  );
}

AlertWithTraceId.propTypes = {
  id: PropTypes.string.isRequired,
  isSuccess: PropTypes.bool,
  error: PropTypes.shape({
    msg: PropTypes.string,
    traceId: PropTypes.string,
  }),
  successTitle: PropTypes.string,
};

export default AlertWithTraceId;
