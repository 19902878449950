import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { CardElement } from '@recurly/react-recurly';
import CountryRegionData from 'country-region-data/data.json';
import isEqual from 'lodash/fp/isEqual';
import mapValues from 'lodash/fp/mapValues';
import omitBy from 'lodash/fp/omitBy';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import withStyles from 'components/withStylesAdapter';
import { paymentValidators } from 'services/validators';

const validationMessages = {
  number: 'Please enter a valid credit card',
  expiry: 'Please enter a valid expiration date for your credit card',
  cvv: 'Please enter a valid security code for your credit card',
};

const withJss = withStyles(
  theme => ({
    root: {
      [theme.breakpoints.up('md')]: {
        margin: '-20px',
      },
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    gridFormGroup: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2.5),
      },
      [theme.breakpoints.down('md')]: {
        '& + &': {
          marginTop: theme.spacing(5),
        },
      },
    },
    cardGroupBox: {
      padding: theme.spacing(1.5),
      borderRadius: '10px',
      border: `1px solid ${theme.colors.mercury}`,
      [theme.breakpoints.up('md')]: {
        flex: 'none',
        maxWidth: 'none',
        width: `calc(100% + ${theme.spacing(3)})`,
        margin: `${-theme.spacing(0.5)} ${-theme.spacing(
          11.5
        )} 0 ${-theme.spacing(1.5)}`,
      },
    },
    field: {
      marginTop: '10px',
      width: '100%',
    },
    validatedFieldContainer: {
      width: '100%',
    },
    recurlyCard: {
      padding: `${theme.spacing(2)} 0`,
      fontSize: '1.5rem',
    },
    customError: {
      fontSize: '0.75rem',
      marginTop: '8px',
      lineHeight: '1em',
    },
  }),
  { inject: ['classes', 'theme'] }
);

function PaymentForm({
  classes,
  countries = [],
  onChangeCountry,
  externalError,
  formRef,
  paymentFormHook,
}) {
  const { recurlyErrors, setRecurlyErrors, isRecurlyReady } = paymentFormHook;

  const {
    muiRegister,
    formState: hookFormState,
    setValue,
    getValues,
    control,
  } = paymentFormHook.hookForm;

  const fields = getValues();

  const recurlyHasError = Object.values(recurlyErrors).some(
    validity => validity
  );

  const countryOptions = countries?.map(({ name, code, group, id }) => ({
    value: code,
    label: name,
    group,
    id,
  }));

  /*
   when changing country, propagate to outer handlers as well as reset the state field
   */
  const handleChangeCountry = useCallback(
    countryCode => {
      const { group: countryGroup, id: selectedId } = countryOptions.find(
        country => country.value === countryCode
      );

      if (!countryCode || countryCode === fields.country) {
        return;
      }

      setValue('state', '', { shouldValidate: true });

      if (selectedId) {
        onChangeCountry({ countryId: selectedId, countryGroup });
      }
    },
    [countryOptions, fields, onChangeCountry, setValue]
  );

  const countryStatesOptions = useMemo(() => {
    const countryCode = fields.country;

    return (
      CountryRegionData.find(
        country => country.countryShortCode === countryCode
      ) || { regions: [] }
    )?.regions?.map(region => ({
      label: region.name,
      value: region.shortCode,
    }));
  }, [fields]);

  const handleRecurlyChange = useCallback(
    changeEvent => {
      const { empty } = changeEvent;
      const fieldErrors = empty
        ? {}
        : pipe(
            pick(['number', 'expiry', 'cvv']),
            mapValues(({ valid }) => !valid),
            omitBy(isError => !isError)
          )(changeEvent);

      if (!isEqual(fieldErrors, recurlyErrors)) {
        setRecurlyErrors(fieldErrors);
      }
    },
    [recurlyErrors, setRecurlyErrors]
  );

  const getRecurlyErrorMessage = useCallback(() => {
    const firstInvalidField = Object.entries(recurlyErrors).find(
      ([, error]) => error
    )?.[0];

    if (firstInvalidField) {
      return `${validationMessages[firstInvalidField]}`;
    }

    return '';
  }, [recurlyErrors]);

  return (
    <>
      <AlertWithTraceId id="paymentFormError" error={externalError} />
      <form ref={formRef}>
        <Grid container rowSpacing={3}>
          <Grid item container xs={12}>
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.subtitle}
            >
              Billing Address
            </Typography>
            <TextField
              id="companyName"
              data-testid="companyName"
              variant="standard"
              label="Company Name"
              className={classes.field}
              inputProps={{ 'data-recurly': 'company_name' }}
              {...muiRegister({
                name: 'companyName',
                defaultValue: fields.companyName,
                useErrorText: true,
                ...paymentValidators.companyName,
              })}
            />
            <TextField
              id="address"
              data-testid="address"
              variant="standard"
              label="Address"
              className={classes.field}
              inputProps={{ 'data-recurly': 'address1' }}
              {...muiRegister({
                name: 'address',
                defaultValue: fields.address,
                useErrorText: true,
                ...paymentValidators.address,
              })}
            />
            <TextField
              id="city"
              data-testid="city"
              variant="standard"
              label="City"
              className={classes.field}
              inputProps={{ 'data-recurly': 'city' }}
              {...muiRegister({
                name: 'city',
                defaultValue: fields.city,
                useErrorText: true,
                ...paymentValidators.city,
              })}
            />
            <FormControl
              variant="standard"
              className={classes.field}
              error={!!hookFormState?.errors?.country}
            >
              <InputLabel htmlFor="country">Country</InputLabel>
              <Select
                SelectDisplayProps={{
                  id: 'country',
                  'data-testid': 'country-select',
                }}
                label="Country"
                variant="standard"
                inputProps={{
                  'data-recurly': 'country',
                  'data-testid': 'country-select-input',
                }}
                {...muiRegister({
                  name: 'country',
                  onChange: evt => handleChangeCountry(evt?.target.value),
                  defaultValue: fields.country || '',
                  ...paymentValidators.country,
                })}
              >
                {countryOptions?.map(({ label, value }) => (
                  <MenuItem id={`country-${value}`} key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
              {!!hookFormState?.errors?.country && (
                <FormHelperText>
                  {paymentValidators.country.required.message}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              variant="standard"
              className={classes.field}
              error={!!hookFormState?.errors?.state}
            >
              <InputLabel htmlFor="state">State</InputLabel>
              <Controller
                control={control}
                name="state"
                rules={paymentValidators.state}
                render={({
                  field: { ref: inputRef, value = '', ...fieldProps },
                }) => (
                  <Select
                    SelectDisplayProps={{
                      id: 'state',
                      'data-testid': 'state-select',
                    }}
                    label="State"
                    variant="standard"
                    inputProps={{
                      'data-recurly': 'state',
                    }}
                    {...fieldProps}
                    value={value}
                    inputRef={inputRef}
                  >
                    {countryStatesOptions?.map(({ label, value }) => (
                      <MenuItem id={`state-${value}`} key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />

              {!!hookFormState?.errors?.state && (
                <FormHelperText>
                  {paymentValidators.state.required.message}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              id="postalCode"
              data-testid="postalCode"
              variant="standard"
              label="Zip/Postal"
              className={classes.field}
              inputProps={{ 'data-recurly': 'postal_code' }}
              {...muiRegister({
                name: 'zip',
                defaultValue: fields.zip,
                useErrorText: true,
                ...paymentValidators.zip,
              })}
            />
          </Grid>
          <Grid item container xs={12}>
            <Typography
              variant="subtitle1"
              component="h3"
              className={classes.subtitle}
            >
              Card Details
            </Typography>
            <Grid container item xs={12} columnSpacing={2}>
              <Grid item xs={8}>
                <TextField
                  id="firstName"
                  data-testid="firstName"
                  variant="standard"
                  label="First name"
                  className={classes.field}
                  inputProps={{ 'data-recurly': 'first_name' }}
                  {...muiRegister({
                    name: 'firstName',
                    defaultValue: fields.firstName,
                    useErrorText: true,
                    ...paymentValidators.firstName,
                  })}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  id="lastName"
                  data-testid="lastName"
                  variant="standard"
                  label="Last name"
                  className={classes.field}
                  inputProps={{ 'data-recurly': 'last_name' }}
                  {...muiRegister({
                    name: 'lastName',
                    defaultValue: fields.lastName,
                    useErrorText: true,
                    ...paymentValidators.lastName,
                  })}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {isRecurlyReady && (
                <CardElement
                  onChange={handleRecurlyChange}
                  className={classes.recurlyCard}
                />
              )}
              {recurlyHasError && (
                <Typography
                  color="error"
                  component="span"
                  className={classes.customError}
                  data-testid="recurlyError"
                >
                  {getRecurlyErrorMessage()}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

PaymentForm.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
  theme: PropTypes.shape({}),
  countries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  externalError: PropTypes.shape({ msg: PropTypes.string }),
  paymentFormHook: PropTypes.shape({}).isRequired,
};

export default withJss(PaymentForm);
