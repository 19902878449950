import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import LoadingSign from 'assets/images/loading-sign.svg?react';
import ClusterLockedContent, {
  LOCKED_CLUSTER_ACTIONS,
} from 'components/common/modals/ClusterLocked/content';
import withStyles from 'components/withStylesAdapter';
import { SELF_SERVICE_PROCESSOR } from 'services/self-service-resize';
import { useSupportRedirect } from 'utils/hooks';

const withJss = withStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  header: {
    paddingTop: theme.utils.pxToRem(16),
    paddingBottom: theme.utils.pxToRem(16),
    fontSize: theme.utils.pxToRem(20),
    lineHeight: theme.utils.pxToRem(32),
  },
  message: {
    textAlign: 'center',
    fontSize: theme.utils.pxToRem(14),
    lineHeight: theme.utils.pxToRem(20),
    color: theme.colors.baliHai,
  },
  zendeskLink: theme.extensions.link,
}));

function ResizeInProgressModalContent({
  classes,
  isLocked,
  isResizeInProgress,
  resizeType,
  resizeTicketId,
}) {
  const shouldIncludeLink = Boolean(
    resizeType === SELF_SERVICE_PROCESSOR.SUPPORT && resizeTicketId
  );

  const { redirectToSupport, isLoading: supportIsLoading } =
    useSupportRedirect(resizeTicketId);

  const resizeText =
    resizeType === SELF_SERVICE_PROCESSOR.AUTOMATION ? (
      'A resize for this cluster is currently in progress. Once completed, you’ll be able to perform a new resize.'
    ) : (
      <>
        An{' '}
        {shouldIncludeLink && !supportIsLoading ? (
          <Link className={classes.zendeskLink} onClick={redirectToSupport}>
            existing resize
          </Link>
        ) : (
          'exiting resize'
        )}{' '}
        request was sent to our Support team and will be handled as soon as
        possible. Once completed, you’ll be able to perform a new resize.
      </>
    );

  if (isResizeInProgress) {
    return (
      <Box className={classes.content}>
        <LoadingSign />
        <Typography
          variant="h6"
          className={classes.header}
          data-testid="resizeTextHeader"
        >
          Resize In Progress
        </Typography>
        <Typography
          variant="body2"
          className={classes.message}
          data-testid="resizeTextMessage"
        >
          {resizeText}
        </Typography>
      </Box>
    );
  } else if (isLocked) {
    return <ClusterLockedContent action={LOCKED_CLUSTER_ACTIONS.RESIZE} />;
  }

  return null;
}

ResizeInProgressModalContent.propTypes = {
  classes: PropTypes.shape({}),
  isLocked: PropTypes.bool.isRequired,
  isResizeInProgress: PropTypes.bool.isRequired,
  resizeType: PropTypes.string,
  resizeTicketId: PropTypes.number,
};

export default withJss(ResizeInProgressModalContent);
