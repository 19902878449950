import { getYear } from 'date-fns';
import PropTypes from 'prop-types';

import Logo from 'components/common/logo';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content max-content 1fr',
    gridGap: `0 ${theme.spacing(2)}`,
    alignItems: 'center',
    '& > a': {
      color: theme.colors.dustyGray,
    },
    borderTop: `1px solid ${theme.colors.mercury}`,
    fontSize: theme.utils.pxToRem(14),
    lineHeight: theme.utils.pxToRem(18),
    padding: `${theme.spacing(1)} ${theme.contentHPadding}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    '@media (max-width:580px)': {
      gridTemplateColumns: 'max-content 1fr',
      '& > a': {
        gridRowStart: 2,
      },
    },
    '& > *': {
      margin: `${theme.spacing(0.5)} 0`,
    },
  },
  logo: {
    display: 'block',
    height: '1rem',
    width: 'auto',
  },
  copyright: {
    justifySelf: 'end',
    '@media (max-width:580px)': {
      gridColumnStart: 2,
    },
  },
}));

function FooterComponent({ classes }) {
  return (
    <footer className={classes.root}>
      <Logo className={classes.logo} variant="horizontal" color="black" />
      <a
        href="https://www.scylladb.com/scylla-cloud-terms-of-service/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>
      <a
        href="https://www.scylladb.com/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy and Compliance Policy
      </a>
      <div className={classes.copyright}>
        &copy;ScyllaDB {getYear(new Date())}
      </div>
    </footer>
  );
}

FooterComponent.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withJss(FooterComponent);
