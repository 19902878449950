import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
import { Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';

import MainModal, {
  MainModalContent,
  MainModalHeader,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import {
  useClusterCACertificate,
  useAccountCACertificate,
} from 'hooks/clusterRequest';

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
  link: theme.extensions.link,
  downloadBtn: {
    whiteSpace: 'nowrap',
  },
}));

function DownloadCertificatesModal({
  hideModal,
  classes,
  params: { clusterId, clusterName },
}) {
  const theme = useTheme();
  const { request: downloadClusterCert } = useClusterCACertificate(clusterId);
  const { request: downloadAccountCert } = useAccountCACertificate();

  return (
    <MainModal onClose={hideModal} maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<GppGoodOutlinedIcon fontSize="medium" />}
        helperIconColor={theme.colors.adamantineBlue}
      >
        Download Certificates
      </MainModalHeader>
      <MainModalContent>
        <Typography paragraph>
          To encrypt the connection between your client and cluster using
          SSL/TLS you will need a trusted cluster certificate.
        </Typography>
        <Typography paragraph>
          All cluster certificates are signed by the ScyllaDB Certificate
          Authority. You can download and use the <b>CA Certificate</b> below
          and trust all ScyllaDB Cloud cluster certificates.
        </Typography>
        <Typography paragraph>
          Alternatively, you can download the <b>Cluster Certificate</b> for the{' '}
          <b>{clusterName}</b> cluster.
        </Typography>
        <Typography paragraph>
          For comprehensive information about certificate authorities, refer to
          the documentation page here.
        </Typography>
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="cancelModalBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
          <MainModalActionButton
            id="downloadAccountCaBtn"
            data-testid="download-ca-cert"
            type={MainModalActionButton.Types.MAJOR}
            onClick={() => downloadAccountCert()}
            className={classes.downloadBtn}
            fullWidth
          >
            CA Certificate
          </MainModalActionButton>
          <MainModalActionButton
            id="downloadClusterCaBtn"
            data-testid="download-cluster-cert"
            type={MainModalActionButton.Types.MAJOR}
            onClick={() => downloadClusterCert()}
            className={classes.downloadBtn}
            fullWidth
          >
            Cluster Certificate
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

DownloadCertificatesModal.propTypes = {
  classes: PropTypes.shape({}),
  params: PropTypes.shape({
    clusterName: PropTypes.string,
    clusterId: PropTypes.string,
  }),
  hideModal: PropTypes.func.isRequired,
};

export default withJss(DownloadCertificatesModal);
