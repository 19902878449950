import {
  CUSTOM_OWNER_NAME,
  SORT_DIRECTIONS,
  USER_API_INTERFACE,
  FAMILY_GROUP_NAMES,
} from '@siren-frontend/shared';

import StorageHeaderTooltip from 'components/pages/new-cluster/ClusterProperties/InstanceSelection/Tooltips/StorageHeaderTooltip';
import { useNewClusterData } from 'components/pages/new-cluster/hooks/context';
import { useCloudAccounts } from 'hooks/account';
import { billingVersions, calculateCostPerHour } from 'services/cluster';
import {
  instanceToClusterSpec,
  isDevelopmentInstance,
} from 'services/instance';
import { getAccountOwner } from 'services/utils';

export const DEFAULT_REPLICATION_FACTOR = 3;
export const DEFAULT_NUMBER_OF_NODES = 3;
export const DEFAULT_AWS_INSTANCE_EXTERNAL_ID = 62;
export const DEFAULT_GCP_INSTANCE_EXTERNAL_ID = 0;

export const INSTANCE_TABLE_CHANGE_TYPE = {
  NODES: 'NODES',
  VARIANT: 'VARIANT',
  INSTANCE: 'INSTANCE',
};

const evaluationFilter = instances =>
  instances?.filter(instance => isDevelopmentInstance(instance));

function createFamily(instanceFamilyName) {
  return {
    label: `${instanceFamilyName} family`,
    name: instanceFamilyName,
    filter: function filterInstancesByFamily(instances) {
      return instances?.filter(
        i =>
          i?.instanceFamily === instanceFamilyName && !isDevelopmentInstance(i)
      );
    },
  };
}

export function findSelectedVariant(groupInstances, selectedVariants) {
  return (
    groupInstances.find(
      i => i.id === selectedVariants[i.instance.externalId]
    ) ||
    groupInstances.find(i => i.instance.groupDefault) ||
    groupInstances[0]
  );
}

export const headerKeys = {
  instanceType: 'instanceType',
  numberOfNodes: 'numberOfNodes',
  vCPUs: 'vCPUs',
  throughput: 'throughput',
  memory: 'memory',
  storage: 'storage',
  freeHours: 'hours',
  cost: 'cost',
};

export const byoaCostHeader = {
  key: headerKeys.cost,
  label: (
    <>
      Scylla Subscription <br /> (cost per hour)
    </>
  ),
};

export const freeTierHeaders = [
  {
    key: headerKeys.freeHours,
    label: 'Cluster Hours',
  },
  {
    key: headerKeys.cost,
    label: 'Total Cost',
  },
];

export const scyllaAccountCostHeader = {
  key: headerKeys.cost,
  label: 'Total Cost Per Hour',
};

export const defaultHeaders = [
  {
    key: headerKeys.instanceType,
    label: 'Instance Type',
  },
  { key: headerKeys.vCPUs, label: 'vCPU' },
  { key: headerKeys.memory, label: 'Memory' },
  {
    key: headerKeys.storage,
    label: <StorageHeaderTooltip />,
  },
  {
    key: headerKeys.numberOfNodes,
    label: 'Number of Nodes',
  },
  {
    key: headerKeys.throughput,
    label: (
      <>
        Sustained / <br />
        Peak throughput
      </>
    ),
  },
];

export function belongsToSelectedFamily(family) {
  return function (instance) {
    if (family === FAMILY_GROUP_NAMES.ALL) {
      return true;
    }
    if (family === FAMILY_GROUP_NAMES.EVALUATE) {
      return isDevelopmentInstance(instance);
    }
    return instance.instanceFamily === family;
  };
}

function stringSort(a, b) {
  return String(a).localeCompare(b);
}
function numberSort(a, b) {
  return Number(a) - Number(b);
}
export function clusterSpecsSort(clusterSpecA, clusterSpecB, sortBy) {
  switch (sortBy) {
    case headerKeys.instanceType:
      return stringSort(
        clusterSpecA?.instance?.externalId,
        clusterSpecB?.instance?.externalId
      );
    case headerKeys.numberOfNodes:
      return numberSort(
        clusterSpecA?.numberOfNodes,
        clusterSpecB?.numberOfNodes
      );
    case headerKeys.vCPUs:
      return numberSort(
        clusterSpecA?.instance?.cpuCount,
        clusterSpecB?.instance?.cpuCount
      );
    case headerKeys.throughput:
      return numberSort(clusterSpecA?.peakLoad, clusterSpecB?.peakLoad);
    case headerKeys.memory:
      return numberSort(
        clusterSpecA?.instance?.memory,
        clusterSpecB?.instance?.memory
      );
    case headerKeys.storage:
      return numberSort(
        clusterSpecA?.instance?.totalStorage,
        clusterSpecB?.instance?.totalStorage
      );
    default:
      return numberSort(
        clusterSpecA?.totalCostPerHour,
        clusterSpecB?.totalCostPerHour
      );
  }
}
export function getFamiliesFromInstances(instances) {
  const defaultFamilies = {
    [FAMILY_GROUP_NAMES.ALL]: {
      label: 'All Instances',
      name: FAMILY_GROUP_NAMES.ALL,
      filter: instances => instances,
    },
    [FAMILY_GROUP_NAMES.EVALUATE]: {
      label: 'Evaluation',
      name: FAMILY_GROUP_NAMES.EVALUATE,
      filter: evaluationFilter,
    },
  };

  const familiesFromInstances = [
    ...new Set(
      instances
        .filter(i => !isDevelopmentInstance(i))
        .map(i => i.instanceFamily)
    ),
  ]
    .map(instanceFamilyName => createFamily(instanceFamilyName))
    .reduce(
      (families, instance) => ({
        ...families,
        [instance.name]: { ...instance },
      }),
      {}
    );

  return { ...defaultFamilies, ...familiesFromInstances };
}

export function useGetInstanceSelectionTableColumns() {
  const {
    state: { accountCredentialId, freeTier, userApiInterface },
  } = useNewClusterData();
  const { data: cloudAccounts } = useCloudAccounts();

  const isCqlEnabled = userApiInterface === USER_API_INTERFACE.CQL;
  const isUsingCrossAccount =
    getAccountOwner(accountCredentialId, cloudAccounts) === CUSTOM_OWNER_NAME;

  return (
    isCqlEnabled
      ? defaultHeaders
      : defaultHeaders.filter(header => header.key !== headerKeys.throughput)
  ).concat(
    freeTier
      ? freeTierHeaders
      : isUsingCrossAccount
        ? byoaCostHeader
        : scyllaAccountCostHeader
  );
}

export const SPECIAL_INSTANCES_TEXTS = {
  SANDBOX: {
    HEADER: 'Developer Mode',
    SUBHEADER:
      'This instance type is designed for development and has the following restrictions:',
    BULLETS: [
      'NOT suitable for staging or production environments',
      'Does not guarantee SLA or 24/7 support',
      'Does not include backup or repair',
      'Does not include Scylla Enterprise software upgrade',
      'Does not include AWS accounts migration',
      'Does not support multiple data centers',
      'Does not support cluster resize',
    ],
  },
};

export function addSpecsToInstances({
  instances,
  selectedNumberOfNodes,
  accountCredentialId,
  cloudAccounts,
}) {
  return instances
    .map(i =>
      instanceToClusterSpec(
        i,
        selectedNumberOfNodes[i.externalId] || DEFAULT_NUMBER_OF_NODES,
        DEFAULT_REPLICATION_FACTOR
      )
    )
    .map(i => ({
      ...i,
      totalCostPerHour: calculateCostPerHour(
        i.instance,
        selectedNumberOfNodes[i.instance.externalId] || DEFAULT_NUMBER_OF_NODES,
        accountCredentialId,
        cloudAccounts,
        billingVersions.v3
      ),
    }));
}

export function sortInstanceSpecs({
  groupedInstanceSpecs,
  selectedVariants,
  sortBy,
}) {
  return Object.entries(groupedInstanceSpecs).toSorted(
    ([, group1Instances], [, group2Instances]) => {
      const group1SelectedInstance = findSelectedVariant(
        group1Instances,
        selectedVariants
      );
      const group2SelectedInstance = findSelectedVariant(
        group2Instances,
        selectedVariants
      );

      const sortingDiff = clusterSpecsSort(
        group1SelectedInstance,
        group2SelectedInstance,
        sortBy.prop
      );

      return sortBy.dir === SORT_DIRECTIONS.ASCENDING
        ? sortingDiff
        : -sortingDiff;
    }
  );
}
