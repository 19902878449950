import { useLoginWithRedirect } from '@frontegg/react';
import { FEATURE_FLAGS } from '@siren-frontend/shared/constants/feature-flags';
import {
  Navigate,
  Routes as RouterRoutes,
  Route,
  useLocation,
} from 'react-router-dom';

import LoadingPage from 'components/common/LoadingPage';
import AppRoute from 'components/layouts/AppRoute';
import AppRoutes from 'components/pages';
import { useHasFeature } from 'components/providers/features/with-features';
import withStyles from 'components/withStylesAdapter';
import * as fonts from 'definitions/mui/fonts';
import { PERMISSIONS } from 'definitions/user-profile';
import { useAuthentication } from 'hooks/authentication';

const withJss = withStyles(theme => ({
  '@global': {
    'html, body, #root': {
      height: '100%',
    },
    body: {
      ...fonts.textLink,
      margin: '0',
      padding: '0',
    },
    html: {
      boxSizing: 'border-box',
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    'a, a:hover, a:visited': {
      textDecoration: 'inherit',
      outline: 'none',
    },

    // Scrollbar
    '::-webkit-scrollbar-button': {
      display: 'none',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.colors.sanMarino,
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: theme.colors.darkSanMarino,
      },
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
    },
  },
}));

// react router dom redirection does not work for frontegg routes
function WindowRedirect({ to }) {
  window.location.href = to;

  return null;
}

function Routes() {
  const location = useLocation();
  const isFEggHostedEnabled = useHasFeature(FEATURE_FLAGS.FRONTEGG_HOSTED);
  const loginWithRedirect = useLoginWithRedirect();
  const { isLoading, isAuthenticated } = useAuthentication();
  const isUsageFfOn = useHasFeature(FEATURE_FLAGS.USAGE);

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isAuthenticated) {
    if (isFEggHostedEnabled) {
      loginWithRedirect();
    }
    return (
      <Navigate
        to={{
          pathname: '/account/login',
          search: location.search,
          state: { loginRedirect: location?.pathname },
        }}
      />
    );
  }

  return (
    <RouterRoutes>
      <Route
        path="/"
        element={
          <AppRoute component={AppRoutes.ClustersList} title="All Clusters" />
        }
      />
      <Route
        path="/clusters/new/*"
        element={
          <AppRoute
            permission={PERMISSIONS.canCreateCluster}
            subheader={AppRoutes.NewClusterHeader}
            component={AppRoutes.NewCluster}
            provider={AppRoutes.NewClusterDataProvider}
            title="Create New Cluster"
          />
        }
      />
      <Route
        path="/clusters/:clusterId/vpc-peering/*"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageVPCPeering}
            subheader={AppRoutes.VPCPeeringHeader}
            component={AppRoutes.VPCPeering}
          />
        }
      />

      <Route
        path="/maintenance-windows"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageMaintenanceWindows}
            component={AppRoutes.Maintenance}
            title="Maintenance Windows"
          />
        }
      />
      <Route
        path="/clusters/list"
        element={
          <AppRoute
            subheader={AppRoutes.ClustersListHeader}
            component={AppRoutes.ClustersList}
            title="All Clusters"
          />
        }
      />
      <Route
        path="/clusters/maintenance"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageMaintenanceWindows}
            component={AppRoutes.Maintenance}
            subheader={AppRoutes.ClustersListHeader}
            title="Maintenance Windows"
          />
        }
      />
      <Route
        path="/notifications"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageNotificationEmail}
            component={AppRoutes.NotificationPreferences}
            subheader={AppRoutes.ClustersListHeader}
            title="Notification Preferences"
          />
        }
      />
      <Route
        path="/clusters/:clusterId/*"
        element={
          <AppRoute
            subheader={AppRoutes.ClusterDetailsHeader}
            component={AppRoutes.ClusterDetails}
          />
        }
      />
      <Route
        path="/billing"
        element={
          <AppRoute
            permission={PERMISSIONS.canUpdateBillingDetails}
            component={AppRoutes.Billing}
            title="Billing"
          />
        }
      />

      <Route
        path="/managed-resources"
        element={
          <AppRoute
            permission={[
              PERMISSIONS.canManageCloudAccount,
              PERMISSIONS.canViewBYOK,
            ]}
            subheader={AppRoutes.ManagedResourcesHeader}
            component={AppRoutes.ManagedResources}
            title="Managed Resources"
          />
        }
      />
      <Route
        path="/managed-resources/cloud-account-setup"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageCloudAccount}
            subheader={AppRoutes.CloudAccountSetupHeader}
            component={AppRoutes.CloudAccountSetup}
            title="Setup a Cloud Account"
          />
        }
      />
      <Route
        path={'/managed-resources/byoa'}
        element={
          <AppRoute
            permission={PERMISSIONS.canManageCloudAccount}
            subheader={AppRoutes.ManagedResourcesHeader}
            component={AppRoutes.Byoa}
            title="Bring Your Own Account"
          />
        }
      />
      <Route
        path="/managed-resources/byoa/edit"
        element={
          <AppRoute
            permission={PERMISSIONS.canManageCloudAccount}
            subheader={AppRoutes.ManagedResourcesHeader}
            component={AppRoutes.ByoaEdit}
            title="Bring Your Own Account"
          />
        }
      />
      <Route
        path="/managed-resources/byok"
        element={
          <AppRoute
            permission={PERMISSIONS.canViewBYOK}
            subheader={AppRoutes.ManagedResourcesHeader}
            component={AppRoutes.Byok}
            title="Customer Managed Keys"
          />
        }
      />

      {isUsageFfOn && (
        <Route
          path="/usage"
          element={
            <AppRoute
              subheader={AppRoutes.ClustersListHeader}
              component={AppRoutes.Usage}
              title="Usage"
            />
          }
        />
      )}
      <Route
        path={`/user/${process.env.signupHash}/signup`}
        element={<WindowRedirect to="/account/sign-up" />}
      />
      <Route
        path="/user/signup"
        element={
          <WindowRedirect to={`/account/sign-up${location?.search}`} /> // Query params are used for propagating marketing metadata
        }
      />
      <Route
        path="/user/signin"
        element={<WindowRedirect to="/account/login" />}
      />
      <Route
        path="/user/forgot-password"
        element={<WindowRedirect to="/account/forget-password" />}
      />
      <Route
        path="/user/reset-password/:status"
        element={<WindowRedirect to="/account/forget-password" />}
      />

      <Route path="*" element={<Navigate to="/" />} />
    </RouterRoutes>
  );
}

export default withJss(Routes);
