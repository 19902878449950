import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import 'reset-css/reset.css';
import { TssCacheProvider } from 'tss-react';

import { ErrorBoundary } from './components/common/error-boundary';
import InfoBanner from './components/common/info-banner/InfoBanner';
import VersionNotice from './components/common/version';
import FronteggAuthProvider from './components/providers/authentication/FronteggAuthProvider';
import FeaturesProvider from './components/providers/features/features-provider';
import OpenFeatureProvider from './components/providers/flags';
import ModalProvider from './components/providers/modals';
import NotificationsProvider from './components/providers/notifications';
import theme from './definitions/mui/theme';
import Routes from './routes';
import { loadRecurly } from './services/recurly';
import { initAnalyticsServices } from './utils/analytics';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
});

export default function Main() {
  initAnalyticsServices();
  loadRecurly();

  return (
    <CacheProvider value={muiCache}>
      <TssCacheProvider value={tssCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <NotificationsProvider>
            <ErrorBoundary>
              <FeaturesProvider>
                <FronteggAuthProvider>
                  <OpenFeatureProvider>
                    <ModalProvider>
                      <Routes />
                      <VersionNotice />
                      <InfoBanner />
                    </ModalProvider>
                  </OpenFeatureProvider>
                </FronteggAuthProvider>
              </FeaturesProvider>
            </ErrorBoundary>
          </NotificationsProvider>
        </ThemeProvider>
      </TssCacheProvider>
    </CacheProvider>
  );
}
