import { Grid } from '@mui/material';
import {
  CLOUD_PROVIDER_NAME_BY_ID,
  calcInternalInputs,
  selectClusterInstances,
  standardVcpuPerf,
} from '@siren-frontend/shared';
import { useCallback, useEffect, useRef, useState } from 'react';

import SpecSlider from 'components/pages/new-cluster/Calculator/SpecSlider';
import { useNewClusterData } from 'components/pages/new-cluster/hooks/context';
import { getNewClusterDefaults } from 'components/pages/new-cluster/hooks/deployment';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(() => ({
  calcSpecsContainer: {
    rowGap: '1.5rem',
    columnGap: '4rem',
  },
}));

const sliderSpecs = {
  reads: {
    name: 'Read ops/sec',
    min: 10000,
    max: 10000000,
    default: 5000000,
    marks: ['10k ops/sec', '10M ops/sec'],
    scale: value => 10 ** value,
  },
  writes: {
    name: 'Write ops/sec',
    min: 10000,
    max: 10000000,
    default: 100000,
    marks: ['10k ops/sec', '10M ops/sec'],
    scale: value => 10 ** value,
  },
  itemSize: {
    name: 'Average item size (KB)',
    min: 1,
    max: 20,
    default: 1,
    marks: ['1', '20'],
  },
  storage: {
    name: 'Storage set size (TB)',
    min: 1,
    max: 10000,
    default: 1,
    marks: ['1', '10000'],
  },
};

function populateInputDefaults(inputs) {
  return Object.fromEntries(
    Object.entries(inputs).map(([inputKey, input]) => [
      inputKey,
      { ...input, value: input.default },
    ])
  );
}

export const internalParamValues = Object.fromEntries(
  Object.entries(calcInternalInputs).map(([specId, specValues]) => [
    specId,
    specValues.value || specValues.default,
  ])
);

function CalculatorSpecChooser({ instances, onChange, classes }) {
  const [chosenSpecs, setChosenSpecs] = useState(
    populateInputDefaults(sliderSpecs)
  );
  const [initialized, setInitialized] = useState(false);
  const { replicationFactor } = getNewClusterDefaults();

  const {
    state: { cloudProviderId },
  } = useNewClusterData();

  const currentSuggestionProviderId = useRef();

  function handleCalculatorInputChange(id, value) {
    setChosenSpecs({
      ...chosenSpecs,
      [id]: { ...chosenSpecs[id], current: Number(value) },
    });

    propagateCalculatorSuggestion();
  }

  const propagateCalculatorSuggestion = useCallback(
    function propagateCalculatorSuggestion() {
      const workload = Object.fromEntries(
        Object.entries(chosenSpecs)?.map(([specKey, specValue]) => [
          specKey,
          specValue.current || specValue.default,
        ])
      );

      const calculatorSuggested = selectClusterInstances(
        workload,
        replicationFactor,
        standardVcpuPerf,
        instances,
        CLOUD_PROVIDER_NAME_BY_ID[cloudProviderId],
        internalParamValues
      );

      onChange &&
        onChange({
          instanceId: calculatorSuggested.instanceType.instanceId,
          nodes: calculatorSuggested.nodes,
        });
    },
    [chosenSpecs, instances, onChange, replicationFactor, cloudProviderId]
  );

  useEffect(
    function updateSelectedInstanceOnRender() {
      if (!initialized) {
        propagateCalculatorSuggestion();
        setInitialized(true);
      }
    },
    [initialized, propagateCalculatorSuggestion]
  );

  useEffect(
    function updateSelectedInstanceOnProviderChange() {
      if (
        cloudProviderId !== currentSuggestionProviderId.current &&
        instances.some(i => i.cloudProviderId === cloudProviderId)
      ) {
        currentSuggestionProviderId.current = cloudProviderId;
        propagateCalculatorSuggestion();
      }
    },
    [cloudProviderId, instances, propagateCalculatorSuggestion]
  );

  return (
    <Grid container className={classes.calcSpecsContainer}>
      {Object.entries(chosenSpecs).map(spec => (
        <SpecSlider
          onChange={handleCalculatorInputChange}
          spec={spec}
          key={spec[0]}
        />
      ))}
    </Grid>
  );
}

export default withJss(CalculatorSpecChooser);
