import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { COUNTRY_GROUPS } from '@siren-frontend/shared';
import { createContext, useCallback, useEffect, useState } from 'react';

import { getRecurlyId } from 'components/common/recurly/get-recurly-id';
import { useCountries } from 'hooks/account';

const PaymentContext = createContext({});

function PaymentProvider({ children }) {
  const { countries } = useCountries();

  const [recurlyKey, setRecurlyKey] = useState(
    getRecurlyId(COUNTRY_GROUPS.NONUS)
  );

  const [country, setCountry] = useState(null);

  const contextValue = { country, setCountry };

  const getCountryGroupFromCode = useCallback(
    countryCode => {
      if (!countryCode) {
        return;
      }

      return countries.find(ctr => ctr.code === countryCode)?.group;
    },
    [countries]
  );

  useEffect(
    function updateRecurlyKeyOnCountryChange() {
      if (!country) {
        return;
      }

      const { group: countryGroup } = getCountryGroupFromCode(country);
      const key = getRecurlyId(countryGroup);

      setRecurlyKey(key);
    },
    [countries, country, getCountryGroupFromCode]
  );

  return (
    <PaymentContext.Provider value={contextValue}>
      <RecurlyProvider publicKey={recurlyKey}>
        <Elements>{children}</Elements>
      </RecurlyProvider>
    </PaymentContext.Provider>
  );
}

export { PaymentContext, PaymentProvider };
