import WarningIcon from '@mui/icons-material/Warning';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';

import MainModal, {
  MainModalActionButton,
  MainModalHeader,
  MainModalContent,
  MainModalActions,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  paper: {
    padding: '28px 20px 10px',
  },
  title: {
    fontSize: theme.utils.pxToRem(20),
    color: theme.colors.black,
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  notice: {
    marginBottom: '2.5em',
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  icon: {
    width: theme.utils.pxToRem(22),
    height: theme.utils.pxToRem(22),
    cursor: 'pointer',
    fill: theme.colors.buttercup,
    marginRight: theme.spacing(1),
  },
}));

const JumpStart = ({
  hideModal,
  params = { onConfirm: () => {} },
  classes,
}) => {
  const theme = useTheme();
  const handleConfirm = () => {
    params.onConfirm();
    hideModal();
  };

  return (
    <MainModal
      classes={{
        paper: classes.paper,
      }}
      onClose={hideModal}
      fullWidth
      maxWidth="sm"
    >
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<WarningIcon fontSize="medium" />}
        helperIconColor={theme.colors.buttercup}
      >
        Please Pay Attention
      </MainModalHeader>

      <MainModalContent>
        <Typography component="div" className={classes.notice}>
          Cluster charges will start once you setup your cluster. You have 31
          days to set your payment method
        </Typography>
      </MainModalContent>

      <MainModalActions>
        <div className={classes.actionContainer}>
          <MainModalActionButton
            id="freeTierConfirmationCancelBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
          <MainModalActionButton
            id="freeTierConfirmationConfirmBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={handleConfirm}
          >
            I AGREE
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
};

JumpStart.propTypes = {
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    onConfirm: PropTypes.func,
  }),
};

export default withJss(JumpStart);
