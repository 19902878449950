import AddDataCenterSuccess from './AddDCSuccess';
import AddTgwConnectionModal from './AddTgwConnection';
import ApplyMaintenance from './ApplyMaintenance';
import ClusterCtnEncryptionModeModal from './ClusterCtnEncryption';
import ClusterLocked from './ClusterLocked';
import ExtractClusterMetrics from './ClusterMetrics';
import ExtractPromProxyConfigModal from './ClusterMetrics/extract';
import CrossCloudRequest from './CrossCloudRequest';
import DeleteCloudAccount from './DeleteCloudAccount';
import DeleteCluster from './DeleteCluster';
import DeleteSecurityKey from './DeleteSecurityKey';
import DisableClusterMetrics from './DisableClusterMetrics';
import DownloadCertificatesModal from './DownloadCertificates';
import JumpStart from './JumpStart';
import ClusterMaintenanceWindows from './MaintenanceWindows';
import RenameClusterModal from './RenameCluster';
import ResizeCluster from './ResizeCluster';
import RestartCloudAccountWizard from './RestartCloudAccount';
import SetPayment from './SetPayment';
import VPCSettingUpFailed from './VPCSettingUp';

export const MODAL_ANALYTICS_TAGS = {
  [DeleteCluster.name]: 'DELETE',
  [ResizeCluster.name]: 'RESIZE',
  [AddDataCenterSuccess.name]: 'ADD_DC_SUCCESS',
  [SetPayment.name]: 'SET_PAYMENT',
  [VPCSettingUpFailed.name]: 'VPC_SETTING_UP_FAILED',
  [ExtractClusterMetrics.name]: 'METRICS',
  [ExtractPromProxyConfigModal.name]: 'DOWNLOAD_METRICS_CONFIG',
  [CrossCloudRequest?.name]: 'CREDENTIALS_REQUEST',
  [DisableClusterMetrics.name]: 'DISABLE_METRICS',
  [JumpStart.name]: 'JUMP_START',
  [RestartCloudAccountWizard.name]: 'RESTART_CLOUD_ACCOUNT_WIZARD',
  [DeleteCloudAccount.name]: 'DELETE_CLOUD_ACCOUNT',
  [ClusterMaintenanceWindows.name]: 'MAINTENANCE',
  [ApplyMaintenance.name]: 'APPLY_MAINTENANCE_TO_ALL',
  [ClusterLocked.name]: 'CLUSTER_LOCKED',
  [AddTgwConnectionModal.name]: 'ADD_TGW_CONNECTION',
  [ClusterCtnEncryptionModeModal.name]: 'CTN_ENCRYPTION_MODE',
  [RenameClusterModal.name]: 'RENAME_CLUSTER',
  [DownloadCertificatesModal.name]: 'DOWNLOAD_CERTIFICATES',
};

export {
  AddDataCenterSuccess,
  ApplyMaintenance,
  ExtractClusterMetrics,
  ExtractPromProxyConfigModal,
  CrossCloudRequest,
  DeleteCloudAccount,
  DeleteCluster,
  DisableClusterMetrics,
  JumpStart,
  ClusterMaintenanceWindows,
  ResizeCluster,
  RestartCloudAccountWizard,
  SetPayment,
  VPCSettingUpFailed,
  ClusterLocked,
  AddTgwConnectionModal,
  DeleteSecurityKey,
  ClusterCtnEncryptionModeModal,
  RenameClusterModal,
  DownloadCertificatesModal,
};
