import { INVALID_DBAAS_TOKEN, REQUEST_FAILED } from '@siren-frontend/shared';

import { createDbaasResourceMiddleware } from 'services/create-resource-middlewares/dbaas-resource';
import {
  createRetryResourceMiddleware,
  createRetryError,
} from 'services/create-resource-middlewares/retry-resource';
import { makeCreateResourceWithMiddlewares } from 'utils/create-resource';

const defaultCheckError = err => {
  // retry on invalid dbaas token or request error
  if ([INVALID_DBAAS_TOKEN, REQUEST_FAILED].indexOf(err.message) !== -1) {
    throw createRetryError(err);
  }
  throw err;
};

export const createServerResource = makeCreateResourceWithMiddlewares(
  createDbaasResourceMiddleware,
  createRetryResourceMiddleware({ defaultCheckError })
);
