import { ERROR_CODES } from '@siren-frontend/shared/constants/errors';
import { debounce } from 'lodash/fp';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useSWRConfig } from 'swr';

import {
  SNACKBAR_VARIANTS,
  useSnackbar,
} from 'components/providers/notifications';
import { useDefaultAccountId } from 'hooks/account';
import { useMutationRequest, useQueryRequest } from 'hooks/fetch';
import { promptBrowserDownload } from 'services/file-download';
import {
  enableDns,
  updateClientEncryptionMode,
  getClusterCaCertificate,
  updateClusterName,
  getAccountCaCertificate,
} from 'services/resources/account/cluster';
import {
  createVPCPeering,
  deleteVPCPeering,
} from 'services/resources/account/vpc-peering';

import { clusterInfoKey, getVpcPeeringWithRequestsFetchKey } from './cluster';

const apiUrl = process.env.apiUrl;

const isOperationAlreadyInProgress = errorCode =>
  errorCode === ERROR_CODES.operationInProgressErrorCode;

export const CLUSTER_REQUESTS_STATUSES = {
  QUEUED: 'QUEUED',
  IN_PROGRESS: 'IN_PROGRESS',
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  NOT_AUTOMATED: 'NOT_AUTOMATED',
};

const ACTIVE_CLUSTER_REQUESTS_STATUSES = [
  CLUSTER_REQUESTS_STATUSES.QUEUED,
  CLUSTER_REQUESTS_STATUSES.IN_PROGRESS,
].join(',');

const COMPLETE_CLUSTER_REQUESTS_STATUSES = [
  CLUSTER_REQUESTS_STATUSES.COMPLETED,
  CLUSTER_REQUESTS_STATUSES.FAILED,
].join(',');

export const requestsKey = (clusterId, requestType, accountId, statuses) =>
  clusterId &&
  requestType &&
  `${apiUrl}/account/${accountId}/cluster/${clusterId}/request?type=${requestType}&status=${statuses}`;

export const activeRequestsKey = (clusterId, requestType, accountId) =>
  requestsKey(
    clusterId,
    requestType,
    accountId,
    ACTIVE_CLUSTER_REQUESTS_STATUSES
  );
/**
 * Poll cluster request progress until completed or failed
 * {
 * @param  {number} requestId - The cluster request id to poll
 * @param  {number} clusterId - The cluster id
 * @param  {string} requestType - Type of the request used for querying
 * @param  {number} refreshInterval - polling interval, default set by config
 * @param  {{ successMessage }} snackbarOptions - When specifed, snackbar for success and failure will be dispalyed
 * }
 * @param  {function} onSuccess - callback Function to be called on cluster request completion, called with request body
 * @param  {function} onFailure - callback Function to be called on cluster request failure, called with request body userFriendlyError
 * @param  {function} onComplete - callback Function to be called on cluser request complete (success or failure)
 * @return {{ isLoading, isCompleted }}
 * @property {boolean} isLoading - is the fetch request loading
 * @property {boolean} isCompleted - is the cluster request completed
 */
export function useClusterRequestPolling({
  requestId,
  clusterId,
  requestType,
  refreshInterval = process.env.clusterPollingInterval,
  snackbarOptions,
  onSuccess,
  onFailure,
  onComplete,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();

  const defaultAccountId = useDefaultAccountId();
  const [shouldPoll, setShouldPoll] = useState(true);

  const key =
    requestId &&
    `${apiUrl}/account/${defaultAccountId}/cluster/request/${requestId}`;

  const { data, isLoading } = useQueryRequest({
    key,
    withToken: true,
    ...(shouldPoll && { options: { refreshInterval } }),
  });

  const lastRequestState = useRef({
    status: data?.status,
  });

  useEffect(
    function startPolling() {
      if (requestId) {
        setShouldPoll(true);
      }
    },
    [requestId]
  );

  useEffect(
    function stopPolling() {
      if (
        !data ||
        isLoading ||
        ACTIVE_CLUSTER_REQUESTS_STATUSES.includes(data?.status) ||
        lastRequestState.current.status === data?.status
      ) {
        lastRequestState.current.status = data?.status;
        return;
      }

      lastRequestState.current.status = data?.status;
      setShouldPoll(false);

      if (
        snackbarOptions &&
        data?.status === CLUSTER_REQUESTS_STATUSES.COMPLETED
      ) {
        enqueueSnackbar(snackbarOptions?.successMessage, {
          variant: SNACKBAR_VARIANTS.SUCCESS,
          key: `${requestType}-success`,
        });

        onSuccess && onSuccess(data);
      } else if (
        snackbarOptions &&
        data?.status === CLUSTER_REQUESTS_STATUSES.FAILED
      ) {
        enqueueSnackbar(
          `${data?.requestType?.toLowerCase()} request failed \n${
            data?.userFriendlyError
          }`,
          {
            variant: SNACKBAR_VARIANTS.ERROR,
            key: `${requestType}-progress-error`,
            persist: true,
          }
        );

        onFailure && onFailure(data?.userFriendlyError);
      }

      onComplete && onComplete(data);
    },
    [
      data,
      isLoading,
      clusterId,
      defaultAccountId,
      enqueueSnackbar,
      mutate,
      setShouldPoll,
      requestType,
      snackbarOptions,
      onSuccess,
      onFailure,
      onComplete,
    ]
  );

  return {
    isLoading,
    isCompleted: COMPLETE_CLUSTER_REQUESTS_STATUSES.includes(data?.status),
    status: data?.status,
  };
}

export function useClusterRequestsByType(
  clusterId,
  requestType,
  pollingInterval = 0
) {
  const defaultAccountId = useDefaultAccountId();

  const key = activeRequestsKey(clusterId, requestType, defaultAccountId);

  return useQueryRequest({
    key,
    withToken: true,
    ...(pollingInterval > 0 && {
      options: { refreshInterval: pollingInterval },
    }),
  });
}

export function useResizeRequests(clusterId) {
  const defaultAccountId = useDefaultAccountId();

  const RESIZE_ACTIVE_REQUESTS = [
    CLUSTER_REQUESTS_STATUSES.QUEUED,
    CLUSTER_REQUESTS_STATUSES.IN_PROGRESS,
    CLUSTER_REQUESTS_STATUSES.NOT_AUTOMATED,
  ].join(',');

  const key = requestsKey(
    clusterId,
    'RESIZE_CLUSTER_V2',
    defaultAccountId,
    RESIZE_ACTIVE_REQUESTS
  );

  return useQueryRequest({
    key,
    withToken: true,
  });
}

/**
 * Handle mutation request (POST/DELETE/PUT) that results in a cluster request
 * The hook will first check for existing cluster requests of the type and will poll on them
 * When executing the request, the result cluster request or existing one will be polled until completed / errored
 * {
 * @param {function():Promise} requester - Async function that will execute the request when called
 * @param  {number} clusterId - The cluster id
 * @param  {string} requestType - Type of the request used for querying
 * @param {function} onClusterRequest - Callback function to be on called cluster request creation , called with response value
 * @param {function} onClusterRequestCompletion - Callback function to be on cluster request completion, called with cluster request data
 * @param {function} onClusterRequestFailure - Callback function to be cluster request creation failure, called with response error
 * @param {function} onClusterRequestCompletionFailure - Callback function to be cluster in progress request failure, called with user friendly error
 * @param {function} onComplete - Callback function to be called on cluster request completion (success or failure)
 * @param  {{ successMessage }} snackbarOptions - When specifed, snackbar for success and failure will be dispalyed
 *  }
 * @return {{ request, isLoading }}
 * @property {function():Promise} request - Async function that will execute the request when called
 * @property {boolean} isLoading - is the cluster request is loading / in progress

 */
export function useAsyncClusterRequest({
  requester,
  clusterId,
  requestType,
  onClusterRequest,
  onClusterRequestFailure,
  onClusterRequestCompletion,
  onClusterRequestCompletionFailure,
  onComplete,
  snackbarOptions,
  requestMatcher,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSWRConfig();
  const defaultAccountId = useDefaultAccountId();
  const [requestId, setRequestId] = useState();
  const { data, isLoading: isClusterRequestsLoading } =
    useClusterRequestsByType(clusterId, requestType); // fetches pending cluster requests by their type (type = action)

  // mutation request - the one which triggers a process in the backend - should return a request id
  const {
    request,
    isLoading: isMutationLoading,
    error,
  } = useMutationRequest({
    requester: (...args) => requester(...args),
    onSuccess: res => {
      setRequestId(res.id); // it is set when the request is being created - but not if it's revisited/refreshed
      onClusterRequest && onClusterRequest(res);
    },
    onFailure: err => {
      snackbarOptions &&
        enqueueSnackbar(`${err?.msg}\n trace-id: ${err?.traceId}`, {
          variant: SNACKBAR_VARIANTS.ERROR,
          key: `${requestType}-error-${err?.traceId}`,
          persist: true,
        });

      // There is an operation in progress, refetch requests will fetch the operation request and start pooling
      if (isOperationAlreadyInProgress(err?.code)) {
        mutate(activeRequestsKey(clusterId, requestType, defaultAccountId));
      }
      onClusterRequestFailure && onClusterRequestFailure(err);
    },
  });

  // the polling - it finds running request by its id - but the requestId depends on the requestMatcher
  const {
    isLoading: isRequestLoading,
    isCompleted,
    status,
  } = useClusterRequestPolling({
    requestId,
    clusterId,
    requestType,
    snackbarOptions,
    refreshInterval: process.env.requestPollingInterval,
    onSuccess: onClusterRequestCompletion,
    onFailure: onClusterRequestCompletionFailure,
    onComplete,
  });

  useEffect(
    function clearIdOnComplete() {
      if (isCompleted && requestId) {
        setRequestId(null);
      }
    },
    [isCompleted, requestId]
  );

  useEffect(
    function clearIdOnClusterChange() {
      setRequestId(null);
    },
    [clusterId]
  );

  useEffect(
    function setMatchingRequestId() {
      const activeRequestId = requestMatcher
        ? requestMatcher(data)?.id
        : data?.[0]?.id;

      if (activeRequestId) {
        setRequestId(activeRequestId);
      }
    },
    [data, requestMatcher]
  );

  const isRequestInProgress = requestId && !isCompleted;
  const isLoading =
    isClusterRequestsLoading ||
    isMutationLoading ||
    isRequestLoading ||
    isRequestInProgress;

  if (isLoading || !data) {
    return { isLoading, status, error };
  }

  return { request, isLoading, status, error };
}

const refetchClusterInfoAndList = (mutate, accountId, clusterId) => {
  mutate(clusterInfoKey(accountId, clusterId, true));
  mutate(`${apiUrl}/account/${accountId}/clusters?enriched=true`);
};

export function useEnableDns(clusterId) {
  const defaultAccountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const requester = useCallback(
    token =>
      enableDns.call({
        accountId: defaultAccountId,
        clusterId,
        token,
      }),
    [defaultAccountId, clusterId]
  );

  const refetchClusterInfo = useCallback(
    () => refetchClusterInfoAndList(mutate, defaultAccountId, clusterId),
    [defaultAccountId, clusterId, mutate]
  );

  return useAsyncClusterRequest({
    clusterId,
    requester,
    snackbarOptions: { successMessage: 'DNS enabled' },
    requestType: 'DNS',
    onComplete: refetchClusterInfo,
  });
}

export function useUpdateClientEncryptionMode(clusterId) {
  const defaultAccountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const refetchClusterInfo = useCallback(
    () => refetchClusterInfoAndList(mutate, defaultAccountId, clusterId),
    [defaultAccountId, clusterId, mutate]
  );

  return useAsyncClusterRequest({
    clusterId,
    requester: (mode, token) =>
      updateClientEncryptionMode.call({
        accountId: defaultAccountId,
        clusterId,
        mode,
        token,
      }),
    snackbarOptions: {
      successMessage: 'Client to node Encryption mode has been changed',
    },
    requestType: 'ENCRYPTION_MODE',
    onComplete: refetchClusterInfo,
  });
}

export function useRenameCluster(clusterId, onRequest) {
  const defaultAccountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const refetchClusterInfo = useCallback(
    () => refetchClusterInfoAndList(mutate, defaultAccountId, clusterId),
    [defaultAccountId, clusterId, mutate]
  );

  return useAsyncClusterRequest({
    clusterId,
    requester: (newName, token) =>
      updateClusterName.call({
        accountId: defaultAccountId,
        clusterId,
        async: false,
        name: newName,
        token,
      }),
    snackbarOptions: {
      successMessage: 'Cluster name has been changed',
    },
    requestType: 'RENAME_CLUSTER',
    onClusterRequest: () => {
      onRequest();
    },
    onComplete: () => {
      refetchClusterInfo();
    },
  });
}

export function useClusterCACertificate(clusterId) {
  const defaultAccountId = useDefaultAccountId();

  return useMutationRequest({
    requester: token =>
      getClusterCaCertificate.call({
        accountId: defaultAccountId,
        clusterId,
        async: false,
        token,
      }),
    onSuccess: res => {
      promptBrowserDownload(
        res.content,
        `scylladb_cluster_${clusterId}_key.${res.format.toLowerCase()}`
      );
    },
  });
}

export function useAccountCACertificate() {
  const defaultAccountId = useDefaultAccountId();

  return useMutationRequest({
    requester: token =>
      getAccountCaCertificate.call({
        accountId: defaultAccountId,
        async: false,
        token,
      }),
    onSuccess: res => {
      promptBrowserDownload(
        res.content,
        `scylladb_service_ca_key.${res.format.toLowerCase()}`
      );
    },
  });
}

export function useCreateVPCPeering(clusterId, onRequestDone) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();

  const onCreateSuccess = useCallback(
    res => {
      const peerId = JSON.parse(res.requestBody)?.PeerID;

      onRequestDone && onRequestDone(peerId);
    },
    [onRequestDone]
  );

  const onComplete = useCallback(() => {
    mutate(getVpcPeeringWithRequestsFetchKey(accountId, clusterId));
    mutate(clusterInfoKey(accountId, clusterId, true));
  }, [clusterId, accountId, mutate]);

  return useAsyncClusterRequest({
    clusterId,
    requester: (peerData, token) =>
      createVPCPeering.call({
        accountId,
        clusterId,
        async: true,
        ...peerData,
        token,
      }),
    snackbarOptions: { successMessage: 'VPC Peering has been created.' },
    requestType: 'CREATE_VPC_PEERING',
    onClusterRequestCompletion: onCreateSuccess,
    onComplete: onComplete,
  });
}

export function useCreateVPCPeeringSync(clusterId, onRequestDone) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const { enqueueSnackbar } = useSnackbar();

  const onCreateSuccess = useCallback(
    res => {
      onRequestDone?.(res.id);
    },
    [onRequestDone]
  );

  const onComplete = useCallback(() => {
    mutate(getVpcPeeringWithRequestsFetchKey(accountId, clusterId));
    mutate(clusterInfoKey(accountId, clusterId, true));
  }, [clusterId, accountId, mutate]);

  return useMutationRequest({
    requester: (peerData, token) =>
      createVPCPeering.call({
        accountId,
        clusterId,
        async: false,
        ...peerData,
        token,
      }),
    onSuccess: res => {
      onComplete();
      onCreateSuccess(res);
    },
    onFailure: err => {
      enqueueSnackbar(`${err?.msg}\n trace-id: ${err?.traceId}`, {
        variant: SNACKBAR_VARIANTS.ERROR,
        key: `CREATE_VPC_PEERING-error-${err?.traceId}`,
        persist: true,
      });

      // There is an operation in progress, refetch requests will fetch the operation request and start pooling
      if (isOperationAlreadyInProgress(err?.code)) {
        mutate(activeRequestsKey(clusterId, 'CREATE_VPC_PEERING', accountId));
      }
    },
  });
}

export function useDeletePeering(clusterId, peeringId, externalId, onComplete) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();

  const requestMatcher = useCallback(
    requests =>
      requests?.find(request => {
        const requestBody = JSON.parse(request?.requestBody || '{}');
        return requestBody?.ExternalID === externalId;
      }),
    [externalId]
  );

  const onPeeringComplete = useCallback(() => {
    // Fetch info for the list and cluster info for the general page
    mutate(getVpcPeeringWithRequestsFetchKey(accountId, clusterId));
    mutate(clusterInfoKey(accountId, clusterId, true));

    if (onComplete) {
      onComplete();
    }
  }, [accountId, clusterId, onComplete, mutate]);

  return useAsyncClusterRequest({
    clusterId,
    requester: token =>
      deleteVPCPeering.call({
        accountId,
        clusterId,
        peerId: peeringId,
        token,
      }),
    snackbarOptions: {
      successMessage: `VPC Peering has been deleted`,
    },
    requestType: 'DELETE_VPC_PEERING',
    onComplete: onPeeringComplete,
    requestMatcher,
  });
}

/**
 * Hook for fetching only the vpc create requests in progress
 * @param clusterId
 * @returns {{isLoading: (boolean|*), data: {vpcRequests: *, vpcPeerings: *}}}
 */
export function useCreateVpcPeeringRequests(clusterId) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const [poll, setPoll] = useState(true);

  const { data, isLoading } = useClusterRequestsByType(
    clusterId,
    'CREATE_VPC_PEERING',
    poll ? process.env.requestPollingInterval : 0
  );

  const refetch = useCallback(() => {
    mutate(getVpcPeeringWithRequestsFetchKey(accountId, clusterId));
    mutate(clusterInfoKey(accountId, clusterId, true));
  }, [accountId, clusterId, mutate]);

  const stopPollingOnEmptyData = useMemo(
    () =>
      // used debounce because of scenario where stale data is returned immediately after the request has been done (and not present in stale collection)
      debounce(process.env.requestPollingInterval / 2, currentData => {
        if (currentData?.length === 0) {
          setPoll(false);
          refetch();
        }
      }),
    [setPoll, refetch]
  );

  useEffect(
    function checkDataState() {
      stopPollingOnEmptyData(data);
    },
    [data, stopPollingOnEmptyData]
  );

  return { data, isLoading };
}

export function useRenameClusterRequest(clusterId) {
  const defaultAccountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const refetchClusterInfo = useCallback(
    () => refetchClusterInfoAndList(mutate, defaultAccountId, clusterId),
    [defaultAccountId, clusterId, mutate]
  );

  const { data, isLoading } = useClusterRequestsByType(
    clusterId,
    'RENAME_CLUSTER',
    process.env.requestPollingInterval
  );
  const status = data?.[0]?.status;

  const [requestCount, setRequestCount] = useState(0);
  useEffect(
    function refetchOnSuccess() {
      if (data?.length < requestCount) {
        refetchClusterInfo();
      }
      setRequestCount(data?.length || 0);
    },
    [status, refetchClusterInfo, requestCount, data]
  );

  return { status, isLoading };
}
