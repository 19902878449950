import { pxToRem } from 'definitions/utils';

/**
 * Theme sizes
 * https://www.figma.com/design/DZis4cW7Ko4yhBPit9fBzX/Scylla-UI-Variables
 * */

export const spacer = {
  _3xs: pxToRem(5),
  _xxs: pxToRem(8),
  _xs: pxToRem(12),
  _sm: pxToRem(20),
  _default: pxToRem(32),
  _md: pxToRem(52),
  _lg: pxToRem(83),
  _xl: pxToRem(134),
};
