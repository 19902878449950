import AlertWithTraceId from 'components/common/AlertWithTraceId';
import { SELF_SERVICE_PROCESSOR } from 'services/self-service-resize';

function ResizeClusterAlert({
  resizeResponse,
  resizeRequestError,
  resizeRequestsInProgressError,
  clusterError,
}) {
  const error =
    resizeRequestsInProgressError || clusterError || resizeRequestError;
  let successTitle = null;
  const requestBody = JSON.parse(resizeResponse?.requestBody ?? '{}');

  if (requestBody?.processor === SELF_SERVICE_PROCESSOR.AUTOMATION) {
    successTitle =
      'Resize is currently in progress. You can close this window.';
  }
  if (requestBody?.processor === SELF_SERVICE_PROCESSOR.SUPPORT) {
    successTitle =
      'Your resize request was sent to our Support team and will be handled as soon as possible. You can close this window.';
  }

  return (
    <>
      <AlertWithTraceId
        error={error}
        isSuccess={Boolean(successTitle)}
        successTitle={successTitle}
        id="resizeCluster"
      />
    </>
  );
}

export default ResizeClusterAlert;
