import { createTheme } from '@mui/material/styles';
import Color from 'color2';

import * as utils from 'definitions/utils';

import * as colors from './colors';
import * as custom from './custom';
import * as extensions from './extensions';
import * as fonts from './fonts';
import * as keyframes from './keyframes';
import * as tables from './tables';
import * as variables from './variables';

const { keyframesDict } = keyframes;

const muiTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, #root': {
          height: '100%',
        },
        body: {
          ...fonts.textLink,
          margin: '0',
          padding: '0',
          lineHeight: 1,
        },
        html: {
          boxSizing: 'border-box',
          WebkitFontSmoothing: 'auto',
        },
        ...keyframesDict,
        '*, *:before, *:after': {
          boxSizing: 'inherit',
        },
        'a, a:hover, a:visited': {
          textDecoration: 'inherit',
          outline: 'none',
        },
        // Scrollbar
        '::-webkit-scrollbar-button': {
          display: 'none',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: colors.sanMarino,
          borderRadius: '6px',
          '&:hover': {
            backgroundColor: colors.darkSanMarino,
          },
        },
        '::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '::-webkit-scrollbar': {
          width: '8px',
          height: '8px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: colors.charcoal,
          fontSize: variables.inputFontSize,
        },
        underline: {
          '&:after': {
            borderBottom: `2px solid ${colors.darkSanMarino}`,
          },
          '&:before': {
            borderBottom: `1px solid ${colors.sanMarino}`,
          },
          '&:hover:not($disabled):not($focused):not($error):before': {
            borderBottom: `2px solid ${colors.sanMarino}`,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: variables.inputLabelFontSize,
          color: colors.sanMarino,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&$focused': {
            color: colors.darkSanMarino,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'initial',
        },
        text: {
          color: colors.mako,
        },
        containedPrimary: {
          backgroundColor: colors.karimunBlue,
          '&:hover': {
            backgroundColor: colors.googleBlue,
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: utils.pxToRem(16),
          color: colors.mako,
          lineHeight: 2,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: utils.pxToRem(11),
          WebkitFontSmoothing: 'antialiased',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: utils.pxToRem(13),
        },
        head: {
          color: colors.comet,
          fontSize: utils.pxToRem(12),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          backgroundColor: colors.chablis,
          fontSize: utils.pxToRem(14),
          color: '#d92d20',
        },
        outlinedWarning: {
          backgroundColor: '#fffcf5',
          fontSize: utils.pxToRem(14),
          color: '#b54708',
        },
        root: {
          marginBottom: '12px',
        },
        message: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          color: 'unset',
          width: '100%',
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          color: 'unset',
          fontSize: utils.pxToRem(14),
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          marginTop: 0,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked, &.Mui-focused': {
            color: colors.darkSanMarino,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.pictonBlue,
      contrastText: colors.white,
      light: colors.sanMarino,
      dark: Color(colors.pictonBlue).darken(0.2).hexString(),
    },
    secondary: {
      main: colors.sanMarino,
      contrastText: colors.white,
      dark: Color(colors.sanMarino).darken(0.2).hexString(),
    },
    error: {
      main: colors.error,
    },
    text: {
      primary: colors.mako,
      secondary: colors.comet,
    },
    violet: {
      main: colors.violet,
      contrastText: colors.white,
      dark: Color(colors.violet).darken(0.2).hexString(),
    },
  },
  typography: {
    fontFamily: fonts.fontFamily,
    h1: {
      color: colors.mako,
    },
    h2: {
      color: colors.mako,
    },
    h3: {
      color: colors.mako,
    },
    h4: {
      color: colors.mako,
    },
    h5: {
      color: colors.rhino,
      fontSize: utils.pxToRem(20),
    },
    h6: {
      color: colors.mako,
      fontSize: utils.pxToRem(24),
    },
    subtitle1: {
      color: colors.sanMarino,
      fontSize: utils.pxToRem(17),
    },
    subtitle2: {
      color: colors.rhino,
      fontWeight: 500,
      fontSize: utils.pxToRem(16),
    },
    body1: {
      color: 'rgba(0, 0, 0, 0.6)',
      fontSize: utils.pxToRem(13),
    },
    body2: {
      color: colors.mako,
      fontSize: utils.pxToRem(10),
    },
    caption: {
      color: colors.baliHai,
      lineHeight: '20px',
    },
    button: {
      color: colors.mako,
    },
  },

  // Our custom theme configs
  colors,
  fonts,
  extensions,
  keyframes,
  tables,
  custom,
  utils,
  ...variables,
});

export default muiTheme;
