import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LaunchIcon from '@mui/icons-material/Launch';
import { Typography, useTheme } from '@mui/material';
import { CLOUD_PROVIDER_ID_BY_NAME } from '@siren-frontend/shared';

import Skeleton from 'components/common/Skeleton';
import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import { useRegions } from 'components/pages/new-cluster/hooks/deployment';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';

const withJss = withStyles(theme => ({
  keyDetailsHeader: {
    margin: `${theme.utils.pxToRem(8)} 0`,
    fontSize: theme.utils.pxToRem(18),
    fontWeight: '500',
    color: theme.colors.black,
    marginTop: theme.spacing(3),
  },
  textLarger: {
    fontSize: theme.utils.pxToRem(14),
  },
  valueDescription: {
    fontSize: theme.utils.pxToRem(16),
    fontWeight: '400',
    color: theme.colors.black,
  },
  launchIcon: {
    marginLeft: theme.spacing(1),
    fontSize: theme.utils.pxToRem(14),
  },
  buttonsContainer: theme.extensions.buttonsContainer(theme),
}));

function DeleteSecurityKeyModal({
  hideModal,
  classes,
  params: { securityKey, provider = 'Amazon Web Services' },
}) {
  const theme = useTheme();
  const { isLoading, regions } = useRegions(CLOUD_PROVIDER_ID_BY_NAME.AWS);
  const stackId = securityKey?.attrs?.stack?.name;
  const regionName = securityKey?.attrs?.masterRegion;

  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={<CheckCircleOutlineIcon fontSize="medium" />}
        helperIconColor={theme.colors.infoGreen}
        testid="deleteKeyModalHeader"
      >
        Your key was successfully deleted from ScyllaDB Cloud.
      </MainModalHeader>
      <MainModalContent>
        <Typography variant="caption" paragraph className={classes.textLarger}>
          To complete the process, you need to deprovision your key from AWS
          using the Delete Stack button below. You will need to log in to your
          AWS account and delete the CloudFormation Stack with ID{' '}
          <b data-testid="stackId">{stackId}</b>.
        </Typography>
        <Typography variant="caption" paragraph className={classes.textLarger}>
          More information on provisioning your key in AWS can be found in our{' '}
          <Typography
            color="primary"
            component="a"
            href={documentationLinks.cloudDocs.security.encryptionAtRest}
            target="_blank"
            className={classes.textLarger}
          >
            docs
          </Typography>
          .
        </Typography>
        <Typography className={classes.keyDetailsHeader}>
          Key Details
        </Typography>
        <Typography
          variant="caption"
          paragraph
          className={classes.textLarger}
          data-testid="deleteKeyProvider"
        >
          Provider: <span className={classes.valueDescription}>{provider}</span>
        </Typography>
        <Typography
          variant="caption"
          paragraph
          className={classes.textLarger}
          data-testid="deleteKeyRegion"
        >
          Region:{' '}
          <span className={classes.valueDescription}>
            <Skeleton isLoading={isLoading}>
              {regions?.find(r => r.name === regionName)?.fullName}
            </Skeleton>
          </span>
        </Typography>
      </MainModalContent>
      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            data-testid="launchStackButton"
            type={MainModalActionButton.Types.MAJOR}
            href={securityKey?.attrs?.nextAction?.url}
            target="_blank"
          >
            DELETE STACK
            <LaunchIcon className={classes.launchIcon} />
          </MainModalActionButton>
          <MainModalActionButton
            data-testid="cancelButton"
            type={MainModalActionButton.Types.MINOR}
            onClick={hideModal}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

export default withJss(DeleteSecurityKeyModal);
