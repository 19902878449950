import LanguageIcon from '@mui/icons-material/Language';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { format } from 'date-fns';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  timezone: {
    display: 'flex',
    alignItems: 'center',
  },
  globe: {
    marginRight: theme.utils.pxToRem(8),
  },
  text: {
    color: theme.colors.black,
  },
}));

function UsedTimezone({ classes, className }) {
  return (
    <div className={classNames(classes.timezone, className)}>
      <LanguageIcon className={classes.globe} />
      <Typography className={classes.text} id="maint-used-timezone">
        Your local timezone is used ({format(new Date(), 'O')})
      </Typography>
    </div>
  );
}

export default withJss(UsedTimezone);
