import { pxToRem } from 'definitions/utils';

import { spacer } from './spacer';

/**
 * Theme borders
 * https://www.figma.com/design/DZis4cW7Ko4yhBPit9fBzX/Scylla-UI-Variables
 * */

const borderWidth = '1px';

export const border = {
  width: borderWidth,
  radius_sm: pxToRem(3),
  radius: spacer._3xs,
  radius_md: spacer._xxs,
};
