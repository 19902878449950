import classnames from 'classnames';
import PropTypes from 'prop-types';

import withStyles from 'components/withStylesAdapter';
import * as utils from 'definitions/utils';

const borderWidth = utils.pxToRem(2.5);
const borderWidthSmall = utils.pxToRem(2.5);

const withJss = withStyles(theme => ({
  root: {
    animation: `${theme.keyframes.rotate} 0.6s infinite linear`,
    borderBottom: `${borderWidth} solid ${theme.custom.colors.gray100}`,
    borderLeft: `${borderWidth} solid ${theme.custom.colors.gray600}`,
    borderRight: `${borderWidth} solid ${theme.custom.colors.gray100}`,
    borderTop: `${borderWidth} solid ${theme.custom.colors.gray100}`,
    display: 'inline-block',
    fontSize: theme.utils.pxToRem(10),
    position: 'relative',
    textIndent: '-9999em',

    '&, &:after': {
      borderRadius: '50%',
      height: '1.15em',
      width: '1.15em',
    },

    '&.large': {
      width: theme.utils.pxToRem(26),
      height: theme.utils.pxToRem(26),
    },

    '&.small': {
      width: theme.utils.pxToRem(20),
      height: theme.utils.pxToRem(20),
    },

    '&.xs': {
      width: theme.utils.pxToRem(14),
      height: theme.utils.pxToRem(14),
    },

    '&.xxs': {
      width: theme.utils.pxToRem(9),
      height: theme.utils.pxToRem(9),
      borderBottom: `${borderWidthSmall} solid ${theme.custom.colors.gray100}`,
      borderLeft: `${borderWidthSmall} solid ${theme.custom.colors.gray600}`,
      borderRight: `${borderWidthSmall} solid ${theme.custom.colors.gray100}`,
      borderTop: `${borderWidthSmall} solid ${theme.custom.colors.gray100}`,
    },
  },
}));

function Spinner({ size = 'xs', className, classes }) {
  const cssClassName = classnames(
    classes.root,
    {
      large: size === 'large',
      small: size === 'small',
      xs: size === 'xs',
      xxs: size === 'xxs',
    },
    className
  );

  return (
    <span className={cssClassName} data-testid="spinner" data-loading="true" />
  );
}

Spinner.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    small: PropTypes.string,
    large: PropTypes.string,
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'small', 'xs', 'xxs']),
};

export default withJss(Spinner);
