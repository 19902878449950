import logger from 'utils/logger';
import { loadScript, loadStylesheet } from 'utils/resource-loader';
export const isRecurlyLoaded = () => !!window.recurly;

const MAX_RETRIES = process.env.maxFetchRetries;

export const loadRecurlyResources = (() => {
  let recurlyPromise;

  return retry => {
    if (isRecurlyLoaded()) {
      return Promise.resolve();
    }

    if (!recurlyPromise || retry) {
      recurlyPromise = Promise.all([
        new Promise((resolve, reject) => {
          function loadWithBackoff(attempt, delay) {
            loadScript(
              'https://js.recurly.com/v4/recurly.js',
              {},
              {
                load: () => resolve(),
                error: () => {
                  if (attempt < MAX_RETRIES) {
                    console.error(
                      `Recurly: Failed to load recurly.js, retrying...`
                    );
                    setTimeout(
                      () => loadWithBackoff(attempt + 1, delay * 2),
                      delay
                    );
                  } else {
                    reject();
                  }
                },
              }
            );
          }

          loadWithBackoff(0, 1000); // backoff with 1s delay
        }),
        new Promise(resolve => {
          loadStylesheet('https://js.recurly.com/v4/recurly.css');
          resolve();
        }),
      ]);
    }
    recurlyPromise.catch(err => {
      logger.error({
        prefix: 'recurly',
        message: 'Failed to load recurly.js',
        error: err,
      });
    });
    return recurlyPromise;
  };
})();

export function loadRecurly() {
  loadRecurlyResources(true);
}

// Returns a promise for fetching a billing token.
export const getRecurlyBillingToken = form =>
  new Promise((resolve, reject) => {
    // fetch recurly token
    window.recurly.token(form, (err, token) => {
      if (!err) {
        resolve(token);
      } else {
        reject(err);
      }
    });
  });

/**
 *  https://github.com/recurly/recurly-js/blob/9ec18f5aef07cff808fafd9d48c56a326f5efb6e/types/lib/error.d.ts
 *
 interface RecurlyError extends Error {
        code: string;
        message: string;
        classification: string;
        help?: string;
    }

 Token validation error also contains `fields` and `details` properties
 */
const isRecurlyTokenValidationError = err => {
  const hasProp = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
  const hasProps = (obj, props) =>
    Array.isArray(props) && props.every(prop => hasProp(obj, prop));

  return (
    typeof err === 'object' &&
    hasProps(err, ['code', 'message', 'fields']) &&
    err.code === 'validation'
  );
};

export const traceAndNormalizeRecurlyError = async err => {
  const dummy = {
    message: (err && err.message) || '',
    fields: (err && err.fields) || [],
    originalError: err,
  };

  if (isRecurlyTokenValidationError(err)) return err;

  return dummy;
};

export const addRecurlyEventListener = (eventType, handler) => {
  window.recurly.on(eventType, handler);
};

export const removeRecurlyEventListener = (eventType, handler) => {
  window.recurly.off(eventType, handler);
};

export const configureRecurly = config => {
  window.recurly.configure(config);
};
