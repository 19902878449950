import {
  CLOUD_PROVIDER_ID_BY_NAME,
  KEY_PROVIDERS,
} from '@siren-frontend/shared';
import {
  ALTERNATOR_ISOLATION_POLICY_NAMES,
  BROADCAST_TYPE,
  USER_API_INTERFACE,
  DEAL_TYPES,
} from '@siren-frontend/shared';
import { isEmpty, isNil, sortBy } from 'lodash/fp';
import {
  useContext,
  useEffect,
  useReducer,
  createContext,
  useCallback,
  useRef,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { AddDCDataContext } from 'components/pages/cluster-details/add-dc/context';
import { useCloudAccounts } from 'hooks/account';
import { useClusterMaintenanceWindowsInfo } from 'hooks/maintenanceWindows';
import { isAws } from 'services/cluster';
import { getActiveCloudAccounts, getDefaultCredentialId } from 'services/utils';
import { validateIPsArray, fixCidr } from 'services/validators';
import { usePrevious } from 'utils/hooks';

import {
  getNewClusterDefaults,
  getUrlDeal,
  useRegions,
  useRegionInstances,
  useScyllaVersions,
  useClientIp,
  useUrlDeal,
} from './deployment';
import { FORM_DEFAULT_CONFIG } from './form';
import { createFormDataReducer } from './reducer';

const getValidInstances = instances => {
  const deal = getUrlDeal();
  const isFreeTierDeal = deal === DEAL_TYPES.FREE_TRIAL;
  const validInstances = isFreeTierDeal
    ? instances.filter(i => i.freeTier)
    : instances;

  return validInstances;
};

export const getDefaultGroupInstance = instances => {
  const validInstances = getValidInstances(instances);

  return sortBy('id', validInstances)[0];
};

export const getDefaultInstance = (instances, cloudProviderId) => {
  const validInstances = getValidInstances(instances);

  const defaults = getNewClusterDefaults();

  const defaultInstanceBySorting = sortBy('id', validInstances)[0];

  const defaultInstance =
    cloudProviderId === CLOUD_PROVIDER_ID_BY_NAME.AWS
      ? validInstances.find(i => i.id === defaults.instanceId) ||
        defaultInstanceBySorting
      : defaultInstanceBySorting;

  return defaultInstance;
};

const NewClusterDataContext = createContext();

function NewClusterDataProvider({ children }) {
  const deal = getUrlDeal();
  const isFreeTierDeal = deal === DEAL_TYPES.FREE_TRIAL;
  const defaults = getNewClusterDefaults();
  const { state: locationState } = useLocation();
  const clusterHistoryState = locationState?.newClusterState;
  const [state, dispatch] = useReducer(createFormDataReducer(defaults), {
    ...defaults,
    ...clusterHistoryState,
    freeTier: isFreeTierDeal,
  });

  // Run all side effects for setting defaults - cloud provider / free tier change etc
  useNewClusterDefaults(state, dispatch, !clusterHistoryState);

  // monitor route changes in the new cluster context
  useNewClusterRouteChecking();

  const form = useForm(FORM_DEFAULT_CONFIG);

  const value = { state, dispatch };

  if (clusterHistoryState) {
    delete window.history.state.usr.newClusterState;
  }
  return (
    <NewClusterDataContext.Provider value={value}>
      <FormProvider {...form}>{children}</FormProvider>
    </NewClusterDataContext.Provider>
  );
}

function refreshIfRevisitingNewCluster(oldPath, newPath) {
  if (
    oldPath !== newPath &&
    oldPath?.match(/new\/[\d]+\/launch/g) &&
    newPath.includes('clusters/new')
  ) {
    // performing page navigation with reload when revisiting directly from /launch page
    window.location.href = newPath;
  }
}

function useNewClusterRouteChecking() {
  const location = useLocation();
  const previousPath = usePrevious(location.pathname);

  useEffect(() => {
    refreshIfRevisitingNewCluster(previousPath, location.pathname);
  }, [previousPath, location]);
}

function useNewClusterDefaults(state, dispatch, shouldRunEffects) {
  const { cloudProviderId, broadcastType, userApiInterface, scyllaVersion } =
    state || {};
  const { regions, defaultRegionId } = useRegions(cloudProviderId, true);
  const { instances } = useRegionInstances(cloudProviderId, regions?.[0]?.id);
  const { scyllaVersions } = useScyllaVersions();
  const { data: maintenanceWindowsInfo } = useClusterMaintenanceWindowsInfo();
  const { clientIp } = useClientIp();

  const deal = useUrlDeal();
  const isFreeTier = deal === DEAL_TYPES.FREE_TRIAL;

  const runConditionRef = useRef(shouldRunEffects);
  runConditionRef.current = shouldRunEffects;

  function setDefaultScyllaVersion() {
    if (scyllaVersions && isNil(scyllaVersion)) {
      const defaultVersion = scyllaVersions.find(
        v => v.newCluster === 'ENABLED'
      );

      dispatch({
        type: 'update',
        payload: {
          scyllaVersion: defaultVersion,
        },
      });
    }
  }

  useEffect(
    function setDefaultMaintenanceWindows() {
      if (runConditionRef.current === true && maintenanceWindowsInfo) {
        dispatch({
          type: 'update',
          payload: {
            maintenanceWindows: maintenanceWindowsInfo?.default?.map(mw => ({
              rrule: mw.rrule,
              duration: mw.duration,
            })),
          },
        });
      }
    },
    [maintenanceWindowsInfo, dispatch]
  );

  useEffect(
    function setEncryptionAtRest() {
      if (runConditionRef.current === true) {
        let encryptionAtRest = {};

        if (isAws(cloudProviderId)) {
          encryptionAtRest = {
            provider: KEY_PROVIDERS.SCYLLA_KEY,
          };
        }

        dispatch({
          type: 'update',
          payload: {
            encryptionAtRest,
          },
        });
      }
    },
    [dispatch, cloudProviderId]
  );

  useEffect(
    function setFreeTier() {
      if (runConditionRef.current === true) {
        dispatch({
          type: 'update',
          payload: {
            freeTier: isFreeTier,
          },
        });
      }
    },
    [isFreeTier, dispatch]
  );

  useEffect(
    function addClientIpEntry() {
      if (runConditionRef.current === true) {
        if (clientIp) {
          dispatch({
            type: 'update',
            payload: {
              allowedIPs: [fixCidr(clientIp)],
            },
          });
        }
      }
    },
    [clientIp, dispatch]
  );

  useEffect(setDefaultScyllaVersion, [scyllaVersions, scyllaVersion, dispatch]);

  // Cloud provider was changed - update the defaults
  useEffect(
    function updateCloudProviderDefaults() {
      if (runConditionRef.current === true) {
        let newState = {};

        if (regions?.length && instances?.length) {
          newState.regionId =
            state.regionId && !isNaN(state.regionId)
              ? state.regionId
              : defaultRegionId || regions[0].id;
          newState.instanceId = getDefaultInstance(
            instances,
            cloudProviderId
          )?.id;
        }

        if (scyllaVersions) {
          const defaultVersion = scyllaVersions.find(
            v => v.newCluster === 'ENABLED'
          );

          if (scyllaVersion?.version !== defaultVersion.version) {
            newState.scyllaVersion = defaultVersion;
          }
        }

        if (!isEmpty(newState)) {
          dispatch({
            type: 'update',
            payload: newState,
          });
        }
      }
    },
    [
      cloudProviderId,
      instances,
      regions,
      scyllaVersions,
      dispatch,
      defaultRegionId,
      scyllaVersion,
      state.regionId,
    ]
  );

  useEffect(
    function removeCidrOnBroadcastType() {
      if (runConditionRef.current === true) {
        if (broadcastType === BROADCAST_TYPE.PUBLIC) {
          dispatch({
            type: 'update',
            payload: {
              cidrBlock: null,
            },
          });
        }
      }
    },
    [broadcastType, dispatch]
  );

  // cloud accounts /cloudProviderId change - update default accountCredentialId
  const { data: cloudAccounts } = useCloudAccounts();
  useEffect(
    function updateCloudCredentialDefaults() {
      if (runConditionRef.current === true) {
        if (cloudAccounts && cloudProviderId) {
          const accountCredentialId = getDefaultCredentialId(
            getActiveCloudAccounts(cloudAccounts),
            cloudProviderId
          );

          dispatch({
            type: 'update',
            payload: { accountCredentialId },
          });
        }
      }
    },
    [cloudAccounts, cloudProviderId, dispatch]
  );

  useEffect(
    function updateCloudAccountDefaults() {
      if (runConditionRef.current === true) {
        if (!instances?.length) {
          return;
        }

        const newState = {
          instanceId: getDefaultInstance(instances, cloudProviderId)?.id,
        };

        dispatch({
          type: 'update',
          payload: newState,
        });
      }
    },
    [dispatch, instances, cloudProviderId]
  );

  // alternator selected - set default isolation policy
  useEffect(
    function updateIsolationPolicy() {
      if (runConditionRef.current === true) {
        if (userApiInterface === USER_API_INTERFACE.ALTERNATOR) {
          dispatch({
            type: 'update',
            payload: {
              alternatorWriteIsolation: ALTERNATOR_ISOLATION_POLICY_NAMES.ONLY,
            },
          });
        } else {
          dispatch({
            type: 'update',
            payload: {
              alternatorWriteIsolation: null,
            },
          });
        }
      }
    },
    [userApiInterface, dispatch]
  );
}

function useNewClusterData() {
  const NewClusterContext = useContext(NewClusterDataContext);
  const AddDCContext = useContext(AddDCDataContext);

  const context = NewClusterContext || AddDCContext;
  if (context === undefined) {
    throw new Error(
      'useNewClusterData must be used within a NewClusterDataProvider'
    );
  }

  const { dispatch } = context;

  const updateClusterData = useCallback(
    function updateClusterData(state) {
      dispatch({
        type: 'update',
        payload: state,
      });
    },
    [dispatch]
  );

  const resetClusterData = useCallback(
    function resetClusterData(state) {
      dispatch({
        type: 'reset',
        payload: state,
      });
    },
    [dispatch]
  );

  const validateClusterData = useCallback(() => {
    let error;
    error = validateIPsArray(context.state.allowedIPs);
    if (error) {
      return error;
    }
  }, [context.state.allowedIPs]);

  return {
    state: context.state,
    updateClusterData,
    validateClusterData,
    resetClusterData,
  };
}

export { NewClusterDataProvider, useNewClusterData };

export default NewClusterDataProvider;
