import WarningIcon from '@mui/icons-material/Warning';
import { Typography, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import MainModal, {
  MainModalHeader,
  MainModalContent,
  MainModalActions,
  MainModalActionButton,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { useDeletePeering } from 'hooks/clusterRequest';

const withJss = withStyles(() => ({}));

const textByFailedStatus = {
  FAILED:
    'AWS failed to peer to your VPC due to invalid configuration, please ensure your VPC details are correct. Please delete the peering request and create a new one.',
  MISMATCHED:
    'VPC network address mismatch, please ensure your VPC details are correct. Please delete the peering request and create a new one making sure that the VPC network address matches your VPC CIDR.',
  REJECTED:
    'Your peering request was rejected, please delete the peering request and create a new one.',
};

function VPCSettingUpModal({
  hideModal,
  params: { status, clusterId, peerId, externalId },
}) {
  const theme = useTheme();
  const navigate = useNavigate();

  const onComplete = useCallback(() => {
    hideModal();
    navigate(`/clusters/${clusterId}/connections`);
  }, [navigate, hideModal, clusterId]);

  const { request: deletePeering, isLoading: isDeleteLoading } =
    useDeletePeering(clusterId, peerId, externalId, onComplete);

  function onClose() {
    hideModal();

    navigate(`/clusters/${clusterId}`);
  }

  const isFailed = ['FAILED', 'MISMATCHED', 'REJECTED'].includes(status);

  return (
    <MainModal onClose={onClose} fullWidth maxWidth="sm">
      <MainModalHeader
        onClose={onClose}
        HelperIcon={isFailed ? <WarningIcon fontSize="medium" /> : null}
        helperIconColor={theme.colors.error}
      >
        {isFailed ? 'SOMETHING WENT WRONG' : 'PEERING REQUEST IS STILL PENDING'}
      </MainModalHeader>

      <MainModalContent>
        <Typography variant="caption" paragraph>
          {isFailed ? (
            textByFailedStatus[status]
          ) : (
            <>
              We are still waiting for you to accept the peering request in your
              AWS account. Please locate and accept the request according to the
              procedure.
              <br />
              <b>
                Note that following acceptance it may take a few minutes for the
                peering request to activate.
              </b>
            </>
          )}
        </Typography>
      </MainModalContent>

      <MainModalActions>
        {!isFailed && (
          <MainModalActionButton
            id="vpcPeeringDoLaterBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={onClose}
          >
            I`LL DO IT LATER
          </MainModalActionButton>
        )}
        {isFailed ? (
          <MainModalActionButton
            type={MainModalActionButton.Types.MAJOR}
            onClick={deletePeering}
            isLoading={isDeleteLoading}
          >
            DELETE VPC PEERING
          </MainModalActionButton>
        ) : (
          <MainModalActionButton
            id="vpcPeeringTryAgainBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={hideModal}
          >
            TRY AGAIN
          </MainModalActionButton>
        )}
      </MainModalActions>
    </MainModal>
  );
}

VPCSettingUpModal.propTypes = {
  params: PropTypes.shape({
    status: PropTypes.string.isRequired,
    clusterId: PropTypes.number.isRequired,
    peerId: PropTypes.number.isRequired,
    externalId: PropTypes.string.isRequired,
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
};

export default withJss(VPCSettingUpModal);
