import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import Skeleton from 'components/common/Skeleton';
import MainModal, {
  MainModalActionButton,
  MainModalActions,
  MainModalContent,
  MainModalHeader,
} from 'components/common/main-modal';
import PaymentForm from 'components/common/payments/PaymentForm';
import {
  SNACKBAR_VARIANTS,
  useSnackbar,
} from 'components/providers/notifications';
import { PaymentProvider } from 'components/providers/payment';
import withStyles from 'components/withStylesAdapter';
import { useCountries, useSetAccountCountry } from 'hooks/account';
import { useAccountBillingInfo, useSetBillingInfoRequest } from 'hooks/billing';
import { usePaymentForm } from 'hooks/payment/payment';
import { SUCCESS } from 'services/translations/notifications';
import { GoogleAnalytics } from 'utils/analytics';

const withJss = withStyles(theme => ({
  buttonsContainer: theme.extensions.buttonsContainer(theme),
}));

function PaymentModalComponent({ classes, hideModal }) {
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: billingInfoData,
    isLoading: isBillingInfoLoading,
    error: billingInfoError,
  } = useAccountBillingInfo();
  const { countries, isLoading: isCountriesLoading } = useCountries();

  const { request: setAccountCountry, error: setAccountCountryError } =
    useSetAccountCountry();

  const {
    request: setBillingInfo,
    isLoading: isSetBillingInfoLoading,
    error: setBillingInfoError,
  } = useSetBillingInfoRequest();

  const paymentFormHook = usePaymentForm();
  const {
    state: {
      isValid,
      isLoading: isFormLoading,
      isRecurlyLoading,
      error: paymentFormError,
    },
    getFields,
    setFields,
    setIsLoading: setIsFormLoading,
    submitPaymentData,
  } = paymentFormHook;

  const onClose = (ev, reason) => {
    if (reason !== 'backdropClick') {
      hideModal();
    }
  };

  const fireSubmit = async () => {
    let token;

    try {
      token = await submitPaymentData(paymentFormRef);
    } catch {
      return;
    }
    try {
      const fieldsToSubmit = getFields();

      await setBillingInfo({
        billingToken: token?.id,
        ...fieldsToSubmit,
      });
    } catch {
      // prevent success notifications and modal close on failure
      return;
    }

    GoogleAnalytics.event({
      category: 'Conversion',
      action: 'Summit info',
      label: 'Cloud payment',
    });

    enqueueSnackbar(SUCCESS.ACCOUNT.SET_PAYMENT_METHOD, {
      variant: SNACKBAR_VARIANTS.SUCCESS,
      key: `update-billing-info-success`,
    });
    hideModal({ setPaymentSuccess: true });
  };
  const paymentFormRef = useRef();

  const dataRelatedError = billingInfoError || setAccountCountryError;
  const submitRelatedErrors =
    setBillingInfoError ||
    (paymentFormError && { msg: paymentFormError.message });
  const dataLoading = isBillingInfoLoading || isCountriesLoading;
  const actionsLoading = isSetBillingInfoLoading || isRecurlyLoading;

  useEffect(
    function prepopulateForm() {
      if (isFormLoading && !isBillingInfoLoading) {
        setFields(billingInfoData);
        setIsFormLoading(false);
      }
    },
    [
      billingInfoData,
      isBillingInfoLoading,
      isFormLoading,
      setFields,
      setIsFormLoading,
    ]
  );

  return (
    <MainModal
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      id="paymentModal"
      disableEscapeKeyDown
    >
      <MainModalHeader onClose={onClose}>Edit Payment Method</MainModalHeader>

      <MainModalContent>
        <AlertWithTraceId error={dataRelatedError} id="setPayment" />
        <Skeleton isLoading={dataLoading}>
          {!isFormLoading && !isCountriesLoading && (
            <PaymentForm
              countries={countries}
              paymentFormHook={paymentFormHook}
              formRef={paymentFormRef}
              externalError={submitRelatedErrors}
              onChangeCountry={({ countryId }) => {
                setAccountCountry(countryId);
              }}
            />
          )}
        </Skeleton>
      </MainModalContent>

      <MainModalActions>
        <div className={classes.buttonsContainer}>
          <MainModalActionButton
            id="setPaymentBtn"
            type={MainModalActionButton.Types.MAJOR}
            onClick={async () => {
              await fireSubmit();
            }}
            isLoading={actionsLoading}
            disabled={
              actionsLoading || dataLoading || !isValid || paymentFormError
            }
          >
            SUBMIT REQUEST
          </MainModalActionButton>
          <MainModalActionButton
            id="setPaymentCancelBtn"
            type={MainModalActionButton.Types.MINOR}
            onClick={onClose}
            disabled={actionsLoading}
          >
            CANCEL
          </MainModalActionButton>
        </div>
      </MainModalActions>
    </MainModal>
  );
}

function PaymentModalWithProvider({ hideModal, classes }) {
  return (
    <PaymentProvider>
      <PaymentModalComponent hideModal={hideModal} classes={classes} />
    </PaymentProvider>
  );
}
PaymentModalComponent.propTypes = {
  hideModal: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.string),
};

export default withJss(PaymentModalWithProvider);
