import { Link } from 'react-router-dom';

import Button from 'components/common/Button';
import withStyles from 'components/withStylesAdapter';

const withJss = withStyles(theme => ({
  banner: {
    width: 'max-content',
    height: `50px`,
    display: 'grid',
    alignItems: 'center',
    borderRadius: '4px',
    color: theme.colors.baliHai,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  addButton: {
    boxShadow: 'none',
    backgroundColor: theme.colors.karimunBlue,
    '&:hover': {
      border: 'unset',
      boxShadow: 'none',
    },
  },
}));

function SetUpConnectionBanner({ clusterId, classes }) {
  return (
    <div className={classes.banner}>
      <Button
        className={classes.addButton}
        component={Link}
        to={`/clusters/${clusterId}/connections`}
        variant="contained"
        size="medium"
        color="primary"
      >
        CONFIGURE CONNECTION
      </Button>
    </div>
  );
}

export default withJss(SetUpConnectionBanner);
