import classnames from 'classnames';
import PropTypes from 'prop-types';

import Button from 'components/common/Button';
import withStyles from 'components/withStylesAdapter';

const actionButtonTypes = {
  MAJOR: 'major',
  SECONDARY: 'secondary',
  MINOR: 'minor',
};

const withJss = withStyles(
  theme => ({
    buttonMajor: {
      ...theme.fonts.modalButton,
    },
    buttonMinor: {
      ...theme.fonts.modalButton,
    },
    buttonSecondary: {
      backgroundColor: theme.colors.darkOrchid,
    },
  }),
  { inject: ['classes'] }
);

function MainModalActionButton({
  type = actionButtonTypes.MAJOR,
  children,
  isLoading = false,
  disabled = false,
  className = '',
  color,
  classes,
  ...restProps
}) {
  const getButtonProps = () => ({
    size: 'large',
    ...{
      [actionButtonTypes.MAJOR]: {
        variant: 'contained',
        color: color || 'primary',
        className: classnames(classes.buttonMajor, className),
      },
      [actionButtonTypes.SECONDARY]: {
        variant: 'contained',
        color: 'secondary',
        className: classnames(classes.buttonSecondary, className),
      },
      [actionButtonTypes.MINOR]: {
        variant: 'contained',
        color: 'inherit',
        className: classnames(classes.buttonMinor, className),
      },
    }[type],
  });

  return (
    <Button
      {...restProps}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      {...getButtonProps()}
    >
      {children}
    </Button>
  );
}

MainModalActionButton.propTypes = {
  type: PropTypes.oneOf(Object.values(actionButtonTypes)),
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  classes: PropTypes.objectOf(PropTypes.string),
};

MainModalActionButton.Types = actionButtonTypes;

const MainModalActionButtonComponent = withJss(MainModalActionButton);
MainModalActionButtonComponent.Types = MainModalActionButton.Types;

export default MainModalActionButtonComponent;
