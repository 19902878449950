import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import CidrInput from 'components/common/CidrInput';
import { MainModalContent } from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { documentationLinks } from 'definitions/documentation-links';
import { connectionValidators } from 'services/validators';

const withJss = withStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  link: {
    color: theme.colors.pictonBlue,
  },
  infoText: {
    fontSize: theme.utils.pxToRem(14),
    lineHeight: theme.utils.pxToRem(20),
    color: theme.colors.dustyGrayLight,
  },
}));

function AddTgwContent({ classes, form, dcs }) {
  const theme = useTheme();
  const { muiRegister } = form;

  return (
    <MainModalContent newDesign>
      <div className={classes.form}>
        <span className={classes.infoText}>
          AWS Transit Gateway connects your VPCs through a central hub.&nbsp;
          <a
            href={
              documentationLinks.cloudDocs.clusterConnections
                .awsTgwVpcAttachment
            }
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
          >
            Read our docs&nbsp;
          </a>
          to learn more.
        </span>
        <TextField
          id="add-tgw-form-name"
          data-testid="add-tgw-form-name"
          label="Name"
          variant="standard"
          {...muiRegister({
            name: 'name',
            defaultValue: '',
            useErrorText: true,
            ...connectionValidators.connectionName,
          })}
        />
        <FormControl
          data-testid="add-tgw-form-dc"
          variant="standard"
          error={!!form.formState?.errors?.dc}
        >
          <InputLabel id="add-tgw-form-dc-label">Data Center</InputLabel>
          <Select
            labelId="add-tgw-form-dc-label"
            id="add-tgw-form-dc"
            {...muiRegister({
              name: 'dc',
              defaultValue: dcs?.length === 1 ? dcs[0].id : '',
              useErrorText: true,
              ...connectionValidators.dc,
            })}
          >
            {dcs?.map(dc => (
              <MenuItem key={dc.id} value={dc.id}>
                {dc.name}
              </MenuItem>
            ))}
          </Select>
          {!!form.formState?.errors?.dc && (
            <FormHelperText>
              {connectionValidators.dc.required.message}
            </FormHelperText>
          )}
        </FormControl>
        <TextField
          id="add-tgw-form-arn"
          data-testid="add-tgw-form-arn"
          label="AWS RAM ARN"
          variant="standard"
          helperText="Skip this field if the transit gateway is in the same AWS account as your cluster."
          {...muiRegister({
            name: 'arn',
            defaultValue: '',
            useErrorText: true,
            ...connectionValidators.tgwRamArn,
          })}
        />
        <TextField
          id="add-tgw-form-id"
          data-testid="add-tgw-form-id"
          label="AWS Transit Gateway ID"
          variant="standard"
          {...muiRegister({
            name: 'tgwId',
            defaultValue: '',
            useErrorText: true,
            ...connectionValidators.tgwId,
          })}
        />
        <FormControl variant="standard">
          <CidrInput form={form} />
          <FormHelperText>
            The network block of your VPC in CIDR format (so we can route
            correctly to your VPC).
            <br />
            Maximum of 5 can be provided.
          </FormHelperText>
        </FormControl>
        <Box display="flex" flexDirection="row" gap="0.5rem">
          <ErrorOutlineIcon
            fontSize="small"
            style={{ color: theme.colors.dustyGrayLight }}
          />
          <span className={classes.infoText}>
            The attachment and usage of a transit gateway is subject to
            additional charges according to&nbsp;
            <a
              href="https://aws.amazon.com/transit-gateway/pricing/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              AWS pricing
            </a>
            .
          </span>
        </Box>
      </div>
    </MainModalContent>
  );
}

export default withJss(AddTgwContent);
