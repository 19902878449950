import { CLOUD_PROVIDER_NAME_BY_ID } from '@siren-frontend/shared';
import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { activeRequestsKey } from 'hooks/clusterRequest';
import {
  useMultipleQueryRequest,
  useMutationRequest,
  useQueryRequest,
} from 'hooks/fetch';
import { modifyIpAddress } from 'services/cluster';
import {
  addClusterAllowedIp,
  createTgwConnection,
  deleteCluster,
  deleteClusterAllowedIp,
  deleteTgwConnection,
  disableClusterMetrics,
  extractClusterMetrics,
  resizeCluster,
} from 'services/resources/account/cluster';
import { GoogleAnalytics } from 'utils/analytics';

import { useDefaultAccountId } from './account';

const apiUrl = process.env.apiUrl;
const refreshInterval = process.env.clusterPollingInterval;

export function clusterInfoKey(
  accountId,
  clusterId,
  enriched = false,
  includeDeleted = false
) {
  const queryString = new URLSearchParams();
  if (enriched) {
    queryString.append('enriched', 'true');
  }
  if (includeDeleted) {
    queryString.append('includeDeleted', 'true');
  }

  return (
    accountId &&
    clusterId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}${
      queryString.size ? `?${queryString.toString()}` : ''
    }`
  );
}
export function useClusterRequestInfo(requestId, enriched = false) {
  const defaultAccountId = useDefaultAccountId();
  const key =
    requestId &&
    defaultAccountId &&
    `${apiUrl}/account/${defaultAccountId}/cluster/request/${requestId}${enriched ? '?enriched=true' : ''}`;
  return useQueryRequest({
    key,
    withToken: true,
    options: {
      refreshInterval,
    },
  });
}

// TODO: "enriched" aggregating some information we now fetch like cloud provider and instance information
export function useClusterInfo(clusterId, enriched = true) {
  const defaultAccountId = useDefaultAccountId();

  const key = clusterInfoKey(defaultAccountId, clusterId, enriched);

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
  });

  return { cluster: data?.cluster, ...rest };
}

export function useClusterConnectInfo(clusterId) {
  const apiUrl = process.env.apiUrl;

  const defaultAccountId = useDefaultAccountId();
  const key =
    clusterId &&
    defaultAccountId &&
    `${apiUrl}/account/${defaultAccountId}/cluster/connect?clusterId=${clusterId}`;
  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useCurrentClusterInPath() {
  const { clusterId } = useParams();

  return useClusterInfo(clusterId);
}

export function useClusterNodesHealth(clusterId) {
  const defaultAccountId = useDefaultAccountId();

  const key =
    defaultAccountId &&
    clusterId &&
    `${apiUrl}/account/${defaultAccountId}/cluster/${clusterId}/nodes/health`;
  return useQueryRequest({
    key,
    withToken: true,
    options: {
      refreshInterval,
    },
  });
}

const clusterAllowedIpsKey = (accountId, clusterId) =>
  accountId &&
  clusterId &&
  `${apiUrl}/account/${accountId}/cluster/${clusterId}/network/firewall/allowed`;
export function useClusterAllowedIps(clusterId, options) {
  const defaultAccountId = useDefaultAccountId();

  const key = clusterAllowedIpsKey(defaultAccountId, clusterId);

  return useQueryRequest({
    key,
    options,
    withToken: true,
  });
}

function useRefetchIpsInfo(clusterId) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();

  return useCallback(() => {
    mutate(clusterInfoKey(accountId, clusterId, true));
    mutate(clusterAllowedIpsKey(accountId, clusterId));
  }, [accountId, clusterId, mutate]);
}

export function useAddClusterIp(clusterId) {
  const accountId = useDefaultAccountId();
  const refetchIpsInfo = useRefetchIpsInfo(clusterId);

  const requester = useCallback(
    (ipAddress, token) =>
      addClusterAllowedIp.call({
        accountId,
        clusterId,
        ipAddress: modifyIpAddress(ipAddress),
        token,
      }),
    [accountId, clusterId]
  );

  return useMutationRequest({
    requester,
    onSuccess: refetchIpsInfo,
  });
}

export function useDeleteClusterIp(clusterId) {
  const accountId = useDefaultAccountId();
  const refetchIpsInfo = useRefetchIpsInfo(clusterId);

  const requester = useCallback(
    (ruleId, token) => {
      return deleteClusterAllowedIp.call({
        accountId,
        clusterId,
        ruleId,
        token,
      });
    },
    [accountId, clusterId]
  );

  return useMutationRequest({
    requester,
    onSuccess: refetchIpsInfo,
  });
}

export function useClusterMetrics(clusterId, metricsArr) {
  const accountId = useDefaultAccountId();

  const key =
    accountId &&
    clusterId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}/metrics${
      metricsArr && Array.isArray(metricsArr)
        ? `?metrics=${metricsArr.join(',')}`
        : ''
    }`;

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
    options: {
      refreshInterval,
    },
  });
  return { metrics: data?.metrics, ...rest };
}

// When promproxy is not active this request will return an error. This will trigger a notification by default
export function useClusterPromProxyTokens(clusterId) {
  const accountId = useDefaultAccountId();

  const key =
    accountId &&
    clusterId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}/promproxy/tokens`;

  return useQueryRequest({ key, withToken: true });
}

export const getVpcPeeringFetchKey = (accountId, clusterId) =>
  accountId &&
  clusterId &&
  `${apiUrl}/account/${accountId}/cluster/${clusterId}/network/vpc/peer`;

export const getVpcPeeringWithRequestsFetchKey = (accountId, clusterId) =>
  accountId &&
  clusterId && [
    getVpcPeeringFetchKey(accountId, clusterId),
    activeRequestsKey(clusterId, 'CREATE_VPC_PEERING', accountId),
  ];

export function useVpcPeeringListWithRequests(clusterId) {
  const accountId = useDefaultAccountId();

  const collectionKey = getVpcPeeringWithRequestsFetchKey(accountId, clusterId);
  const pollingInterval = process.env.requestPollingInterval;

  const {
    data: [vpcPeerings, vpcRequests] = [],
    isLoading,
    error,
  } = useMultipleQueryRequest({
    keys: collectionKey,
    withToken: true,
    options: { refreshInterval: pollingInterval },
  });

  return {
    data: {
      vpcPeerings: vpcPeerings || [],
      vpcRequests: vpcRequests || [],
    },
    isLoading,
    error,
  };
}

export function useClusterVpcPeerInfo(clusterId, peerId) {
  const accountId = useDefaultAccountId();

  const key =
    accountId &&
    clusterId &&
    peerId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}/network/vpc/peer/${peerId}`;

  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useDisableClusterMetrics(clusterId) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const requester = useCallback(
    token => disableClusterMetrics.call({ accountId, clusterId, token }),
    [accountId, clusterId]
  );

  const refetchCluster = useCallback(
    () => mutate(clusterInfoKey(accountId, clusterId, true)),
    [accountId, clusterId, mutate]
  );
  return useMutationRequest({ requester, onSuccess: refetchCluster });
}

export function useExtractClusterMetrics(clusterId, onSuccess, onFailure) {
  const accountId = useDefaultAccountId();
  const { mutate } = useSWRConfig();
  const requester = useCallback(
    token => extractClusterMetrics.call({ accountId, clusterId, token }),
    [accountId, clusterId]
  );

  const refetchCluster = useCallback(
    data => {
      mutate(clusterInfoKey(accountId, clusterId, true));
      onSuccess && onSuccess(data);
    },
    [accountId, clusterId, mutate, onSuccess]
  );
  return useMutationRequest({
    requester,
    onSuccess: refetchCluster,
    onFailure,
  });
}

export function useResizeCluster(clusterId, onSuccess) {
  const accountId = useDefaultAccountId();
  const requester = useCallback(
    (dcNodes, token) =>
      resizeCluster.call({ accountId, clusterId, dcNodes, token }),
    [accountId, clusterId]
  );

  return useMutationRequest({
    requester,
    onSuccess,
  });
}

function useGoToClusterList() {
  const navigate = useNavigate();
  return useCallback(() => navigate('/clusters/list'), [navigate]);
}

export function useDeleteCluster(clusterId) {
  const accountId = useDefaultAccountId();
  const { cluster: { clusterName, cloudProviderId } = {} } =
    useClusterInfo(clusterId);

  const requester = useCallback(
    token => deleteCluster.call({ accountId, clusterId, clusterName, token }),
    [accountId, clusterId, clusterName]
  );
  const goToClusterList = useGoToClusterList();
  const onSuccess = useCallback(() => {
    GoogleAnalytics.event({
      category: 'Clusters',
      action: 'Deleted a cluster',
      label: CLOUD_PROVIDER_NAME_BY_ID[cloudProviderId],
    });
    goToClusterList();
  }, [cloudProviderId, goToClusterList]);

  return useMutationRequest({
    requester,
    onSuccess,
    onFailure: goToClusterList,
  });
}

// Includes queued and bootstrapping clusters, consider using active
export function useAllClusterList({
  enriched = false,
  enablePolling = true,
  includeDeleted = false,
}) {
  const accountId = useDefaultAccountId();

  const queryString = new URLSearchParams();
  if (enriched) {
    queryString.append('enriched', 'true');
  }
  if (includeDeleted) {
    queryString.append('includeDeleted', 'true');
  }

  const key =
    accountId &&
    `${apiUrl}/account/${accountId}/clusters${
      queryString.size ? `?${queryString.toString()}` : ''
    }`;

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
    options: {
      refreshInterval: enablePolling ? refreshInterval : 0,
    },
  });

  return { clusters: data?.clusters, ...rest };
}

export function useClusterConnections(clusterId) {
  const accountId = useDefaultAccountId();

  const key =
    accountId &&
    clusterId &&
    `${apiUrl}/account/${accountId}/cluster/${clusterId}/network/vpc/connection`;

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
    options: {
      refreshInterval,
    },
  });

  return { connections: data?.connections || [], ...rest };
}

export function useCreateTgwConnection(clusterId, onSuccess) {
  const accountId = useDefaultAccountId();

  const requester = useCallback(
    (tgwData, token) =>
      createTgwConnection.call({
        accountId,
        clusterId: clusterId,
        clusterDCID: tgwData.dc,
        cidrList: tgwData.cidrBlocks,
        name: tgwData.name,
        type: 'AWS_TGW_ATTACHMENT',
        data: {
          tgwID: tgwData.tgwId,
          ramARN: tgwData.arn,
        },
        token,
      }),
    [accountId, clusterId]
  );

  return useMutationRequest({
    requester,
    onSuccess,
  });
}

export function useDeleteTgwConnection(clusterId, onSuccess) {
  const accountId = useDefaultAccountId();

  const requester = useCallback(
    (connectionId, token) =>
      deleteTgwConnection.call({
        accountId,
        clusterId,
        connectionId,
        token,
      }),
    [accountId, clusterId]
  );

  return useMutationRequest({
    requester,
    onSuccess,
  });
}

export function useActiveClusterList() {
  const allClustersResponse = useAllClusterList({
    enriched: true,
    enablePolling: false,
  });

  return useMemo(
    () => ({
      ...allClustersResponse,
      clusters: allClustersResponse?.clusters?.filter(
        cluster => cluster?.status === 'ACTIVE'
      ),
    }),
    [allClustersResponse]
  );
}
