import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {
  SnackbarProvider,
  useSnackbar as useNotistackSnackbar,
} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';

import withStyles from 'components/withStylesAdapter';

export const SNACKBAR_VARIANTS = {
  INFO: 'info',
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export function useSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  function enqueue(message, options) {
    return enqueueSnackbar(message, {
      ...options,
      variant: options?.variant ?? SNACKBAR_VARIANTS.INFO,
      action: key => (
        <>
          {options?.action}
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon
              fontSize="small"
              id={`closeSnack-${key}`}
              data-testid={`closeSnack-${key}`}
            />
          </IconButton>
        </>
      ),
    });
  }

  return {
    closeSnackbar,
    enqueueSnackbar: enqueue,
  };
}

const withJss = withStyles(theme => ({
  containerRoot: {
    '& .SnackbarItem-message': {
      fontSize: theme.utils.pxToRem(14),
      fontWeight: 500,
    },
  },
}));

const NotificationsProvider = ({ children, classes }) => {
  const notistackRef = React.createRef();

  return (
    <SnackbarProvider
      ref={notistackRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        containerRoot: classes.containerRoot,
      }}
      preventDuplicate
    >
      {children}
    </SnackbarProvider>
  );
};

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({}),
};

export default withJss(NotificationsProvider);
