import { Box, Popover } from '@mui/material';
import { useState } from 'react';

import { maybeTrimClusterName } from 'services/cluster';

export default function ClusterName({ name = '' }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [trimmed, isTrimmed] = maybeTrimClusterName(name);

  const handleClose = () => isTrimmed && setAnchorEl(null);
  const handleOpen = ({ currentTarget }) =>
    isTrimmed && setAnchorEl(currentTarget);

  return (
    <>
      <span
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        data-testid="clusterName"
      >
        {trimmed}
      </span>
      {isTrimmed && (
        <Popover
          data-testid="clusterNamePopover"
          open={!!anchorEl}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{ pointerEvents: 'none' }}
        >
          <Box sx={{ p: 1 }}>{name}</Box>
        </Popover>
      )}
    </>
  );
}
