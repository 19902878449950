import { USER_API_INTERFACE, BROADCAST_TYPE } from '@siren-frontend/shared';
import { get } from 'lodash/fp';

export function formatNumber(x) {
  if (Number.isNaN(x)) {
    return x;
  }

  if (x < 1000) {
    return x.toFixed(2);
  }

  if (x < 10000) {
    return `${(x / 1000).toFixed(2)}K`;
  }

  if (x < 1000000) {
    return `${Math.round(x / 1000)}K`;
  }

  if (x < 10000000) {
    return `${(x / 1000000).toFixed(2)}M`;
  }

  if (x < 1000000000) {
    return `${Math.round(x / 1000000)}M`;
  }

  if (x < 1000000000000) {
    return `${Math.round(x / 1000000000)}B`;
  }

  return 'N/A';
}

export function isExternalURL(url) {
  return /^https?:\/\//.test(url);
}

export function isScyllaVersionAlternator({ userApiInterface }) {
  return [USER_API_INTERFACE.ALTERNATOR].indexOf(userApiInterface) !== -1;
}

export function isClusterPrivate({ broadcastType }) {
  return broadcastType === BROADCAST_TYPE.PRIVATE;
}

export function isEnabledVpcPeering({ broadcastType }) {
  return isClusterPrivate({ broadcastType });
}

export const getActiveCloudAccounts = cloudAccounts => {
  return cloudAccounts?.filter(account => account.state === 'ACTIVE');
};

export const getAccountOwner = (accountId, cloudAccounts) =>
  get(
    'owner',
    cloudAccounts?.find(cloudAccount => cloudAccount.id === accountId)
  );

export const getDefaultCredentialId = (cloudAccounts, cloudProviderId = 1) => {
  const [{ id: defaultAccountCredentialId } = {}] =
    cloudAccounts
      ?.filter(account => account.cloudProviderId === cloudProviderId)
      ?.sort((a, b) => b.id - a.id) || {};

  return defaultAccountCredentialId;
};

export function scrollToTop() {
  return window.scroll({
    top: 0,
    behavior: 'smooth',
  });
}

export function scrollAppLayoutToTop() {
  const appLayout = document.getElementById('app-layout-wrapper');
  appLayout &&
    appLayout.scroll({
      top: 0,
      behavior: 'smooth',
    });
}
