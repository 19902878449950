import { alpha } from '@mui/material';

import * as colors from 'definitions/mui/colors';
import * as custom from 'definitions/mui/custom';
import * as utils from 'definitions/utils';

const containedButtonStyles = (color, hoverColor) => ({
  backgroundColor: color,
  color: custom.colors.white,
  '&:hover': {
    backgroundColor: hoverColor,
    color: custom.colors.white,
  },
  '&.Mui-disabled': {
    backgroundColor: custom.colors.disabledBg,
    color: custom.colors.textDisabled,
  },
});

const outlinedButtonStyles = color => ({
  borderColor: alpha(color, 0.5),
  color: color,
  '&:hover': {
    borderColor: alpha(color, 0.5),
    backgroundColor: alpha(color, 0.04),
    color: color,
  },
  '&.Mui-disabled': {
    borderColor: custom.colors.disabledBg,
    color: custom.colors.textDisabled,
  },
});

const textButtonStyles = color => ({
  color: color,
  '&:hover': {
    color: color,
    backgroundColor: alpha(color, 0.04),
  },
  '&.Mui-disabled': {
    color: custom.colors.textDisabled,
  },
});

const MuiButtonOverrides = {
  styleOverrides: {
    root: {
      textTransform: 'initial',
    },
    text: {
      color: custom.colors.textColor,
    },
    sizeXs: {
      padding: custom.spacer._xxs,
      fontSize: utils.pxToRem(11),
      lineHeight: utils.pxToRem(16.5),
      minWidth: utils.pxToRem(30),
      height: utils.pxToRem(17),
    },
    containedPrimary: containedButtonStyles(
      custom.colors.primary,
      custom.colors.primaryDark
    ),
    outlinedPrimary: outlinedButtonStyles(custom.colors.primary),
    textPrimary: textButtonStyles(custom.colors.primary),

    containedSecondary: containedButtonStyles(
      custom.colors.secondary,
      custom.colors.secondaryDark
    ),
    outlinedSecondary: outlinedButtonStyles(custom.colors.secondary),
    textSecondary: textButtonStyles(custom.colors.secondary),

    containedError: containedButtonStyles(
      custom.colors.error,
      custom.colors.errorDark
    ),
    outlinedError: outlinedButtonStyles(custom.colors.error),
    textError: textButtonStyles(custom.colors.error),

    containedWarning: containedButtonStyles(
      custom.colors.warning,
      custom.colors.warningDark
    ),
    outlinedWarning: outlinedButtonStyles(custom.colors.warning),
    textWarning: textButtonStyles(custom.colors.warning),

    containedInfo: containedButtonStyles(
      custom.colors.info,
      custom.colors.infoDark
    ),
    outlinedInfo: outlinedButtonStyles(custom.colors.info),
    textInfo: textButtonStyles(custom.colors.info),

    containedSuccess: containedButtonStyles(
      custom.colors.success,
      custom.colors.successDark
    ),
    outlinedSuccess: outlinedButtonStyles(custom.colors.success),
    textSuccess: textButtonStyles(custom.colors.success),

    containedInheritWhite: containedButtonStyles(
      custom.colors.gray300,
      custom.colors.gray100
    ),
    outlinedInheritWhite: outlinedButtonStyles(custom.colors.white),
    textInheritWhite: textButtonStyles(custom.colors.white),

    containedInherit: {
      // Temporary fix for the inherit button color - till new design will be provided
      backgroundColor: colors.chineseWhite,
      color: custom.colors.textColor,
      '&:hover': {
        backgroundColor: `${colors.chineseWhite}af`,
        color: custom.colors.textColor,
        boxShadow:
          '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
      },
    },
    outlinedInherit: outlinedButtonStyles(custom.colors.textColor),
    textInherit: textButtonStyles(custom.colors.textColor),
  },
};

export default MuiButtonOverrides;
