import { useAuthUser } from '@frontegg/react';
import { OFREPWebProvider } from '@openfeature/ofrep-web-provider';
import {
  InMemoryProvider,
  OpenFeature,
  OpenFeatureProvider,
} from '@openfeature/react-sdk';
import { useContext, useEffect, useRef } from 'react';

import { FeaturesContext } from 'components/providers/features/features-provider';
import { useHasFeature } from 'components/providers/features/with-features';

import { stringified } from './constants';
import { config, stringFlagsToConfig } from './in-memory-config';

export default function Provider({ children }) {
  const { accessToken = '', email = '' } = useAuthUser();
  const tokenRef = useRef(accessToken);
  const domain = email.split('@').at(-1);

  const fliptEnabled = useHasFeature('FLIPT');
  const { featureFlags } = useContext(FeaturesContext);

  useEffect(
    function setOFContext() {
      OpenFeature.setContext({ flags: stringified, email_domain: domain });
    },
    [domain]
  );

  useEffect(
    function setOFProvider() {
      /**
       * @todo in order to speed up the development and merge the FE solution,
       * we can put existing flags into OpenFeature using InMemoryProvider:
       * https://openfeature.dev/docs/reference/technologies/client/web/react
       *
       * Once the backend is deployed, we can switch to OFREPWebProvider by
       * enabling 'FLIPT' feature flag in our backend.
       */
      OpenFeature.setProvider(
        fliptEnabled
          ? new OFREPWebProvider({
              baseUrl: '/flags',
              pollInterval: 6e4,
              headersFactory: () => [
                ['authorization', `bearer ${tokenRef.current}`],
              ],
            })
          : new InMemoryProvider({
              ...config,
              ...stringFlagsToConfig(featureFlags),
            })
      );
    },
    [fliptEnabled, featureFlags]
  );

  useEffect(
    function setTokenRef() {
      tokenRef.current = accessToken;
    },
    [accessToken]
  );

  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
}
