import { range, sortBy } from 'lodash/fp';

import {
  CLOUD_PROVIDER_ID_BY_NAME,
  FAMILY_GROUP_NAMES,
} from '../constants/cluster';

export const HOURS_PER_MONTH = 730;
export const MAX_NUMBER_OF_NODES = 1556;
export const TB_TO_GB_MULTIPLIER = 1000;

export const PERF_MULTIPLIER_BY_FAMILY_GROUP = {
  [FAMILY_GROUP_NAMES.i4i]: 2,
};

function excludeDevelopmentInstances(instance) {
  return instance.environment !== 'DEVELOPMENT';
}

// https://github.com/scylladb/siren-frontend/issues/3984
function excludeI3Instances(instance) {
  return instance.instanceFamily !== 'i3';
}

export function mapInstanceProperties(instance) {
  return {
    name: instance.externalId,
    instanceFamily: instance.instanceFamily,
    vcpu: instance.cpuCount,
    memory: instance.memory / 1024,
    storage: instance.totalStorage,
    cloudProviderId: instance.cloudProviderId,
    instanceId: instance.id,
    computePrice: {
      ondemand: HOURS_PER_MONTH * instance.instanceCostHourly,
    },
    licensePrice: {
      ondemand: HOURS_PER_MONTH * instance.subscriptionCostHourly,
    },
  };
}

// creates initial data structure with fetched 'on-demand' prices
export function prepareInstanceInitData(instances) {
  return instances
    .filter(excludeDevelopmentInstances)
    .filter(excludeI3Instances)
    .map(mapInstanceProperties);
}

export const standardVcpuPerf = {
  reads: 15000,
  writes: 15000,
};

function licensePrice(cluster) {
  return cluster.nodes * cluster.instanceType.licensePrice.ondemand;
}

function ondemandPrice(cluster) {
  return cluster.nodes * cluster.instanceType.computePrice.ondemand;
}

function clusterResources(cluster) {
  return {
    storage: cluster.instanceType?.storage * cluster.nodes,
    vcpu: cluster.instanceType?.vcpu * cluster.nodes,
    memory: cluster.instanceType?.memory * cluster.nodes,
  };
}

/*
The following is experimental result, function is based on model regressions done on a series of benchmark results. itemSize in kb.
*/
function itemSizePerfFactor(itemSize, internalParams) {
  const a = internalParams.performanceFactorA;
  const b = internalParams.performanceFactorB;
  return a / (itemSize + b);
}

function getInstancePerfMultiplier(instance) {
  return PERF_MULTIPLIER_BY_FAMILY_GROUP[instance?.instanceFamily] || 1;
}

/* Cluster size recommendations based on the optimization target:
- performance (CPU) - select nodes with enough storage and max cpu (note that the performance for i4i instances is doubled)
- storage - select nodes with enough cpu and max storage
- cost - select nodes with just enough cpu and storage, even if smaller nodes
*/
function selectClusterConfigs(specs, instances) {
  return instances
    .map(instanceType => {
      const nodes =
        range(1, MAX_NUMBER_OF_NODES + 1).find(
          n =>
            instanceType.vcpu * n * getInstancePerfMultiplier(instanceType) >=
              specs.vcpu &&
            instanceType.memory * n >= specs.memory &&
            instanceType.storage * n >= specs.storage
        ) || 0;
      return { instanceType, nodes };
    })
    .filter(({ nodes }) => nodes > 0);
}

export function selectClusterInstances(
  workload,
  replicationFactor,
  perf,
  instances,
  cloudProvider,
  internalParams
) {
  const diskSpace = workload.storage * internalParams.compactionOverhead;
  const recommendedResources = {
    vcpu:
      (workload.reads /
        perf.reads /
        internalParams.performanceDegradationReads +
        workload.writes /
          perf.writes /
          internalParams.performanceDegradationWrites) /
      itemSizePerfFactor(workload.itemSize, internalParams),
    storage: diskSpace,
    memory: Math.ceil(workload.storage / internalParams.ramToDataRatio),
  };

  const minimalResources = {
    ...recommendedResources,
    memory: Math.ceil(diskSpace / internalParams.ramToDiskRatio),
  };

  const selectedProviderId = CLOUD_PROVIDER_ID_BY_NAME[cloudProvider];
  const instancesForTheProvider = instances.filter(
    i => i.cloudProviderId === selectedProviderId
  );

  const recommendedConfigs = selectClusterConfigs(
    recommendedResources,
    instancesForTheProvider
  );
  const minimalConfigs = selectClusterConfigs(
    minimalResources,
    instancesForTheProvider
  );

  const lowestPrice = Math.min(...minimalConfigs.map(ondemandPrice));

  const bestConfig = configs =>
    sortBy(
      'nodes',
      configs.filter(
        spec =>
          ondemandPrice(spec) <
          lowestPrice * internalParams.bestConfigSelectionThreshold
      )
    )[0];

  const selectedConfig =
    bestConfig(recommendedConfigs) || bestConfig(minimalConfigs);

  if (!selectedConfig) {
    return;
  }

  return {
    ...selectedConfig,
    nodes: selectedConfig?.nodes * replicationFactor,
  };
}

export function calculateClusterCapacity(
  cluster,
  replicationFactor,
  internalParams
) {
  const perf = standardVcpuPerf;
  const totalResources = clusterResources(cluster);
  const dataset =
    totalResources.storage /
    replicationFactor /
    internalParams.compactionOverhead;
  const peakLoad =
    (totalResources.vcpu *
      (perf.writes + perf.reads) *
      getInstancePerfMultiplier(cluster.instanceType)) /
    2 /
    replicationFactor;
  const sustainedLoad =
    (totalResources.vcpu *
      (perf.writes * internalParams.performanceDegradationReads +
        perf.reads * internalParams.performanceDegradationWrites) *
      getInstancePerfMultiplier(cluster.instanceType)) /
    2 /
    replicationFactor;

  return { sustainedLoad, peakLoad, dataset, ...totalResources };
}

export function computeSuggestedCluster(
  workload,
  replicationFactor,
  instances,
  cloud = 'aws',
  internalParams
) {
  const workloadAdjusted = {
    ...workload,
    storage: workload?.storage * TB_TO_GB_MULTIPLIER,
  };

  const perf = standardVcpuPerf;
  // currently, Scylla requires each replica to be in a different AZ
  const replicationTraffic =
    ((HOURS_PER_MONTH *
      3600 *
      ((workloadAdjusted.reads + workloadAdjusted.writes) *
        workloadAdjusted.itemSize *
        (replicationFactor - 1))) /
      1e6) *
    internalParams.throughputFactor;
  const dataTransfer = replicationTraffic * internalParams.awsDataTransferPrice;

  const cluster = selectClusterInstances(
    workloadAdjusted,
    replicationFactor,
    perf,
    instances,
    cloud,
    internalParams
  );

  if (!cluster) {
    return { cluster: {}, prices: [] };
  }

  const prices = [
    {
      id: 'ondemand',
      name: 'On demand',
      compute: ondemandPrice(cluster),
      license: licensePrice(cluster, false),
    },
  ].map(priceSpec => {
    const { compute, license } = priceSpec;
    return {
      ...priceSpec,
      dataTransfer,
      total: compute + license, // In the new pricing, data transfer fees included in subscription price
    };
  });

  return { prices, cluster };
}
