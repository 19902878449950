import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useState } from 'react';

import LinearProgress from 'components/common/LinearProgress';
import MainModal, {
  MainModalActionButton,
  MainModalActions,
  MainModalContent,
  MainModalHeader,
} from 'components/common/main-modal';
import withStyles from 'components/withStylesAdapter';
import { APPLY_TO_ALL, APPLY_TO_NOT_SET } from 'services/maintenance-windows';
import { GoogleAnalytics } from 'utils/analytics';

const withJss = withStyles(theme => ({
  linkButton: {
    textTransform: 'uppercase',
    '&.MuiButton-text': {
      color: theme.colors.celticBlue,
    },
  },
  contentText: {
    fontSize: '1rem',
  },
  label: {
    color: theme.colors.charcoal,
    fontSize: theme.utils.pxToRem(14),
  },
}));

function ApplyMaintenance({
  hideModal,
  classes,
  params: { applySameMaintenance, isThereClusterWithoutMaintenance } = {},
}) {
  const [requestLoading, setRequestLoading] = useState(false);
  const [selectedApplyOption, setSelectedApplyOption] = useState(APPLY_TO_ALL);
  return (
    <MainModal onClose={hideModal} fullWidth maxWidth="sm">
      <MainModalHeader>
        Apply scheduled maintenance to other clusters
      </MainModalHeader>
      <MainModalContent>
        {isThereClusterWithoutMaintenance ? (
          <RadioGroup
            name="applyToOptions"
            id="applyToOptions"
            value={selectedApplyOption}
            onChange={e => setSelectedApplyOption(e.target.value)}
          >
            <FormControlLabel
              value={APPLY_TO_ALL}
              control={<Radio id={`${APPLY_TO_ALL}-option`} />}
              label={<p className={classes.label}>Apply to all</p>}
            />
            <FormControlLabel
              value={APPLY_TO_NOT_SET}
              control={<Radio id={`${APPLY_TO_NOT_SET}-option`} />}
              className={classes.label}
              label={
                <p className={classes.label}>
                  Apply for clusters without Maintenance
                </p>
              }
            />
          </RadioGroup>
        ) : (
          <p className={classes.contentText}>
            The action will apply your maintenance settings to all clusters
          </p>
        )}
      </MainModalContent>
      {requestLoading && <LinearProgress />}
      <MainModalActions>
        <MainModalActionButton
          type={MainModalActionButton.Types.MINOR}
          className={classes.linkButton}
          onClick={hideModal}
          disabled={requestLoading}
        >
          Cancel
        </MainModalActionButton>
        <MainModalActionButton
          disabled={requestLoading}
          type={MainModalActionButton.Types.MAJOR}
          className={classes.linkButton}
          onClick={() => {
            GoogleAnalytics.event({
              category: 'Maintenance windows',
              action:
                selectedApplyOption === APPLY_TO_ALL
                  ? 'Apply to all'
                  : 'Apply to not set',
            });
            applySameMaintenance(
              selectedApplyOption,
              setRequestLoading,
              hideModal
            );
          }}
        >
          Apply
        </MainModalActionButton>
      </MainModalActions>
    </MainModal>
  );
}

export default withJss(ApplyMaintenance);
