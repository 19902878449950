import { Grow, Alert as MuiAlert, AlertTitle } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import withStyles from 'components/withStylesAdapter';

const withJss = withStyles((theme, props) => ({
  root: {
    padding:
      props.size === 'sm'
        ? `${theme.custom.spacer._3xs} ${theme.custom.spacer._xxs}`
        : `${theme.custom.spacer._xs} ${theme.custom.spacer._sm}`,
    borderRadius: theme.custom.border.radius_md,
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiAlert-icon': {
      padding: 0,
      marginRight:
        props.size === 'sm' ? theme.utils.pxToRem(4) : theme.utils.pxToRem(12),
    },
    '& .MuiAlert-action': {
      paddingTop: 0,
      marginTop: `-${theme.utils.pxToRem(3)}`,
    },
  },
  alertText: {
    fontWeight: 400,
    fontSize: '0.875rem',
    a: {
      fontWeight: 'inherit',
      textDecoration: 'underline',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  actions: {
    display: 'flex',
    paddingLeft: '2rem',
    gap: '1rem',
    '& > *': {
      cursor: 'pointer',
    },
  },
}));

function Alert({
  id,
  status = 'success',
  title,
  text,
  onClose,
  visible,
  timeout,
  classes,
  actions,
  variant = 'outlined',
  icon,
  color,
  className,
}) {
  const alertRef = useRef(null);
  const prevVisibleRef = useRef({});

  useEffect(
    function scrollToAlertOnShow() {
      if (!prevVisibleRef.current && visible) {
        // After react 18 upgrade, this can't be done synchronously
        setTimeout(
          () => alertRef.current?.scrollIntoView({ behavior: 'smooth' }),
          100
        );
      }

      prevVisibleRef.current = visible;
    },
    [visible]
  );

  if (!visible) return null;

  const alertIdStatus = status === 'error' ? 'failure' : status;

  return (
    <Grow in={visible} timeout={timeout}>
      <MuiAlert
        variant={variant}
        severity={
          color && ['primary', 'secondary'].includes(color) ? 'info' : status
        }
        id={id ? `alert-${status}-${id}` : null}
        data-testid={`alert-${status}`}
        ref={alertRef}
        onClick={onClose}
        onClose={onClose}
        className={
          className ? classnames(classes.root, className) : classes.root
        }
        color={color}
        icon={icon} // if undefined - default icon will be used, if false - no icon will be shown
      >
        {title && (
          <AlertTitle
            id={`alert-title-${alertIdStatus}-${id}`}
            style={{
              marginBottom: text ? '' : '0px',
            }}
          >
            <span>{title}</span>
            {actions && (
              <span className={classes.actions}>
                {actions.map(a => (
                  <span onClick={a.clickHandler} id={a.label} key={a.label}>
                    {a.label}
                  </span>
                ))}
              </span>
            )}
          </AlertTitle>
        )}
        <span id={`alert-text-${status}-${id}`} className={classes.alertText}>
          {text}
        </span>
      </MuiAlert>
    </Grow>
  );
}

Alert.propTypes = {
  visible: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  text: PropTypes.node,
  status: PropTypes.oneOf(['success', 'error', 'warning', 'info']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  icon: PropTypes.node || false,
  timeout: PropTypes.number,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      clickHandler: PropTypes.func,
    })
  ),
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'error',
    'warning',
    'info',
  ]),
  size: PropTypes.oneOf(['default', 'sm']),
};

export default withJss(Alert);
