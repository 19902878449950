import { Box, MenuItem, Select } from '@mui/material';
import { INSTANCE_TYPE_ENVIRONMENT } from '@siren-frontend/shared';
import { groupBy } from 'lodash/fp';
import { Link } from 'react-router-dom';

import Skeleton from 'components/common/Skeleton';
import DcChangeSummary from 'components/common/modals/ResizeCluster/DcChangeSummary';
import InstanceSummary from 'components/common/modals/ResizeCluster/InstanceSummary';
import { DEFAULT_NUMBER_OF_NODES } from 'components/pages/new-cluster/ClusterProperties/InstanceSelection/instance-selection';
import { useRegionInstances } from 'components/pages/new-cluster/hooks/deployment';
import withStyles from 'components/withStylesAdapter';
import { calcResizeReplicationFactor } from 'services/cluster';
import { instanceToClusterSpec } from 'services/instance';
import { useSupportRedirect } from 'utils/hooks';

const withJss = withStyles(theme => ({
  box: {
    borderRadius: theme.contentBorderRadius,
    backgroundColor: theme.colors.whiteSmoke,
    padding: '0.5rem 1rem',
    marginTop: '1rem',
  },
  contactUsBox: {
    position: 'sticky',
    bottom: 0,
    height: '2rem',
    borderTop: `1px solid ${theme.colors.superSilver}`,
    backgroundColor: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0.375rem 1rem',
  },
  contactUsLink: {
    color: theme.colors.karimunBlue,
    paddingLeft: theme.utils.pxToRem(6),
  },
  dcName: {
    fontSize: theme.utils.pxToRem(16),
    lineHeight: theme.utils.pxToRem(24),
  },
  dcProperties: {
    display: 'flex',
    gap: '1rem',
  },
  moreNodesContact: {
    height: '2rem',
  },
  nodesSelectOptions: {
    height: '200px',
  },
}));

function SingleDcResizeBox({
  dataCenter,
  newNodesCount,
  currentNodesCount,
  newInstanceId,
  updateNewNodesCount,
  updateNewInstanceType,
  disableInputs,
  classes,
}) {
  const { redirectToSupport, isLoading: supportIsLoading } =
    useSupportRedirect();

  const nodesSelectOptions = calcResizeReplicationFactor(
    dataCenter.replicationFactor
  );

  const { isLoading: isInstancesLoading, instances = [] } = useRegionInstances(
    dataCenter?.cloudProviderId,
    dataCenter?.regionId
  );

  const instancesWithSpecs = instances.map(i =>
    instanceToClusterSpec(
      i,
      DEFAULT_NUMBER_OF_NODES,
      dataCenter.replicationFactor
    )
  );

  const productionInstances = instancesWithSpecs.filter(
    i => i.instance.environment === INSTANCE_TYPE_ENVIRONMENT.PRODUCTION
  );

  const groupedInstances = groupBy('instance.externalId', productionInstances);

  const currentInstance = instancesWithSpecs?.find(
    i => i?.id === dataCenter?.instanceId
  );

  const currentGroup =
    groupedInstances?.[currentInstance?.instance?.externalId];

  const selectedInstance = instancesWithSpecs?.find(
    i => i?.id === newInstanceId
  );

  const selectedGroup =
    groupedInstances?.[selectedInstance?.instance?.externalId];

  function onStorageOptionChange(selectedInstanceVariantId) {
    updateNewInstanceType(selectedInstanceVariantId, dataCenter.id);
  }

  return (
    <Box className={classes.box}>
      <Box className={classes.dcName}>{dataCenter.name}</Box>
      <Skeleton isLoading={isInstancesLoading}>
        <Box className={classes.dcProperties}>
          {!isInstancesLoading && (
            <Select
              SelectDisplayProps={{
                'data-testid': `DesiredInstanceSelect`,
              }}
              value={newInstanceId ?? dataCenter?.instanceId}
              variant="standard"
              data-testid={`desiredInstanceSelector-${dataCenter.id}`}
              onChange={e => {
                updateNewInstanceType(e.target.value, dataCenter.id);
              }}
            >
              {Object.entries(groupedInstances)?.map(
                ([groupExternalId, instanceGroup]) => {
                  const groupDefaultInstance =
                    instanceGroup.find(i => i.instance.groupDefault) ??
                    instanceGroup[0];
                  const instance =
                    selectedInstance?.instance.externalId === groupExternalId
                      ? selectedInstance
                      : groupDefaultInstance;

                  return (
                    <MenuItem
                      key={instance.id}
                      value={instance.id}
                      data-testid={`desiredInstanceSelectorOption-${instance.id}`}
                    >
                      {instance.instance.externalId}
                    </MenuItem>
                  );
                }
              )}
            </Select>
          )}
          <Select
            SelectDisplayProps={{
              'data-testid': `NodesCountSelect`,
            }}
            value={newNodesCount || currentNodesCount}
            onChange={ev => {
              updateNewNodesCount(ev.target.value, dataCenter.id);
            }}
            variant="standard"
            data-testid={`desiredNoOfNodesSelector${dataCenter.id}`}
            disabled={disableInputs}
            className={classes.nodeSelector}
            MenuProps={{
              PaperProps: {
                sx: {
                  height: 200,
                },
              },
              MenuListProps: {
                sx: {
                  paddingBottom: 0,
                },
              },
            }}
          >
            {nodesSelectOptions.map(o => (
              <MenuItem value={o} key={o}>
                {o} Nodes
              </MenuItem>
            ))}
            <Box className={classes.contactUsBox}>
              {supportIsLoading ? (
                <Skeleton width={231} height={12} />
              ) : (
                <>
                  Need more than {nodesSelectOptions.at(-1)} nodes?
                  <Link
                    onClick={redirectToSupport}
                    className={classes.contactUsLink}
                  >
                    Contact us
                  </Link>
                </>
              )}
            </Box>
          </Select>
        </Box>
        <InstanceSummary
          instance={selectedInstance ?? currentInstance}
          group={selectedGroup ?? currentGroup}
          onStorageOptionChange={onStorageOptionChange}
        />
        <DcChangeSummary
          currentNodesCount={currentNodesCount}
          newNodesCount={newNodesCount}
          currentInstance={currentInstance}
          newInstance={selectedInstance}
          showStorage={selectedGroup?.length > 1}
        />
      </Skeleton>
    </Box>
  );
}

export default withJss(SingleDcResizeBox);
