import MuiButton from '@mui/material/Button';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Spinner from 'components/common/Spinner';
import withStyles from 'components/withStylesAdapter';
import * as utils from 'definitions/utils';

// when isLoading is true - adding extra space to the edge except fullWidth
const addSpinnerPadding = ({ isLoading, fullWidth, size } = {}) => {
  if (!isLoading || fullWidth) return null;

  return size === 'xs' ? utils.pxToRem(24) : utils.pxToRem(32);
};

const withJss = withStyles((theme, props) => ({
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    paddingRight: addSpinnerPadding(props) || null,
  },
  spinner: {
    ...theme.extensions.spinner,
  },
}));

function Button(
  { isLoading = false, children, className, classes, ...buttonProps },
  buttonRef
) {
  return (
    <MuiButton
      ref={buttonRef}
      className={classnames(className, classes.button)}
      {...buttonProps}
      data-loading={isLoading}
      disabled={isLoading || buttonProps.disabled}
    >
      {children}
      {isLoading && (
        <Spinner
          size={buttonProps.size === 'xs' ? 'xxs' : 'xs'}
          className={classes.spinner}
        />
      )}
    </MuiButton>
  );
}

const ButtonRef = forwardRef(Button);

ButtonRef.propTypes = {
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'small', 'medium', 'large']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  color: PropTypes.oneOf([
    'inherit',
    'inheritWhite',
    'primary',
    'secondary',
    'success',
    'error',
    'warning',
    'info',
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.shape({}),
};

export default withJss(ButtonRef);
