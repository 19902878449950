import { FEATURE_FLAGS } from '@siren-frontend/shared/constants/feature-flags';
import DOMPurify from 'dompurify';
import { useCallback } from 'react';

import { useHasFeature } from 'components/providers/features/with-features';
import { useQueryRequest } from 'hooks/fetch';

function useScyllaBannerRequest() {
  const apiUrl = process.env.apiUrl;
  const shouldSkipBannerRequests = !useHasFeature(FEATURE_FLAGS.BANNER);
  const key = `${apiUrl}/info/banner`;

  const result = useQueryRequest({
    key: shouldSkipBannerRequests ? '' : key,
    withToken: true,
  });

  const sanitizedContent = DOMPurify.sanitize(result?.data?.acf.cloud_content);
  const sanitizedLink = DOMPurify.sanitize(result?.data?.acf.cloud_banner_link);

  return {
    // TODO date and title will be getting different source properties, working on it with Tenrec
    data: result?.data && {
      date: result?.data?.date_gmt,
      title: result?.data?.title.rendered,
      text: sanitizedContent,
      link: sanitizedLink,
      style: {},
    },
    isLoading: result?.isLoading,
  };
}

export function useScyllaBanner() {
  const { data, isLoading } = useScyllaBannerRequest();
  const bannerStorageKey = 'INFO_BANNER_VIEWED';

  const dismiss = useCallback((title, date) => {
    localStorage.setItem(bannerStorageKey, JSON.stringify({ date, title }));
  }, []);

  const hasBeenDismissed = useCallback(() => {
    const bannerInStorage = localStorage.getItem(bannerStorageKey);

    if (data && bannerInStorage) {
      const bannerObj = JSON.parse(bannerInStorage);

      if (bannerObj.date === data.date && bannerObj.title === data.title) {
        return true;
      } else if (bannerObj) {
        // remove existing entry in storage if it does not match the current banner
        localStorage.removeItem(bannerStorageKey);
      }
    }
    return false;
  }, [data]);

  return {
    data: !hasBeenDismissed() ? data : null,
    isLoading,
    dismiss,
  };
}
