import {
  CLOUD_PROVIDER_ID_BY_NAME,
  CUSTOM_OWNER_NAME,
  DEFAULT_OWNER_NAME,
  TRIM_CLUSTER_NAME_AT,
} from '@siren-frontend/shared';
import bytes from 'bytes';

import { getAccountOwner } from 'services/utils';

export const status = {
  active: 'ACTIVE',
  generalError: 'GENERAL_ERROR',
  bootstrapError: 'BOOTSTRAP_ERROR',
  bootstrap: 'BOOTSTRAPPING',
  queued: 'QUEUED',
};

export const billingVersions = {
  v1: 1,
  v2: 2,
  v3: 3,
};

export function calculateCostPerHour(
  instance,
  numberOfNodes,
  accountCredentialId,
  cloudAccounts,
  billingVersion = billingVersions.v1
) {
  if (!instance) {
    return 0;
  }

  const accountOwner =
    getAccountOwner(accountCredentialId, cloudAccounts) || DEFAULT_OWNER_NAME;

  const subscriptionCost =
    billingVersion === billingVersions.v1
      ? instance.licenseCostOnDemandPerHour
      : instance.subscriptionCostHourly;
  const instanceCost =
    billingVersion === billingVersions.v1
      ? parseFloat(instance.costPerHour)
      : parseFloat(instance.instanceCostHourly) +
        parseFloat(instance.subscriptionCostHourly);
  const cost =
    accountOwner === CUSTOM_OWNER_NAME
      ? parseFloat(subscriptionCost)
      : parseFloat(instanceCost);
  const calculatedCost = cost * numberOfNodes;

  return parseFloat(calculatedCost.toFixed(3));
}

export function modifyIpAddress(ipAddress) {
  try {
    return ipAddress.indexOf('/') === -1 ? `${ipAddress}/32` : ipAddress;
  } catch {
    throw new Error('Could not process the IP address.');
  }
}

/**
 * checks if the given value is a real number
 * @param {Number|*} value
 * @returns {Boolean}
 */
function isEmptyNumber(value) {
  return typeof value === 'undefined' || Number.isNaN(value);
}

export function formatMemory(memory) {
  if (isEmptyNumber(memory)) return 'N/A';
  return bytes(memory * 1024 * 1024, { unitSeparator: ' ' }) || 'N/A';
}

/**
 * Format storage output
 * includes option to return string with percentages
 * @param {Number} storage
 * @param {Number} [storageFree] if exists - returns as percentage { value: '80%', health: true }
 * @returns {Number|Object}
 */
export function formatStorage(storage, storageFree) {
  if (isEmptyNumber(storage)) return 'N/A';

  const isStorageMetricInvalid = storage === 0 && storageFree === 0;
  if (isStorageMetricInvalid) return { health: true, value: 'N/A' };

  if (storageFree) {
    // returns value and health
    const storagePercent = Math.round(
      (storage * 100) / (storage + storageFree)
    );
    const isStorageHealthy = storagePercent < 75;
    return {
      health: isStorageHealthy,
      value: `${storagePercent}%`,
    };
  }
  return bytes(storage * 1024 * 1024 * 1024, { unitSeparator: ' ' }) || 'N/A';
}

export function formatCPU(cpu, percentages) {
  if (isEmptyNumber(cpu)) return 'N/A';
  if (percentages) return `${Math.round(cpu)}%`;
  return cpu;
}

export function formatNetworkSpeed(networkSpeed) {
  return Math.round(networkSpeed / 1000);
}

export function calcReplicationFactor(numberOfOptions, rf, limit) {
  const numberOfNodesList = Array(numberOfOptions)
    .fill()
    .map((v, i) => (i + 1) * rf)
    .filter(n => n <= limit);

  return numberOfNodesList;
}

export function calcBootstrapReplicationFactor(rf) {
  return calcReplicationFactor(7, rf, 21);
}
export function calcResizeReplicationFactor(rf) {
  return calcReplicationFactor(34, rf, 102);
}

export function isAws(cloudProviderId) {
  return cloudProviderId === CLOUD_PROVIDER_ID_BY_NAME.AWS;
}

export function isGcp(cloudProviderId) {
  return cloudProviderId === CLOUD_PROVIDER_ID_BY_NAME.GCP;
}

/**
 * if the name is longer than TRIM_CLUSTER_NAME_AT, trim it and append ellipsis
 * @param {string} name
 * @returns {[string, boolean]} trimmed name and a flag specifying if
 * the name was actually trimmed
 */
export function maybeTrimClusterName(name = '') {
  const isLong = name.length > TRIM_CLUSTER_NAME_AT;
  return [isLong ? `${name.slice(0, TRIM_CLUSTER_NAME_AT)}…` : name, isLong];
}
