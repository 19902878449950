import { FronteggProvider, useAuth, useAuthActions } from '@frontegg/react';
import fronteggPackageData from '@frontegg/react/package.json';
import { STALE_DATA_PREFIX } from '@siren-frontend/shared';
import { useEffect } from 'react';
import { useSWRConfig } from 'swr';

import { LoginSidePanel } from 'components/pages/auth/LoginSidePanel';
import { useHasFeature } from 'components/providers/features/with-features';
import { useAnalyticsIndetity } from 'hooks/authentication';
import {
  gatherSignUpMarketingMetadata,
  sendSignUpMarketingMetadata,
} from 'services/registration';
import { GoogleAnalytics, trackHeapEvent } from 'utils/analytics';
import logger from 'utils/logger';

import { Terms, stepDescriptions } from './Footers';

const fronteggAdminVersion =
  fronteggPackageData?.dependencies?.['@frontegg/js'];

const themeOptions = {
  loginBox: {
    layout: {
      type: 'float-right',
      splitSize: 50,
      sideElement: LoginSidePanel,
      sideElementStyle: {},
    },
    signup: {
      disclaimer: {
        terms: { enabled: false },
        privacy: { enabled: false },
      },
      boxFooter: () => <Terms stepDescription={stepDescriptions.SignUp} />,
      splitFullName: true,
    },
    login: {
      disclaimer: {
        terms: { enabled: false },
        privacy: { enabled: false },
      },
      boxFooter: () => <Terms stepDescription={stepDescriptions.Login} />,
    },
  },
  adminPortal: {
    pages: {
      account: {
        fieldsProperties: {
          currency: {
            appearance: 'hidden',
          },
          website: {
            appearance: 'hidden',
          },
        },
      },
      users: {
        inviteUserModal: {
          inviteByEmail: {
            enabled: true,
            fieldsProperties: {
              name: {
                appearance: 'edit',
                settings: {
                  validation: {
                    required: true,
                  },
                },
              },
              phoneNumber: {
                appearance: 'hidden',
              },
            },
          },
        },
      },
    },
  },
};

const localizations = {
  en: {
    adminPortal: {
      navigation: {
        workspace: 'Account',
      },
    },
    loginBox: {
      signup: {
        account: {
          emailInputLabel: 'Company Email Only',
          nameInputLabel: 'Full Name',
          companyNameInputLabel: 'Company Name',
        },
      },
    },
  },
};

function refetchStaleData(cache, mutate) {
  cache?.forEach((value, key) => {
    try {
      if (key.startsWith(STALE_DATA_PREFIX)) {
        mutate(key.split(STALE_DATA_PREFIX)[1]);
        cache.delete(key);
      }
    } catch (e) {
      logger.error({
        prefix: 'FronteggAuthProvider',
        message:
          'refetchStaleData() error while trying to refetch stale data for key',
        error: e,
        extraData: {
          key,
        },
      });
    }
  });
}

function TokenRefresh({ children }) {
  const auth = useAuth();
  const { requestAuthorize } = useAuthActions();
  const { cache, mutate } = useSWRConfig();

  useEffect(
    function setTokenRefreshInterval() {
      const id = setInterval(function checkTokenValidity() {
        const currentTime = new Date().getTime();
        const tokenExpiryTime = auth.user?.exp ? auth.user.exp * 1000 : null;
        const tokenStaleInterval = auth.user?.expiresIn * 1000 * 0.4;

        if (
          tokenExpiryTime &&
          currentTime + tokenStaleInterval > tokenExpiryTime
        ) {
          logger.info({
            prefix: 'FronteggAuthProvider',
            message: 'setTokenRefreshInterval() attempting to requestAuthorize',
            extraData: {
              tokenExpiryTime,
              tokenStaleInterval,
              currentTime,
            },
          });
          requestAuthorize();
        } else {
          refetchStaleData(cache, mutate);
        }
      }, 1000);

      return function () {
        clearInterval(id);
      };
    },
    [auth.user?.exp, auth.user?.expiresIn, cache, mutate, requestAuthorize]
  );

  return children;
}

function AnalyticsIdentify({ children }) {
  useAnalyticsIndetity();

  return children;
}

export default function FronteggAuthProvider({ children }) {
  const isFEggHostedEnabled = useHasFeature('FRONTEGG_HOSTED');

  const fronteggEvents = {
    signUpComplete: function (ev) {
      sendSignUpMarketingMetadata(ev.id);

      GoogleAnalytics.event({
        category: 'Sign Up',
        action: 'scylla_cloud_user_signed_up',
        label: 'User signed up',
      });

      trackHeapEvent('User Signed Up', {
        userId: ev.id,
        userEmail: ev.email,
        ...gatherSignUpMarketingMetadata(),
      });
    },
    userVerified: function (ev) {
      GoogleAnalytics.event({
        category: 'Sign Up',
        action: 'scylla_cloud_user_account_verified',
        label: 'User account verified',
      });

      trackHeapEvent('Account Verified', {
        userId: ev.id,
        userEmail: ev.email,
      });
    },
  };

  const fronteggContextOptions = {
    baseUrl: process.env.fronteggApiUrl,
    clientId: isFEggHostedEnabled ? process.env.fronteggClientID : undefined,
  };

  const authOptions = {
    keepSessionAlive: true,
    enforceRedirectToSameSite: !isFEggHostedEnabled,
  };

  return (
    <FronteggProvider
      authOptions={authOptions}
      contextOptions={fronteggContextOptions}
      hostedLoginBox={isFEggHostedEnabled}
      themeOptions={themeOptions}
      localizations={localizations}
      events={fronteggEvents}
      cdnUrl={`/authui/admin-box/${fronteggAdminVersion}`}
      guidesCdnUrl={`/authui/sso/${fronteggAdminVersion}`}
    >
      <TokenRefresh>
        <AnalyticsIdentify>{children}</AnalyticsIdentify>
      </TokenRefresh>
    </FronteggProvider>
  );
}
