import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Box, useTheme } from '@mui/material';

import AlertWithTraceId from 'components/common/AlertWithTraceId';
import MainModal, { MainModalHeader } from 'components/common/main-modal';
import ClusterLockedContent, {
  LOCKED_CLUSTER_ACTIONS,
} from 'components/common/modals/ClusterLocked/content';
import { useMuiForm } from 'components/pages/new-cluster/hooks/form';
import withStyles from 'components/withStylesAdapter';
import { useCreateTgwConnection } from 'hooks/cluster';

import AddTgwActions from './AddTgwActions';
import AddTgwContent from './AddTgwContent';

const withJss = withStyles(() => ({
  alertBox: {
    padding: '1.5rem 1.5rem 0',
  },
}));

function AddTgwConnectionModal({
  hideModal,
  params: { clusterId, dcs, isLocked },
  classes,
}) {
  const theme = useTheme();
  const tgwForm = useMuiForm({ mode: 'onBlur' }, {});
  const {
    request: createTgwConnection,
    isLoading,
    error,
  } = useCreateTgwConnection(clusterId, hideModal);

  const submit = async values => {
    const submitData = {
      ...values,
      cidrBlocks: values.cidrList,
    };

    await createTgwConnection(submitData);
  };

  return (
    <MainModal
      onClose={hideModal}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        // TODO: make it a default for the modal paper while doing the modals refactor
        style: {
          borderRadius: '12px',
        },
      }}
    >
      <MainModalHeader
        onClose={hideModal}
        HelperIcon={!isLocked && <AddOutlinedIcon fontSize="medium" />}
        helperIconColor={theme.colors.infoGreen}
      >
        Add Transit Gateway Connection
      </MainModalHeader>
      <Box className={classes.alertBox}>
        <AlertWithTraceId id="create-connection-error-alert" error={error} />
      </Box>
      {isLocked ? (
        <ClusterLockedContent action={LOCKED_CLUSTER_ACTIONS.ADD_CONNECTION} />
      ) : (
        <AddTgwContent form={tgwForm} dcs={dcs} />
      )}
      <AddTgwActions
        onSubmit={tgwForm.handleSubmit(submit)}
        onCancel={hideModal}
        isLoading={isLoading}
        isClusterLocked={isLocked}
      />
    </MainModal>
  );
}

export default withJss(AddTgwConnectionModal);
