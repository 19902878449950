import {
  CLOUD_PROVIDER_ID_BY_NAME,
  CUSTOM_OWNER_NAME,
  DEFAULT_OWNER_NAME,
} from '@siren-frontend/shared';

import { useAuthentication } from 'hooks/authentication';
import { useMutationRequest, useQueryRequest } from 'hooks/fetch';
import {
  setAccountCountry,
  setNotificationEmail,
} from 'services/resources/account/account';

const apiUrl = process.env.apiUrl;

export const cloudAccountsKey = accountId =>
  accountId && `${apiUrl}/account/${accountId}/cloud-account`;

export function useDefaultAccountId() {
  const { userProfile } = useAuthentication();
  return userProfile?.defaultAccountId;
}

export function useCloudAccounts(fallbackAccounts) {
  const defaultAccountId = useDefaultAccountId();
  const key =
    defaultAccountId && `${apiUrl}/account/${defaultAccountId}/cloud-account`;
  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
  });

  return {
    data: data ?? fallbackAccounts,
    ...rest,
  };
}

export function useOwnCloudAccount() {
  const { data: cloudAccounts, isLoading, error, ...rest } = useCloudAccounts();
  return {
    data: cloudAccounts?.find(account => account.owner === CUSTOM_OWNER_NAME),
    isLoading,
    error,
    ...rest,
  };
}

export function useScyllaCloudAccount(
  cloudProviderId = CLOUD_PROVIDER_ID_BY_NAME.AWS
) {
  const { data: cloudAccounts, isLoading, error } = useCloudAccounts();
  return {
    data: cloudAccounts?.find(
      account =>
        account.owner === DEFAULT_OWNER_NAME &&
        account.cloudProviderId === cloudProviderId
    ),
    isLoading,
    error,
  };
}

export function useAccount(fallbackAccount) {
  const defaultAccountId = useDefaultAccountId();
  const key = defaultAccountId && `${apiUrl}/account/${defaultAccountId}`;

  const { data, ...rest } = useQueryRequest({
    key,
    withToken: true,
  });

  return {
    data: data ?? fallbackAccount,
    ...rest,
  };
}

// Can't reuse useFetchList because the reponse here shaped as .data and not .data.countries
export function useCountries() {
  const { isLoading, data, error } = useQueryRequest({
    key: `${apiUrl}/account/countries`,
  });

  return {
    countries: data,
    isLoading,
    error,
  };
}

export const useSetAccountCountry = () => {
  const defaultAccountId = useDefaultAccountId();

  return useMutationRequest({
    requester: (countryId, token) =>
      setAccountCountry.call({
        accountId: defaultAccountId,
        id: countryId,
        token,
      }),
  });
};

export function useDefaultNotificationEmail() {
  const defaultAccountId = useDefaultAccountId();
  const key =
    defaultAccountId &&
    `${apiUrl}/account/${defaultAccountId}/notifications/email`;
  return useQueryRequest({
    key,
    withToken: true,
  });
}

export function useSetNotificationEmail({ onSuccess, onFailure }) {
  const defaultAccountId = useDefaultAccountId();

  return useMutationRequest({
    requester: (emails, token) =>
      setNotificationEmail.call({ accountId: defaultAccountId, emails, token }),
    onSuccess,
    onFailure,
  });
}
